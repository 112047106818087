import { useAccount } from "modules/common";
import { Fingerprint } from "modules/common/constants";
import { useMemo } from "react";

export interface IFingerprintDisplay {
    item?: Fingerprint;
    value: "created" | "lastUpdated";
    containerClassName?: string;
    labelClassName?: string;
    textClassName?: string;
}

export const FingerprintDisplay = ({
    item,
    value,
    containerClassName,
    labelClassName,
    textClassName,
}: IFingerprintDisplay) => {
    const userId = useMemo(() => {
        return value === "created" ? item?.createdBy : item?.lastUpdatedBy;
    }, [item, value]);

    const time = useMemo(() => {
        return value === "created" ? item?.created : item?.lastUpdated;
    }, [item, value]);

    const { data: user, isLoading, isError } = useAccount(userId);

    if (!item || isLoading || isError) return null;

    return (
        <div className={`fingerprint ${containerClassName ?? ""}`}>
            <span className={`fingerprint-label ${labelClassName ?? ""}`}>
                {value === "created" ? "Kreirao" : "Zadnju izmjenu napravio"}
            </span>
            <span className={`fingerprint-text ${textClassName ?? ""}`}>
                {user.firstName} {user.lastName}
            </span>
            <span className={`fingerprint-text ${textClassName ?? ""}`}>
                {time && new Date(time).toLocaleString("HR-hr")}
            </span>
        </div>
    );
};
