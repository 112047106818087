import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isDeactivating: boolean;
  deactivationSuccess: boolean;
  deactivationError: boolean;
  errorMessage: string;
}

interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  isDeactivating: false,
  deactivationError: false,
  deactivationSuccess: false,
  errorMessage: "",
};

const deactivateAccountSlice = createSlice({
  name: "DeactivateAccount",
  initialState: initialState,
  reducers: {
    deactivateAccountRequest: (state) => {
      state.isDeactivating = true;
    },
    deactivateAccountSuccess: (state) => {
      state.isDeactivating = false;
      state.deactivationSuccess = true;
    },
    deactivateAccountError: (state, action: PayloadAction<RequestError>) => {
      state.isDeactivating = false;
      state.deactivationError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = deactivateAccountSlice;

export const {
  deactivateAccountRequest,
  deactivateAccountSuccess,
  deactivateAccountError,
} = actions;

export const deactivateAccountReducer = deactivateAccountSlice.reducer;
