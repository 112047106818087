import { Modal, ModalHeader, ModalFooter, Button, FingerprintDisplay } from "modules/common";
import { ConfirmationModal } from "modules/common/components/ConfirmationModal";
import { useMemo, useState } from "react";
import { useOuterModuleVersionDetails, useRestoreOuterModuleVersion } from "../hooks";
import { OuterModulesTable } from "./OuterModulesTable";

export interface IOuterModuleDetailsVesrionModal {
    moduleId?: string;
    isOpen: boolean;
    handleClose: () => void;
}

export const OuterModuleDetailsVersionModal = ({
    moduleId,
    isOpen,
    handleClose,
}: IOuterModuleDetailsVesrionModal) => {
    const [showApprove, setShowApprove] = useState(false);
    const { data: outerModuleVersion, isLoading } = useOuterModuleVersionDetails(moduleId);
    const restore = useRestoreOuterModuleVersion();

    const tabledata = useMemo(
        () => ({
            items: outerModuleVersion?.modules ?? [],
            total: outerModuleVersion?.modules?.length ?? 0,
        }),
        [outerModuleVersion]
    );

    const onRestore = async () => {
        if (!moduleId) return;
        await restore.mutateAsync(moduleId);
        setShowApprove(false);
    };

    return (
        <Modal
            isActive={isOpen}
            className="outer-module-version-details-modal"
            onClose={handleClose}
            modalHeader={
                <ModalHeader
                    onClose={handleClose}
                    title={`Verzija: ${outerModuleVersion?.version}`}
                    isLoading={isLoading}
                />
            }
            modalFooter={
                <ModalFooter>
                    <Button
                        type="button"
                        size="small"
                        variant="primary-ghost"
                        isDisabled={isLoading}
                        onClick={() => setShowApprove(true)}
                    >
                        <span>Vrati na ovo stanje</span>
                    </Button>

                    <Button
                        type="button"
                        size="small"
                        variant="primary"
                        isDisabled={false}
                        onClick={handleClose}
                    >
                        <span>Zatvori</span>
                    </Button>
                </ModalFooter>
            }
        >
            <div className="outer-module-version-details">
                <div className="outer-module-version-details-section">
                    <span className="outer-module-version-details-label">Opis promjene</span>
                    <span className="outer-module-version-details-text version-description">
                        {outerModuleVersion?.description}
                    </span>
                </div>

                <FingerprintDisplay item={outerModuleVersion} value="created" />

                <OuterModulesTable
                    className="wide"
                    outerModules={tabledata}
                    isLoading={isLoading}
                />
            </div>
            <ConfirmationModal
                isActive={showApprove}
                onConfirm={onRestore}
                onCancel={() => setShowApprove(false)}
                content={`Želite li vratiti vanjske module na verziju ${outerModuleVersion?.version}?`}
            />
        </Modal>
    );
};
