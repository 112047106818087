import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isDeactivating: boolean;
  deactivationSuccess: boolean;
  deactivationError: boolean;
  errorMessage: string;
}

interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  isDeactivating: false,
  deactivationError: false,
  deactivationSuccess: false,
  errorMessage: "",
};

const deactivateUserSlice = createSlice({
  name: "DeactivateUser",
  initialState: initialState,
  reducers: {
    deactivateUserRequest: (state) => {
      state.isDeactivating = true;
    },
    deactivateUserSuccess: (state) => {
      state.isDeactivating = false;
      state.deactivationSuccess = true;
    },
    deactivateUserError: (state, action: PayloadAction<RequestError>) => {
      state.isDeactivating = false;
      state.deactivationError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = deactivateUserSlice;

export const {
  deactivateUserRequest,
  deactivateUserSuccess,
  deactivateUserError,
} = actions;

export const deactivateUserReducer = deactivateUserSlice.reducer;
