import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MappedUserProfile } from "modules/dashboard/utils";

interface State {
  isLoading: boolean;
  isUpdating: boolean;
  isError: boolean;
  isSuccess: boolean;
  errorMessage: string;
  profile: MappedUserProfile | null;
}

interface GetProfileSuccess {
  profile: any;
}
interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  isLoading: false,
  isUpdating: false,
  isError: false,
  isSuccess: false,
  errorMessage: "",
  profile: null,
};

const profileSlice = createSlice({
  name: "Profile",
  initialState: initialState,
  reducers: {
    getProfileRequest: (state) => {
      state.isLoading = true;
    },
    getProfileSuccess: (state, action: PayloadAction<GetProfileSuccess>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.profile = action.payload.profile;
    },
    getProfileError: (state, action: PayloadAction<RequestError>) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload.errorMessage;
    },
    updateProfileRequest: (state) => {
      state.isUpdating = true;
    },
    updateProfileSuccess: (state) => {
      state.isUpdating = false;
      state.isSuccess = true;
    },
    updateProfileError: (state) => {
      state.isUpdating = false;
      state.isError = true;
    },
  },
});

const { actions } = profileSlice;

export const {
  getProfileRequest,
  getProfileSuccess,
  getProfileError,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileError,
} = actions;

export const profileReducer = profileSlice.reducer;
