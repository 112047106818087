import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  registrationError: boolean;
  errorMessage: string;
  successMessage: string;
  registrationSuccess: boolean;
}

interface RegistrationError {
  errorMessage: string;
}

interface RegistrationSuccess {
  successMessage: string;
}

const initialState: State = {
  isLoading: false,
  registrationError: false,
  registrationSuccess: false,
  errorMessage: "",
  successMessage: "",
};
const registerSlice = createSlice({
  name: "Registration",
  initialState: initialState,
  reducers: {
    registrationRequest: (state) => {
      state.registrationError = false;
      state.registrationSuccess = false;
      state.isLoading = true;
    },
    registrationSuccess: (
      state,
      action: PayloadAction<RegistrationSuccess>
    ) => {
      state.isLoading = false;
      state.registrationSuccess = true;
      state.successMessage = action.payload.successMessage;
    },
    registrationError: (state, action: PayloadAction<RegistrationError>) => {
      state.isLoading = false;
      state.registrationError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = registerSlice;

export const {
  registrationError,
  registrationRequest,
  registrationSuccess,
} = actions;

export const registrationReducer = registerSlice.reducer;
