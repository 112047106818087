import { signUp, SignUpRequest } from "services";
import { AppThunk } from "store";
import {
  registrationError,
  registrationRequest,
  registrationSuccess,
} from "./reducer";

export const registerUser = (data: SignUpRequest): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(registrationRequest());

    const response = await signUp(data);
    const [first] = response.data.statuses;
    dispatch(registrationSuccess({ successMessage: first.message }));
  } catch (error: any) {
    const [first] = error.response.data.statuses;
    dispatch(registrationError({ errorMessage: first.message }));
  }
};
