import { AxiosError } from "axios";
import { Status } from "services/responseStatus";
import { toast } from "react-toastify";
import axios from "axios";

export type IErrorContext = {
    statuses: Status[];
};

const isErrorContext = (error: AxiosError<any>): error is AxiosError<IErrorContext> => {
    return Boolean(error.response && error.response.data.statuses);
};

const showErrorMessage = (message: unknown) => {
    if (typeof message === "string") {
        toast.error(message);
    } else if (Array.isArray(message)) {
        const [first] = message;
        toast.error(first.message);
    } else {
        toast.error("Dogodila se greška");
    }
};

export const standardErrorHandle = (
    error: AxiosError<IErrorContext> | unknown,
    defaulMessage?: string
) => {
    if (!axios.isAxiosError(error)) {
        showErrorMessage(defaulMessage ?? "Dogodila se greška");
    } else if (isErrorContext(error) && error.response) {
        const [first] = error.response.data.statuses;
        showErrorMessage(first.message);
    } else if (error.response?.data?.message) {
        showErrorMessage(error.response.data.message);
    } else if (error.response?.data) {
        showErrorMessage(error.response.data);
    } else if (defaulMessage) {
        showErrorMessage(defaulMessage);
    } else {
        showErrorMessage("Dogodila se greška");
    }
};

export const useStandardErrorHandler = (defaulMessage?: string) => {
    return (error: AxiosError<IErrorContext> | unknown) => {
        standardErrorHandle(error, defaulMessage);
    };
};
