import React from "react";
import { Can } from "modules/auth";

import {
  EducationLinks,
  EducationSignup,
  EducationTabNavigation,
  SpecificEducation,
} from "modules/education";
import { useLocation } from "react-router-dom";
import { useTitle } from "modules/common";

export const Educations: React.FC = () => {
  useTitle('Edukacija - eSavjetnik');
  const { search } = useLocation();

  const id = search.includes("id") ? search.split("=")[1] : undefined;

  return (
    <Can I="view" a="EducationModule">
      <div className="educations-container">
        <EducationTabNavigation />

        <Can I="view" a="EducationLinks">
          <EducationLinks />
        </Can>

        <Can I="view" a="CourseRegistration">
          {id ? <SpecificEducation educationUid={id} /> : <EducationSignup />}
        </Can>
      </div>
    </Can>
  );
};
