import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  changePasswordError: boolean;
  errorMessage: string;
  successMessage: string;
  changePasswordSuccess: boolean;
  verifySuccess: boolean;
  verifyError: boolean;
  code: string;
}

interface ChangePasswordError {
  errorMessage: string;
}

interface ChangePasswordSuccess {
  successMessage: string;
}

interface VerifyLinkSuccess {
  code: string;
}

const initialState: State = {
  isLoading: false,
  changePasswordError: false,
  changePasswordSuccess: false,
  verifySuccess: false,
  verifyError: false,
  errorMessage: "",
  successMessage: "",
  code: "",
};

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: initialState,
  reducers: {
    verifyPasswordResetLinkRequest: (state) => {
      state.isLoading = true;
    },
    verifyPasswordResetLinkSuccess: (
      state,
      action: PayloadAction<VerifyLinkSuccess>
    ) => {
      state.isLoading = false;
      state.verifySuccess = true;
      state.code = action.payload.code;
    },
    verifyPasswordResetLinkError: (state) => {
      state.isLoading = false;
      state.verifyError = true;
    },
    changePasswordRequest: (state) => {
      state.changePasswordError = false;
      state.changePasswordSuccess = false;
      state.isLoading = true;
    },
    changePasswordSuccess: (
      state,
      action: PayloadAction<ChangePasswordSuccess>
    ) => {
      state.isLoading = false;
      state.changePasswordSuccess = true;
      state.successMessage = action.payload.successMessage;
    },
    changePasswordError: (
      state,
      action: PayloadAction<ChangePasswordError>
    ) => {
      state.isLoading = false;
      state.changePasswordError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = changePasswordSlice;

export const {
  changePasswordError,
  changePasswordRequest,
  changePasswordSuccess,
  verifyPasswordResetLinkRequest,
  verifyPasswordResetLinkError,
  verifyPasswordResetLinkSuccess,
} = actions;

export const changePasswordReducer = changePasswordSlice.reducer;
