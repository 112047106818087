import React from "react";

interface Props {
  title: string;
  className?: string;
}
export const StatisticsContainer: React.FC<Props> = ({
  children,
  title,
  className,
}) => {
  return (
    <div className={`statistics ${className ?? ""}`}>
      <div className="statistics-title">{title}</div>
      {children}
    </div>
  );
};
