import React, { useState } from "react";
import { HamburgerMenu } from "../HamburgerMenu";
import { MainFooter } from "../MainFooter";
import { MainHeader } from "../MainHeader/MainHeader";
import { ToastContainer } from "react-toastify";

export const MainLayout: React.FC = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  return (
    <div className="layout-container">
      <div className="layout-grid">
        <div className="layout-header">
          <MainHeader setMenuOpen={handleMenuOpen} />
        </div>
        <HamburgerMenu
          isOpen={menuOpen}
          className={`${menuOpen ? "hamburger-menu--open" : ""}`}
          close={handleMenuClose}
        />
        <main className="layout-content">{children}</main>
        <div className="layout-footer">
          <MainFooter />
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};
