import qs from "query-string";

const getCode = (code: string | string[]) => {
  if (typeof code === "string") {
    return code;
  }

  const [first] = code;

  return first;
};

export function getUrlCode(url: string) {
  const decoded = decodeURIComponent(url);
  const parsedQuery = qs.parse(decoded);

  if (parsedQuery.code) {
    return getCode(parsedQuery.code);
  }

  return null;
}
