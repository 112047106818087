import { decodeJwt, sessionCache, Timer } from "modules/auth";
import { getProfile } from "modules/dashboard";
import { renewToken, signIn } from "services";
import { SignInRequest } from "services/auth/request";
import { AppThunk } from "store";

import {
  signInError,
  signInRequest,
  signInSuccess,
  tokenRenewError,
  tokenRenewRequest,
  tokenRenewSuccess,
  signOut,
} from "./reducer";

export const tokenRenew = (): AppThunk => async (dispatch) => {
  try {
    dispatch(tokenRenewRequest());

    const cache = sessionCache();
    const timer = Timer.getInstance();
    const session = cache.getSession();

    if (session) {
      const response = await renewToken(session.refreshToken);
      const body = decodeJwt(response.data.accessToken);

      cache.updateTokens(response.data.accessToken, response.data.refreshToken);
      timer.start(body.exp);

      dispatch(
        tokenRenewSuccess({
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        })
      );
    } else {
      dispatch(logOff());
    }
  } catch (error) {
    dispatch(tokenRenewError());
    dispatch(logOff());
    dispatch(
      signInError({ errorMessage: "Sesija istekla, ponovno se prijavite." })
    );
  }
};

export const signInUser = (data: SignInRequest): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(signInRequest());

    const response = await signIn(data);
    const cache = sessionCache();
    const { accessToken, refreshToken } = response.data;
    const body = decodeJwt(accessToken);
    const refreshBody = decodeJwt(refreshToken);

    const authData = {
      userId: body.sub,
      email: body.email,
      accessToken: accessToken,
      refreshToken: refreshToken,
      role: { value: 0, label: "" },
      userType: { value: 0, label: "" },
      rememberMe: data.rememberMe,
    };

    dispatch(signInSuccess(authData));

    cache.saveSession(data.rememberMe, {
      ...authData,
      expiration: body.exp,
      refreshExpiration: refreshBody.exp,
    });
    const timer = Timer.getInstance();

    timer.addHandler(async () => {
      dispatch(tokenRenew());
    });

    timer.start(body.exp);

    dispatch(getProfile());
  } catch (error: any) {
    const [first] = error.response.data.statuses;
    dispatch(signInError({ errorMessage: first.message }));
  }
};

export const logOff = (): AppThunk => async (dispatch) => {
  const cache = sessionCache();

  cache.removeSession();
  dispatch(signOut());
};
