enum InfoType {
  News = 0x0001,
  Advice = 0x0002,

  FarmingAndOther = 0x0200,
  WineGrowing = 0x0400,
  FruitAndOliveGrowing = 0x0800,

  CoursesNotification = 0x1000,
  DemonstrationNotification = 0x2000,
  WetherAlarm = 0x4000,
  Announcement = 0x8000,
}

export const infoTypes: Record<string, { value: number; label: string }> = {
  Advice: {
    value: InfoType.Advice,
    label: "Savjeti",
  },
  CoursesNotification: {
    value: InfoType.CoursesNotification,
    label: "Obavijesti o tečajevima",
  },
  DemonstrationNotification: {
    value: InfoType.DemonstrationNotification,
    label: "Obavijesti o demonstracijama",
  },
  News: {
    value: InfoType.News,
    label: "Vijesti",
  },
  WetherAlarm: {
    value: InfoType.WetherAlarm,
    label: "Vremenski alarmi",
  },
  RecommendationFarmingAndOther: {
    value: InfoType.FarmingAndOther,
    label: "Preporuke za zaštitu bilja: Ratarstvo i ostalo",
  },
  RecommendationFruitAndOliveGrowing: {
    value: InfoType.FruitAndOliveGrowing,
    label: "Preporuke za zaštitu bilja: Voćarstvo i maslinarstvo",
  },
  RecommendationWineGrowing: {
    value: InfoType.WineGrowing,
    label: "Preporuke za zaštitu bilja: Vinogradarstvo",
  },
  Announcement: {
    value: InfoType.Announcement,
    label: "Najave manifestacija",
  },
};
