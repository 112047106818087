import React from "react";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";
import { NotificationType, notificationTypes } from "modules/common";

interface Props {
  title: string;
  notificationFilter: NotificationType[];
  setNotificationFilter: React.Dispatch<
    React.SetStateAction<NotificationType[]>
  >;
}

/** Refactor if needed for dynamic data */
export const NotificationSelect: React.FC<Props> = ({
  title,
  notificationFilter,
  setNotificationFilter,
}) => {
  function checkIfSelected(option: NotificationType) {
    return notificationFilter.some((i) => i === option);
  }

  function toggleOption(option: NotificationType) {
    const selected = checkIfSelected(option);

    if (selected) {
      const newArray = notificationFilter.filter((i) => i !== option);
      setNotificationFilter(newArray);
      return;
    }

    setNotificationFilter([...notificationFilter, option]);
  }

  return (
    <div className="f-align-y-top">
      <h3 className="notification-stats-title">{title}</h3>
      <button
        className={`btn btn--select mb-20 ${
          checkIfSelected(NotificationType.Email) && "btn--select--active"
        }`}
        type="button"
        onClick={toggleOption.bind(null, NotificationType.Email)}
      >
        <EmailIcon className="notification-stats-icon" />
        <span className="notification-stats-text">
          {notificationTypes.Email.label}
        </span>
      </button>
        <button
        className={`btn btn--select mb-20 ${
          checkIfSelected(NotificationType.Push) && "btn--select--active"
        }`}
        type="button"
        onClick={toggleOption.bind(null, NotificationType.Push)}
      >
        <MessengerIcon className="notification-stats-icon" />
        <span className="notification-stats-text">
          {notificationTypes.Application.label}
        </span>
      </button> 
    </div>
  );
};
