import React from "react";
import { ValidationSummary } from "modules/common";

interface Props {
  error?: boolean;
  success?: boolean;
  info?: boolean;
  message: string;
}

export const AccountMessages: React.FC<Props> = ({
  error,
  success,
  info,
  message,
}) => {
  return (
    <>
      {error && (
        <ValidationSummary className="mb-36" type="error">
          {message}
        </ValidationSummary>
      )}
      {success && (
        <ValidationSummary className="mb-36" type="success">
          {message}
        </ValidationSummary>
      )}
      {info && (
        <ValidationSummary className="mb-36" type="info">
          {message}
        </ValidationSummary>
      )}
    </>
  );
};
