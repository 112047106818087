import React from "react";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Download } from "assets/icons/download-circle.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { PresentationItem as Presentation } from "services";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Can } from "modules/auth";

interface Props {
  item: Presentation;
  onEdit: (id: number) => void;
  onDownload: (id: number) => void;
  onDelete: (id: number) => void;
}

export const PresentationItem: React.FC<Props> = ({
  item,
  onEdit,
  onDownload,
  onDelete,
}) => {
  const { isDownloading } = useSelector(
    (state: RootState) => state.presentations
  );

  return (
    <div>
      <div className="f f-align-items-center">
		{item.type ?
			<p className="educations__item-subheading educations__item-subheading--first">
			{item.type}
			</p>
		: null}
        <p className="educations__item-subheading educations__item-subheading--second">
          {item.expertArea.name}
        </p>
		{item.measure.name !== 'other' ?
			<p className="educations__item-subheading educations__item-subheading--third">
			{item.measure.name}
			</p>
		: null}
        <p className="educations__item-subheading educations__item-subheading--third">
          {item.operationType.name}
        </p>
        <p className="presentation__item-date">
          {format(new Date(item.createdAt), "dd.MM.yyyy.")}
        </p>
      </div>

      <div className="f f-justify-between">
        <div className="presentation__item">
          <p className="presentation__item-title">{item.title}</p>
          <p className="presentation__item-subtitle">{item.author}</p>
        </div>
        <div>
          <Can I="update" a="EditPresentation">
            <button
              className="presentation__item-button mr-4"
              onClick={onEdit.bind(null, item.id)}
            >
              <Edit />
            </button>
          </Can>
          <Can I="view" a="DownloadPresentation">
            <button
              className={`presentation__item-button mr-4 ${
                isDownloading ? "btn--disabled" : ""
              }`}
              onClick={onDownload.bind(null, item.id)}
              disabled={isDownloading}
            >
              <Download />
            </button>
          </Can>
          <Can I="update" a="DeletePresentation">
            <button
              className="presentation__item-button"
              onClick={onDelete.bind(null, item.id)}
            >
              <Delete />
            </button>
          </Can>
        </div>
      </div>
      <div className="separator" />
    </div>
  );
};
