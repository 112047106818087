import React from "react";
import profileImage from "assets/images/profile-image.png";

interface Props {
  image?: string;
  className?: string;
}

export const ProfileImage: React.FC<Props> = ({ image, className }) => (
  <div className={`profile-image ${className ?? ""}`}>
    <img width={22} src={image || profileImage} alt="profile" />
  </div>
);
