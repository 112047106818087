import React from "react";
import { Navigation } from "../Navigation";
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";
import Mplogo from "assets/images/LogoMP1x.jpg";
import { ProfileSettings } from "../ProfileSettings";
import { Link } from "react-router-dom";
import { AppRoutes } from "routing";

interface Props {
  setMenuOpen: () => void;
}
export const MainHeader: React.FC<Props> = ({ setMenuOpen }) => {
  return (
    <header className="header-container">
      <div className="header-hamburger">
        <HamburgerIcon onClick={setMenuOpen} />
      </div>
      <Link to={AppRoutes.News}>
        <img className="header-logo" src={Mplogo} alt="MPlogo" />
      </Link>
      <div className="header-navigation">
        <Navigation />
      </div>
      <div className="header-profile">
        <ProfileSettings />
      </div>
    </header>
  );
};
