import React from "react";

interface Props {
  score: number;
}
export const PasswordStrength: React.FC<Props> = ({ score }) => {
  return (
    <div className={`password-strength`}>
      <div className="password-strength-fill" data-strength={score}></div>
    </div>
  );
};
