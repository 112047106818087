import {
  AddOrUpdatePresentationResponse,
  DownloadPresentationResponse,
  PresentationsRequest,
  PresentationsResponse,
} from "services";
import { createService } from "services/serviceFactory";

const presentationService = createService("presentation");

async function getAllPresentations(
  accessToken: string,
  body: PresentationsRequest
) {
  return presentationService.post<PresentationsResponse>(
    "/presentation/v1/File/GetFiles",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function addOrUpdatePresentationFile(
  accessToken: string,
  body: FormData
) {
  return presentationService.post<AddOrUpdatePresentationResponse>(
    "presentation/v1/File/AddOrUpdateFile",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

async function deletePresentationItem(accessToken: string, id: number) {
  return presentationService.delete<any>(`presentation/v1/File/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

async function downloadPresentationItem(accessToken: string, id: number) {
  return presentationService.get<DownloadPresentationResponse>(
    "presentation/v1/File/Download",
    {
      params: { id },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export {
  getAllPresentations,
  addOrUpdatePresentationFile,
  deletePresentationItem,
  downloadPresentationItem,
};
