import React from "react";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";

interface Props {
  text: string;
  className?: string;
}
export const FilterGroup: React.FC<Props> = ({ text, children, className }) => {
  return (
    <div className={`filter-group ${className ?? ""}`}>
      <FilterIcon className="filter-group__icon mr-12" />
      <span className="filter-group__text filter-group__text--mr-24">
        {text}
      </span>
      <div className="filter-group__items">{children}</div>
    </div>
  );
};
