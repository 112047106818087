import { ProfileInfo } from "modules/common";
import * as React from "react";

import { SkeletonText } from "../SkeletonText";

interface Props {}

export const ProfileSkeleton: React.FC<Props> = () => {
  return (
    <div className="profile">
      <SkeletonText className="mb-48" width="30%" height="36px" />
      <div className="profile-container">
        <div className="profile-name">
          <span>
            <SkeletonText width="100%" height="32px" />
          </span>
        </div>
        <div>
          <SkeletonText className="mb-28" width="100%" height="28px" />
          <SkeletonText className="mb-28" width="100%" height="24px" />
          <div className="mb-40">
            <SkeletonText width="100%" height="24px" />
          </div>
          <section>
            <ProfileInfo>
              <SkeletonText width="70%" height="24px" />
              <SkeletonText width="100%" height="24px" />
              <SkeletonText width="70%" height="24px" />
              <SkeletonText width="100%" height="24px" />
              <SkeletonText width="70%" height="24px" />
              <SkeletonText width="100%" height="24px" />
              <SkeletonText width="70%" height="24px" />
              <SkeletonText width="100%" height="24px" />
            </ProfileInfo>
            <ProfileInfo className="mt-8">
              <div className="profile-settings-address profile-settings-col-2 ">
                <div>
                  <SkeletonText className="mb-12" width="40%" height="24px" />
                </div>
                <SkeletonText className="mb-12" width="20%" height="24px" />
                <SkeletonText className="mb-12" width="20%" height="24px" />
              </div>
            </ProfileInfo>
          </section>
          <div className="section-separator" />
          <section>
            <form>
              <ProfileInfo>
                <SkeletonText width="60%" height="24px" />
                <SkeletonText width="70%" height="24px" />
                <SkeletonText width="60%" height="24px" />
                <SkeletonText width="70%" height="24px" />
                <div className="profile-settings-button ">
                  <SkeletonText width="60%" height="24px" />
                </div>
              </ProfileInfo>
            </form>
          </section>
          <div className="section-separator" />
          <section>
            <SkeletonText className="mb-28" width="30%" height="24px" />
            <SkeletonText className="mb-36" width="60%" height="24px" />
            <div className="notifications">
              {[...new Array(8)].map((_item, index) => (
                <SkeletonText
                  key={index}
                  className="mb-12"
                  width="50%"
                  height="20px"
                />
              ))}
            </div>
          </section>
          <div className="section-separator" />
          <section>
            <SkeletonText className="mb-28" width="30%" height="24px" />
            <SkeletonText className="mb-36" width="60%" height="24px" />
            <div className="subscriptions mb-40">
              {[...new Array(8)].map((_item, index) => (
                <SkeletonText
                  key={index}
                  className="mb-12"
                  width="60%"
                  height="20px"
                />
              ))}
            </div>
            <SkeletonText className="mb-28" width="20%" height="24px" />
            <div className="subscriptions mb-40">
              {[...new Array(4)].map((_item, index) => (
                <SkeletonText
                  key={index}
                  className="mb-12"
                  width="60%"
                  height="20px"
                />
              ))}
            </div>
            <SkeletonText className="mb-28" width="20%" height="24px" />
            <div className="subscriptions mb-40">
              {[...new Array(6)].map((_item, index) => (
                <SkeletonText
                  key={index}
                  className="mb-12"
                  width="60%"
                  height="20px"
                />
              ))}
            </div>
          </section>
          <div className="section-separator" />
          <section>
            <SkeletonText className="mb-28" width="100%" height="26px" />
            <SkeletonText className="mb-36" width="100%" height="24px" />
            <SkeletonText width="20%" height="24px" />
          </section>
        </div>
      </div>
    </div>
  );
};
