import {
  isOneNumericChar,
  isOneSpecialChar,
  isOneUpperCaseChar,
  isOneLowerCaseChar,
  isTenCharLong,
} from "./validation";

export const calculatePasswordStrength = (input: string) => {
  let score = 0;

  if (isOneLowerCaseChar(input)) {
    score++;
  }

  if (isOneUpperCaseChar(input)) {
    score++;
  }

  if (isOneNumericChar(input)) {
    score++;
  }

  if (isOneSpecialChar(input)) {
    score++;
  }

  if (isTenCharLong(input)) {
    score++;
  }

  return score;
};
