import {
  Button,
  Loading,
  Modal,
  ModalFooter,
  ModalHeader,
} from 'modules/common';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddOrUpdatePresentationRequest } from 'services';
import {
  PresentationFormFirst,
  PresentationFormSecond,
  PresentationFormThird,
} from '.';
import { addOrUpdatePresentation } from '..';

export interface PresentationFormState {
  id?: string;
  file?: File;
  type: string;
  expertArea: string;
  operationTypes: string;
  measures: string;
  author: string;
}

interface Props {
  isVisible: boolean;
  toggleModal: () => void;
  id?: number;
  selectedPresentation?: AddOrUpdatePresentationRequest;
  selectedPresentationFile?: File;
  isEditPresentation: boolean;
}

export const PresentationUploadModal: React.FC<Props> = ({
  isVisible,
  toggleModal,
  id,
  selectedPresentation,
  selectedPresentationFile,
  isEditPresentation,
}) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  /**
   * Future improvement:
   *  - Preload data on edit
   *  - Get data from reducer and find item with passed ID
   *  - Populate formData if item exists
   */

  const [formData, setFormData] = useState<PresentationFormState>();

  const currentStep = [
    'presentation__modal-step--one',
    'presentation__modal-step--two',
    'presentation__modal-step--three',
  ][step];

  useEffect(() => {
    if (selectedPresentation) {
      setFormData({
        ...selectedPresentation,
        file: selectedPresentationFile ? selectedPresentationFile : undefined,
      });
    }
  }, [selectedPresentation]);

  function nextStep() {
    setStep(step + 1);
  }

  function previousStep() {
    setStep(step - 1);
  }

  function closeModal() {
    setFormData(undefined);
    setStep(0);
    toggleModal();
    setIsSubmitted(false);
  }

  function submit() {
    if (!formData || !formData.file) {
      return;
    }

    const presentationId = id ? id.toString() : '';

    setIsSubmitted(true);
    dispatch(
      addOrUpdatePresentation(
        {
          id: presentationId,
          file: formData.file,
          type: formData.type,
          expertArea: formData.expertArea,
          operationTypes: formData.operationTypes,
          measures: formData.measures,
          author: formData.author,
        },
        closeModal
      )
    );
  }

  function updateValues(data: any) {
    setFormData(data);
  }

  function disableButton(step: number) {
    switch (step) {
      case 0:
        return !formData?.file;
      case 1:
        return !formData?.expertArea;
      case 2:
        return (
          !formData?.measures ||
          !formData?.operationTypes ||
          !formData?.author ||
          isSubmitted
        );
      default:
        return true;
    }
  }

  function renderForm(step: number) {
    switch (step) {
      case 0:
        return (
          <PresentationFormFirst
            onChange={updateValues}
            data={formData}
            isEditPresentation={isEditPresentation}
          />
        );
      case 1:
        return (
          <PresentationFormSecond onChange={updateValues} data={formData} />
        );
      case 2:
        return (
          <PresentationFormThird onChange={updateValues} data={formData} />
        );
      default:
        return (
          <PresentationFormFirst
            onChange={updateValues}
            data={formData}
            isEditPresentation={isEditPresentation}
          />
        );
    }
  }

  return (
    <Modal
      isActive={isVisible}
      onClose={isSubmitted ? () => {} : closeModal}
      modalHeader={
        <ModalHeader
          onClose={isSubmitted ? () => {} : closeModal}
          title={`${id ? 'Uredi' : 'Dodaj novu'} prezentaciju`}
          isLoading={false}
        />
      }
      modalFooter={
        <ModalFooter>
          <Button
            type="button"
            size="small"
            variant="primary-ghost"
            isDisabled={isSubmitted || false}
            onClick={step === 0 ? closeModal : previousStep}
          >
            <span>{step === 0 ? 'Odustani' : 'Povratak'}</span>
          </Button>
          <Button
            type="button"
            size="small"
            variant="primary"
            isDisabled={disableButton(step)}
            onClick={step < 2 ? nextStep : submit}
          >
            {isSubmitted ? (
              <Loading className="educations__modal-spinner" />
            ) : (
              <span>{step < 2 ? 'Sljedeći korak' : 'Spremi'}</span>
            )}
          </Button>
        </ModalFooter>
      }
    >
      <span className={`presentation__modal-step ${currentStep}`} />

      <form className="educations_modal-form">{renderForm(step)}</form>
    </Modal>
  );
};
