import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  errorMessage: string;
  items: string[];
}

const initialState: State = {
  isLoading: false,
  errorMessage: "",
  items: [],
};

const operationTypesSlice = createSlice({
  name: "OperationTypes",
  initialState: initialState,
  reducers: {
    getOperationTypesRequest: (state) => {
      state.isLoading = true;
    },
    getOperationTypesSuccess: (
      state,
      action: PayloadAction<{ operationTypes: string[] }>
    ) => {
      state.isLoading = false;
      state.items = action.payload.operationTypes;
    },
    getOperationTypesError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = operationTypesSlice;

export const {
  getOperationTypesRequest,
  getOperationTypesSuccess,
  getOperationTypesError,
} = actions;

export const operationTypesReducer = operationTypesSlice.reducer;
