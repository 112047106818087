import { ControlProps, GroupTypeBase } from "react-select";

export function Control(props: ControlProps<any, boolean, GroupTypeBase<any>>) {
  const getDropdownSize = () => {
    switch (props.selectProps.size) {
      case "large":
        return "dropdown--large";
      case "medium":
        return "dropdown--medium";
      case "small":
        return "dropdown--small";
      default:
        return;
    }
  };
  return (
    <div
      className={`dropdown-container ${
        props.isFocused ? "dropdown--focused" : ""
      } ${getDropdownSize()} ${
        !props.selectProps.isValid ? "dropdown--error" : ""
      }`}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
}
