import React from "react";
import { components } from "react-select";
import { ReactComponent as ChevronIcon } from "../../../../assets/icons/chevron.svg";

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronIcon />
    </components.DropdownIndicator>
  );
};
