import React from "react";

interface Props {
  filters: string[];
  activeFilter: string;
  setFilter: (filter: string) => void;
}

export const EducationModalFilter: React.FC<Props> = ({
  filters,
  activeFilter,
  setFilter,
}) => {
  return (
    <div className="ml-40 mr-40">
      {filters.map((value, index) => {
        return (
          <button
            key={index}
            className={`educations__filter educations__modal-filter ${
              activeFilter === value && "educations__modal-filter--active"
            }`}
            onClick={() => setFilter(value)}
          >
            {value}
          </button>
        );
      })}
    </div>
  );
};
