import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";

const MONTHS = [
  "Siječanj",
  "Veljača",
  "Ožujak",
  "Travanj",
  "Svibanj",
  "Lipanj",
  "Srpanj",
  "Kolovoz",
  "Rujan",
  "Listopad",
  "Studeni",
  "Prosinac",
];

export function Navbar(props: any) {
  const currentDate = `${MONTHS[getMonth(props.month)]} ${getYear(
    props.month
  )}`;
  return (
    <div className={`${props.className}`}>
      <ArrowLeftIcon
        className="day-picker-control"
        onClick={() => props.onPreviousClick()}
      />
      {currentDate}
      <ArrowRightIcon
        className="day-picker-control"
        onClick={() => props.onNextClick()}
      />
    </div>
  );
}
