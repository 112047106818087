import React from "react";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as BaseCheckIcon } from "assets/icons/base-check.svg";

interface Props {
  isActive: boolean;
  className?: string;
  lengthRequirement: boolean;
  charRequiremenet: boolean;
  numberRequirement: boolean;
}
export const PassworRequirements: React.FC<Props> = ({
  className,
  isActive,
  lengthRequirement,
  charRequiremenet,
  numberRequirement,
}) => {
  return (
    <div
      className={`pwd-requirements ${
        isActive ? "pwd-requirements--active" : ""
      } ${className ?? ""}`}
    >
      <div>
        <div className="pwd-requirement">
          {lengthRequirement ? (
            <CheckIcon className="pwd-requirement__icon" />
          ) : (
            <BaseCheckIcon className="pwd-requirement__icon" />
          )}
          <span>Lozinka treba imati minimalno 8 znakova</span>
        </div>
        <div className="pwd-requirement">
          {charRequiremenet ? (
            <CheckIcon className="pwd-requirement__icon" />
          ) : (
            <BaseCheckIcon className="pwd-requirement__icon" />
          )}
          <span>Mora sadržavati minimalno jedno veliko slovo i jedno malo</span>
        </div>
        <div className="pwd-requirement">
          {numberRequirement ? (
            <CheckIcon className="pwd-requirement__icon" />
          ) : (
            <BaseCheckIcon className="pwd-requirement__icon" />
          )}
          <span>Mora sadržavati minimalno jedan broj</span>
        </div>
      </div>
    </div>
  );
};
