enum ApplicationType {
  Web = 0x001,
  Mobile = 0x002,
}
export const applicationTypes: Record<
  string,
  { value: number; label: string }
> = {
  Web: { value: ApplicationType.Web, label: "Web" },
  Mobile: { value: ApplicationType.Mobile, label: "Mobilna" },
};
