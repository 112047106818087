export const mockModuleUsageData = [
  {
    label: "Moje evidencije",
    web: 1112,
    mobile: 3311,
  },
  {
    label: "Edukacija",
    web: 98,
    mobile: 123,
  },
  {
    label: "Agroklimatske stanice",
    web: 24111,
    mobile: 17098,
  },
  {
    label: "Agrometeorologija",
    web: 18001,
    mobile: 17223,
  },
  {
    label: "Zaštita bilja",
    web: 123312,
    mobile: 98020,
  },
  {
    label: "Prezentacije",
    web: 4321,
    mobile: 7432,
  },
  {
    label: "Moj profil",
    web: 1001,
    mobile: 340,
  },
];
