import React from "react";
import { ModuleUsageTable, StatisticsContainer } from "modules/common";

interface Props {
  columns: any;
  data: any;
}
export const ModuleUsageStats: React.FC<Props> = ({ columns, data }) => {
  return (
    <StatisticsContainer title="Moduli po korištenju">
      <div className="module-table-container">
        <ModuleUsageTable columns={columns} data={data} />
      </div>
    </StatisticsContainer>
  );
};
