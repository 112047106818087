import { useTitle } from "modules/common";
import React from "react";
import { Link } from "react-router-dom";
import { AppRoutes } from "routing";

export const NotAuthorized: React.FC = () => {
  useTitle('Nemate autorizaciju - eSavjetnik');
  return (
    <div className="not-auth">
      <p>
        Nemate potrebnu autorizaciju, vratite se na{" "}
        <Link to={AppRoutes.News}>početnu stranicu</Link>
      </p>
    </div>
  );
};
