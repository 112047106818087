import { UserAccounts } from "services";
import { mapCounty, mapUserRoles, mapUserType } from "./mappingUtils";

export const mapUserAccounts = (userAccounts: UserAccounts[]) => {
    return userAccounts.map(a => {
        const role = mapUserRoles(a.roleType);
        const county = mapCounty(a.countyId);
        const userType = mapUserType(a.userType);

        return {
            uid: a.uid,
            isActive: a.isActive,
            firstName: a.firstName,
            lastName: a.lastName,
            legalEntityName: a.legalEntityName,
            userRole: role ?? { value: 0, label: "" },
            county: county?.label ?? "",
            userType: userType?.label ?? "",
            city: a.cityMunicipality,
            email: a.email,
            mibpg: a.mibpg,
            oib: a.oib,
            lastStatusUpdate: a.lastStatusUpdate,
            lastLogin: a.lastLogin,
        };
    });
};

export type MappedUserAccounts = ReturnType<typeof mapUserAccounts>;
