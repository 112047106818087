import React from "react";
import { Button } from "../../../common";

export const LinkExpired: React.FC = () => {
  return (
    <div>
      <h1 className="link-expired-heading mt-72">Poveznica je istekla</h1>
      <p className="link-expired-description">
        Vaša poveznica za resetiranje lozinke je istekla. Molimo zatražite novu
        poveznicu na početnoj stranici.
      </p>
      <Button size="medium" variant="primary" isDisabled={false} to="/">
        <span>Povratak na početnu</span>
      </Button>
    </div>
  );
};
