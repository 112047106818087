export const createObjectUrl = (
  fileAsByteArray: string,
  contentType: string,
  title: string,
  extension: string
) => {
  const binaryString = window.atob(fileAsByteArray);
  const bytes = new Uint8Array(binaryString.length);
  const base64ToArrayBuffer = bytes.map((_, i) => binaryString.charCodeAt(i));

  const blob = new Blob([base64ToArrayBuffer], {
    type: contentType,
  });
  const fileName = `${title}${extension}`;
  const url = URL.createObjectURL(blob);

  return {
    fileName,
    url,
    blob,
  };
};
