import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SendEmailResponse, SendNotificationResponse } from "services";

interface State {
  isLoadingEmail: boolean;
  errorEmail: string;
  email: SendEmailResponse | null;
  isLoadingNotification: boolean;
  errorNotification: string;
  notification: SendNotificationResponse | null;
}

const initialState: State = {
  isLoadingEmail: false,
  errorEmail: "",
  email: null,
  isLoadingNotification: false,
  errorNotification: "",
  notification: null,
};

const communicationSlice = createSlice({
  name: "Communication",
  initialState: initialState,
  reducers: {
    getEmailRequest: (state) => {
      state.isLoadingEmail = true;
    },
    getEmailSuccess: (
      state,
      action: PayloadAction<{ res: SendEmailResponse }>
    ) => {
      state.isLoadingEmail = false;
      state.email = action.payload.res;
    },
    getEmailError: (state, action: PayloadAction<{ error: string }>) => {
      state.isLoadingEmail = false;
      state.errorEmail = action.payload.error;
    },
    getNotificationRequest: (state) => {
      state.isLoadingNotification = true;
    },
    getNotificationSuccess: (
      state,
      action: PayloadAction<{ res: SendNotificationResponse }>
    ) => {
      state.isLoadingNotification = false;
      state.notification = action.payload.res;
    },
    getNotificationError: (state, action: PayloadAction<{ error: string }>) => {
      state.isLoadingNotification = false;
      state.errorNotification = action.payload.error;
    },
  },
});

const { actions } = communicationSlice;

export const {
  getEmailRequest,
  getEmailSuccess,
  getEmailError,
  getNotificationRequest,
  getNotificationSuccess,
  getNotificationError,
} = actions;

export const communicationsReducer = communicationSlice.reducer;
