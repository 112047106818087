import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Farmer, FarmHolderResponse, FarmMembersResponse } from "services";

interface State {
  isLoading: boolean;
  errorMessage: string | undefined;
  farmHolder: FarmHolderResponse | null;
  farmMembers: FarmMembersResponse | null;
  firstFarmMember: Farmer | null;
}

const initialState: State = {
  isLoading: false,
  errorMessage: undefined,
  farmHolder: null,
  farmMembers: null,
  firstFarmMember: null,
};

const farmSlice = createSlice({
  name: "Farm",
  initialState: initialState,
  reducers: {
    getFarmHolderRequest: (state) => {
      state.isLoading = true;
    },
    getFarmHolderSuccess: (
      state,
      action: PayloadAction<{ farmHolder: FarmHolderResponse }>
    ) => {
      state.isLoading = false;
      state.farmHolder = action.payload.farmHolder;
    },
    getFarmHolderError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
    getFarmMembersSuccess: (
      state,
      action: PayloadAction<{ farmMembers: FarmMembersResponse }>
    ) => {
      state.isLoading = false;
      state.farmMembers = action.payload.farmMembers;
      state.firstFarmMember = action.payload.farmMembers.farmMembers[0];
    },
    getFarmMembersError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = farmSlice;

export const {
  getFarmHolderRequest,
  getFarmHolderSuccess,
  getFarmHolderError,
  getFarmMembersSuccess,
  getFarmMembersError,
} = actions;

export const farmReducer = farmSlice.reducer;
