import * as React from "react";

type IInputBase = {
    size: "small" | "medium" | "large";
    name: string;
    className?: string;
    id: string;
    type: string;
    isValid: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    errorMessage: string;
    rightIcon?: React.ReactNode;
    lefIcon?: React.ReactNode;
    value?: any;
    readOnly?: boolean;
    flexField?: boolean;
    children?: React.ReactNode;
};

type PropsTextArea = {
    isArea: true;
    rows?: number;
    resizable?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
} & IInputBase;

type PropsInput = {
    isArea?: false;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    max?: number;
    min?: number;
} & IInputBase;

export type IInput = PropsTextArea | PropsInput;

export const Input = (props: IInput) => {
    const {
        size,
        name,
        className,
        id,
        type,
        isValid,
        isDisabled,
        placeholder,
        errorMessage,
        rightIcon,
        lefIcon,
        value,
        readOnly,
        flexField,
        children,
    } = props;

    const getInputSize = () => {
        return `form-input--${size}`;
    };

    const commonProps = {
        id,
        className: `form-input ${getInputSize()} ${rightIcon ? "input--spacing-right" : ""} ${
            lefIcon ? "input--spacing-left" : ""
        } ${!isValid ? "form-input--error" : ""} ${className ?? ""} ${
            isDisabled ? "form-input--disabled" : ""
        }`,
        value,
        name,
        readOnly,
        disabled: isDisabled,
        placeholder,
    };

    return (
        <div className={`input-container ${flexField ? "f-1" : ""}`}>
            <div className="input-icon-container">
                {props.isArea ? (
                    <textarea
                        {...commonProps}
                        className={`${commonProps.className ?? ""} ${
                            props.resizable ? "resizable" : ""
                        }`}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        rows={props.rows ?? 3}
                    />
                ) : (
                    <input
                        {...commonProps}
                        type={type}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        min={props.min}
                        max={props.max}
                    />
                )}
                {lefIcon && (
                    <div
                        className={`input-icon input-icon--left ${
                            isDisabled ? "input-icon--disabled" : ""
                        }`}
                    >
                        {lefIcon}
                    </div>
                )}
                {rightIcon && (
                    <div
                        className={`input-icon input-icon--right ${
                            isDisabled ? "input-icon--disabled" : ""
                        }`}
                    >
                        {rightIcon}
                    </div>
                )}
            </div>
            {children}
            {!isValid && <p className="error-message">{errorMessage}</p>}
        </div>
    );
};
