import { Loading, useTitle } from "modules/common";
import React, { useState } from "react";

export const Records: React.FC = () => {
  useTitle('Evidencije - eSavjetnik');
  const [showLoading, setShowLoading] = useState(true);

  return (
    <>
      {showLoading && (
        <div className="educations-container">
          <Loading />
        </div>
      )}
      <iframe
        onLoad={() => setShowLoading(false)}
        className="agrivi-iframe"
        title="Evidencije"
        src={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_AGRIVI_URL}/documents`}
      />
    </>
  );
};
