export enum RoleType {
    None = 0,
    Citizen = 1,
    Farmer = 2,
    // Fisherman = 3,
    EmployeeMp = 4,
    EmployeeApprrr = 5,
    EmployeeAdviser = 6,
    MpAdminFarmer = 7,
    // MpAdminFisherman = 8,
    MpSuperAdmin = 9,
    AdviserAdminFarmer = 10,
    // AdviserAdminFisherman = 11,
    AdviserSuperAdmin = 12,
}

export const userRoles: Record<string, { value: number; label: string }> = {
    Citizen: {
        value: RoleType.Citizen,
        label: "Građanin",
    },
    Farmer: {
        value: RoleType.Farmer,
        label: "Poljoprivrednik",
    },
    // Fisherman: {
    //   value: RoleType.Fisherman,
    //   label: "Ribar",
    // },
    EmployeeMp: {
        value: RoleType.EmployeeMp,
        label: "Djelatnik MP",
    },
    EmployeeApprrr: {
        value: RoleType.EmployeeApprrr,
        label: "Djelatnik APPRRR",
    },
    EmployeeAdviser: {
        value: RoleType.EmployeeAdviser,
        label: "Savjetnik",
    },
    MpAdminFarmer: {
        value: RoleType.MpAdminFarmer,
        label: "Djelatnik MP Poljo – administrator",
    },
    // MpAdminFisherman: {
    //   value: RoleType.MpAdminFisherman,
    //   label: "Djelatnik MP Ribar – administrator",
    // },
    MpSuperAdmin: {
        value: RoleType.MpSuperAdmin,
        label: "Djelatnik MP Super – administrator",
    },
    AdviserAdminFarmer: {
        value: RoleType.AdviserAdminFarmer,
        label: "Savjetnik Poljo – administrator",
    },
    // AdviserAdminFisherman: {
    //   value: RoleType.AdviserAdminFisherman,
    //   label: "Savjetnik Ribar – administrator",
    // },
    AdviserSuperAdmin: {
        value: RoleType.AdviserSuperAdmin,
        label: "Savjetnik Super – administrator",
    },
};

export enum NewRoleType {
    None = 0,
    Citizen,
    Farmer,
    PrivateAdvisor,
    MinistryOfficial,
    CommunicatorOfficial,
    MinistryAdvisor,
    CommunicatorAdvisor,
    EquipmentAdministrator,
    SuperAdministrator,
}

export const newRoles: Record<string, { value: number; label: string }> = {
    Citizen: {
        value: NewRoleType.Citizen,
        label: "Građanin",
    },
    Farmer: {
        value: NewRoleType.Farmer,
        label: "Poljoprivrednik",
    },
    PrivateAdvisor: {
        value: NewRoleType.PrivateAdvisor,
        label: "Privatni savjetnik",
    },
    MinistryOfficial: {
        value: NewRoleType.MinistryOfficial,
        label: "Službenik ministarstva",
    },
    CommunicatorOfficial: {
        value: NewRoleType.CommunicatorOfficial,
        label: "Službenik komunikator",
    },
    MinistryAdvisor: {
        value: NewRoleType.MinistryAdvisor,
        label: "Savjetnik ministarstva",
    },
    CommunicatorAdvisor: {
        value: NewRoleType.CommunicatorAdvisor,
        label: "Savjetnik komunikator",
    },
    EquipmentAdministrator: {
        value: NewRoleType.EquipmentAdministrator,
        label: "Administrator opreme",
    },
    SuperAdministrator: {
        value: NewRoleType.SuperAdministrator,
        label: "Super administrator",
    },
};

export const getNewRoleLabel = (role: NewRoleType) => {
    const roleKey = Object.keys(newRoles).find((key) => newRoles[key].value === role);
    if (!roleKey) return "";
    return newRoles[roleKey].label;
}