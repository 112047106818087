import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ValueType } from "react-select";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import {
    Input,
    AdministrationTable,
    Pagination,
    OptionType,
    Modal,
    ModalHeader,
    ModalFooter,
    Button,
    UserInfo,
    Loading,
    findUserTypeByLabel,
    UserInfoLoading,
    useTitle,
} from "modules/common";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as DetailsIcon } from "assets/icons/details.svg";
import {
    AdministrationFilters,
    deactivateUser,
    getAccountListRequest,
    getUserAccountData,
    getAccountList,
} from "modules/dashboard";
import { RootState } from "store";
import { SortingRule } from "react-table";

const DefaultPageSize = {
    value: "10",
    label: "10",
};

const PAGE_NEIGHBOURS = 1;
const INPUT_DEBOUNCE_TIME = 500; //miliseconds

export const UserAdministration: React.FC = () => {
    useTitle("Administracija korisnika - eSavjetnik");
    const dispatch = useDispatch();
    const accountList = useSelector((state: RootState) => state.accountList);
    const userAccount = useSelector((state: RootState) => state.userAccount);
    const { userData } = userAccount;
    const deactivateAccount = useSelector((state: RootState) => state.deactivateUser);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [countyFilter, setCountyFilter] = useState<number[]>([]);
    const [roleFilter, setRoleFilter] = useState<number[]>([]);
    const [statusFilter, setStatusFilter] = useState<number>(-1);
    const [sortBy, setSortBy] = useState<SortingRule<object>>();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchTerm(value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
        setPageSize(value);
        setCurrentPage(1);
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        setCurrentPage(Number(parseInt(event.currentTarget.id)));
    };

    const handleNexPageClick = () => {
        setCurrentPage(prev => prev + 1);
    };

    const handlePrevPageClick = () => {
        setCurrentPage(prev => prev - 1);
    };

    const handleModalOpen = useCallback(
        (uid: string, userType: number) => {
            dispatch(getUserAccountData(uid, userType));
            setIsModalOpen(true);
        },
        [dispatch]
    );

    const handleModalClose = () => {
        setIsModalOpen(false);
        // refetch data to display up to date values
        dispatch(getAccountListRequest());
        const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
        debouncedSearch(
            currentPage,
            searchTerm,
            size,
            countyFilter,
            roleFilter,
            statusFilter,
            sortBy
        );
    };

    const handleDeactivateUser = (uid: string, userType: number) => {
        dispatch(deactivateUser(uid, userType));
        setIsModalOpen(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(
            (
                currentPage: number,
                searchTerm: string | null,
                size: number,
                counties: number[],
                roles: number[],
                status: number,
                sortBy: SortingRule<object> | undefined
            ) =>
                dispatch(
                    getAccountList({
                        pageNumber: currentPage,
                        pageSize: size,
                        countyFilter: counties,
                        userRoleFilter: roles,
                        search: searchTerm,
                        statusFilter: status,
                        sortField: sortBy?.id,
                        descending: sortBy?.desc,
                    })
                ),
            INPUT_DEBOUNCE_TIME
        ),
        []
    );

    useEffect(() => {
        const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
        dispatch(getAccountListRequest());
        debouncedSearch(
            currentPage,
            searchTerm,
            size,
            countyFilter,
            roleFilter,
            statusFilter,
            sortBy
        );
    }, [
        countyFilter,
        currentPage,
        debouncedSearch,
        dispatch,
        pageSize?.value,
        roleFilter,
        searchTerm,
        deactivateAccount.deactivationSuccess,
        statusFilter,
        sortBy,
    ]);

    const tableColumns = useMemo(
        () => [
            {
                Header: "STATUS",
                accessor: "isActive",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <span>{original.isActive ? "Aktivan" : "Neaktivan"}</span>;
                },
            },
            {
                Header: "DATUM PROMJENE STATUSA",
                accessor: "lastStatusUpdate",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <span>{original.lastStatusUpdate}</span>;
                },
            },
            {
                Header: "NAZIV KORISNIKA",
                id: "name",
                accessor: (data: any) => {
                    return (
                        <div className="user-info-container">
                            <span className="user-info">
                                {data.firstName ?? ""} {data.lastName ?? ""}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "OIB",
                accessor: "oib",
            },
            {
                Header: "ULOGA",
                accessor: "userRole",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <div className="role-row ">{original.userRole.label}</div>;
                },
            },
            {
                Header: "MIBPG",
                accessor: "mibpg",
            },
            {
                Header: "EMAIL",
                accessor: "email",
            },
            {
                Header: "ŽUPANIJA",
                accessor: "county",
            },
            {
                Header: "GRAD",
                accessor: "city",
            },
            {
                Header: "DATUM ZADNJE PRIJAVE",
                accessor: "lastLogin",
                Cell: ({ row }: any) => {
                    const { original } = row;
                    return <span>{original.lastLogin}</span>;
                },
            },
            {
                id: "info",
                disableSortBy: true,
                Cell: ({ row }: any) => {
                    const { original } = row;
                    const type = findUserTypeByLabel(original.userType);
                    return (
                        <DetailsIcon
                            className="details-icon"
                            onClick={() => handleModalOpen(original.uid, type?.value ?? 0)}
                        />
                    );
                },
            },
        ],
        [handleModalOpen]
    );

    return (
        <div className="user-administration">
            <h1 className="user-administration__heading mb-28">Administracija korisnika</h1>
            <div className="user-administration__filters mb-44">
                <AdministrationFilters
                    roleFilter={roleFilter}
                    setRoleFilter={setRoleFilter}
                    countyFilter={countyFilter}
                    setCountyFilter={setCountyFilter}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                />
                <Input
                    name="ime"
                    className="search"
                    id="ime"
                    type="text"
                    size="small"
                    placeholder="Pretraživanje korisnika"
                    isValid={true}
                    onChange={handleSearchChange}
                    errorMessage=""
                    lefIcon={<SearchIcon className="search-icon" />}
                />
            </div>
            {accountList.isFetchingAccountList ? (
                <Loading />
            ) : accountList.data && !accountList.errorMessage ? (
                <>
                    <div className="table-container">
                        <AdministrationTable
                            data={accountList.data}
                            columns={tableColumns}
                            setSortBy={setSortBy}
                        />
                    </div>
                    {accountList.data.length === 0 ? null : (
                        <Pagination
                            className="mt-24"
                            currentPage={currentPage}
                            numberOfPages={Math.ceil(
                                accountList.userAccountCount /
                                    parseInt(pageSize?.value ?? DefaultPageSize.value)
                            )}
                            pageNeighbours={PAGE_NEIGHBOURS}
                            pageSize={pageSize ?? DefaultPageSize}
                            onPageSizeChange={handlePageSizeChange}
                            handlepageClick={handlePageClick}
                            next={handleNexPageClick}
                            prev={handlePrevPageClick}
                        />
                    )}
                </>
            ) : (
                <p>{accountList.errorMessage}</p>
            )}
            <Modal
                isActive={isModalOpen}
                onClose={handleModalClose}
                modalHeader={
                    <ModalHeader
                        onClose={handleModalClose}
                        title={`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}
                        isLoading={userAccount.isFetchingUserData || !userData}
                    />
                }
                modalFooter={
                    userAccount.isFetchingUserData || !userData ? null : (
                        <ModalFooter>
                            <Button
                                type="button"
                                size="small"
                                variant="primary"
                                isDisabled={false}
                                onClick={handleModalClose}
                            >
                                <span>Zatvori</span>
                            </Button>
                        </ModalFooter>
                    )
                }
            >
                {userAccount.isFetchingUserData || !userData ? (
                    <UserInfoLoading />
                ) : (
                    <UserInfo userInfo={userData} onDeactivateClick={handleDeactivateUser} />
                )}
            </Modal>
        </div>
    );
};
