import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EducationItem, EducationReminder } from "services";
import { RootState } from "store";
import { addReminder } from "..";

// Hook for handling adding new and update existing reminders.
// It also handles deletion of reminders.

export function useReminderHandler(currentPickedEducation?: EducationItem) {
  const dispatch = useDispatch();
  const { allReminders } = useSelector((state: RootState) => state.educations);
  const [pickedReminders, setPickedReminders] = useState<EducationReminder[]>(
    []
  );

  function handleAddNewReminder(reminder: EducationReminder) {
    setPickedReminders((prev) => [...prev, reminder]);
    dispatch(addReminder(reminder));
  }

  function handleAddExistingReminder(reminder: EducationReminder) {
    const isReminderExisting = pickedReminders.find(
      (r) => r.education.uid === reminder.education.uid
    );

    // This check prevents user for saving empty or already picked reminders.
    if (
      isReminderExisting &&
      isReminderExisting.remindInDays === reminder.remindInDays
    )
      return;

    setPickedReminders((prev) => [
      ...prev.filter((r) => r.education.uid !== reminder.education.uid),
      reminder,
    ]);
    dispatch(addReminder(reminder));
  }

  function handleRemoveReminder(reminder: EducationReminder) {
    setPickedReminders((prev) => [
      ...prev.filter((r) => r.education.uid !== reminder.education.uid),
    ]);
    dispatch(addReminder(reminder));
  }

  function handleAddReminder(reminder: EducationReminder) {
    if (!currentPickedEducation) return;

    const isReminderExisting = pickedReminders.find(
      (r) => r.education.uid === currentPickedEducation.uid
    );

    if (!isReminderExisting) {
      Boolean(reminder.remindInDays.length) && handleAddNewReminder(reminder);
      return;
    }

    Boolean(reminder.remindInDays.length)
      ? handleAddExistingReminder(reminder)
      : handleRemoveReminder(reminder);
  }

  useEffect(() => {
    if (!allReminders) {
      setPickedReminders([]);
      return;
    }

    setPickedReminders(allReminders.items);
  }, [allReminders]);

  return { handleAddReminder, pickedReminders };
}
