import React from "react";
import { useTable } from "react-table";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";

interface Props {
  data: Array<any>;
  columns: any;
}
export const ModuleUsageTable: React.FC<Props> = ({ data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <table className="table borderless" {...getTableProps()}>
      <TableHeader headerGroups={headerGroups} />
      <TableBody
        rows={rows}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
      />
    </table>
  );
};
