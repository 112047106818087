import { createService } from "services/serviceFactory";
import {
  AccountDashboardDataResponse,
  ProfileResonse,
  SendEmailResponse,
  SendNotificationResponse,
  UserAccountResponse,
  UserAccountsResponse,
} from ".";
import {
  SendEmailRequest,
  SendNotificationRequest,
  UpdateMyAccountMetadataRequest,
  UpdateProfileRequest,
  UserAccountsRequest,
} from "./request";
import { UploadAttachmentResponse, UserAccountsByUidResponse } from "./response";

const accountService = createService("account");

async function getUserAccounts(data: UserAccountsRequest, accessToken: string) {
  return accountService.post<UserAccountsResponse>(
    "/account/v1/Account/GetUserAccounts",
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getUserAccountsByUids(uids: string[], accessToken: string) {
  return accountService.post<UserAccountsByUidResponse>(
    "/account/v1/Account/GetUserAccountsByUids", uids,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getUserAccount(
  uid: string,
  userType: number,
  accessToken: string
) {
  return accountService.post<UserAccountResponse>(
    "/account/v1/Account/GetUserAccount",
    {
      uid,
      userType,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function deleteUserAccount(
  uid: string,
  userType: number,
  accessToken: string
) {
  return accountService.delete("/account/v1/Account/DeleteUserAccount", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      uid,
      userType,
    },
  });
}

async function updateRoles(
  uid: string,
  roleType: number,
  userType: number,
  accessToken: string
) {
  return accountService.post(
    "/account/v1/Account/UpdateUserAccountRoles",
    {
      uid,
      userType,
      roleType,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getMyAccount(accessToken: string) {
  return accountService.get<ProfileResonse>(
    "/account/v1/Account/GetMyAccount",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function updateMyAccountSettings(
  requestData: UpdateProfileRequest,
  accessToken: string
) {
  return accountService.post(
    "/account/v1/Account/UpdateMyAccountSettings",
    {
      ...requestData,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function updateMyAccountMobileNumber(
  mobileNumber: string,
  accessToken: string
) {
  return accountService.post(
    "/account/v1/Account/UpdateMyAccountMobileNumber",
    {
      mobileNumber,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function updateMyAccountMetadata(
  requestData: UpdateMyAccountMetadataRequest,
  accessToken: string
) {
  return accountService.post(
    "/account/v1/Account/UpdateMyAccountMetadata",
    {
      ...requestData,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function deleteMyAccount(accessToken: string) {
  return accountService.delete("/account/v1/Account/DeleteMyAccount", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

async function getUserAccountDashboardData(
  accessToken: string,
  body: {
    dateFrom: Date | null;
    dateTo: Date | null;
  }
) {
  return accountService.post<AccountDashboardDataResponse>(
    "/account/v1/Account/GetUserAccountDashboardData",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getAllCounties(accessToken: string) {
  return accountService.get("/account/v1/Account/GetAllCounties", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

async function getSettlements(accessToken: string, countyId: any) {
  return accountService.get("/account/v1/Account/GetSettlementsByCountyId", {
    params: {
      countyId,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

async function getCityMunicipalities(accessToken: string, countyIds: string) {
  return accountService.get(
    "/account/v1/Account/GetCityMunicipalitiesByCountyValues",
    {
      params: {
        countyIds,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function initializeNew(accessToken: string, body: SendEmailRequest) {
  return accountService.post<SendEmailResponse>(
      "/account/v1/NotificationTransmit/InitializeNew",
      body,
      {
          headers: {
              Authorization: `Bearer ${accessToken}`,
          },
      }
    );
}

async function sendEmail(accessToken: string, body: SendEmailRequest) {
    return accountService.post<SendEmailResponse>(
        "/account/v1/NotificationTransmit/SendNotification",
        body,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
}
async function uploadAttachment(accessToken: string, body: FormData) {
    return accountService.post<UploadAttachmentResponse>(
        "/account/v1/NotificationTransmit/UploadAttachment",
        body,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            },
        }
    );
}

async function deleteAttachment(accessToken: string) {
    return accountService.post<SendEmailResponse>(
        "/account/v1/NotificationTransmit/DeleteAttachment",
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
}

async function saveEmail(accessToken: string) {
    return accountService.post<SendEmailResponse>(
        "/account/v1/NotificationTransmit/SaveNotification",
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
}


async function sendNotification(accessToken: string,  body: SendNotificationRequest) {
  return accountService.post<SendNotificationResponse>(
    "/account/v1/Account/SendUserNotification",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export {
  getUserAccounts,
  getUserAccount,
  getUserAccountsByUids,
  deleteUserAccount,
  updateRoles,
  getMyAccount,
  updateMyAccountSettings,
  updateMyAccountMobileNumber,
  updateMyAccountMetadata,
  deleteMyAccount,
  getUserAccountDashboardData,
  getAllCounties,
  getSettlements,
  getCityMunicipalities,
  initializeNew,
  uploadAttachment,
  sendEmail,
  sendNotification,
};
