import { useTitle } from "modules/common";
import { PanelMenu } from "modules/common/components/PanelMenu";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";

const sumMenuItems = [
    {
        label: "Intenzitet napada štetnika",
        link: "/zastita-bilja/intenzitet-napada-stetnika",
    },
    {
        label: "Kretanje brojnosti populacije štetnika",
        link: "/zastita-bilja/brojnost-populacije-stetnika",
        fullScreen: true,
    },
    {
        label: "Prve pojave štetnika",
        link: "/zastita-bilja/prve-pojave-stetnika",
    },
    {
        label: "Bolesti i štetnici",
        link: "/zastita-bilja/bolesti-stetnici",
    },
    {
        label: "Preporuke o zaštiti bilja",
        link: "/zastita-bilja/preporuke",
    },
    {
        label: "Sakupljanje ambalažnog otpada",
        link: "/zastita-bilja/sakupljanje-otpada",
        fullScreen: true,
    },
];

export const PlantProtection = () => {
    useTitle("Zaštita bilja - eSavjetnik");
    const { search } = useLocation();
    const [link, setLink] = useState<string>(sumMenuItems[0].link);
    const [activeFullScreen, setActiveFullScreen] = useState<boolean>(false);
    const [isBackButtonVisible, setIsBackButtonVisible] = useState<boolean>(false);

    useEffect(() => {
        const item = sumMenuItems.find(item => item.link === link);
        setActiveFullScreen(item?.fullScreen ?? false);
    }, [link]);

    return (
        <div className="plant-protection-container">
            <PanelMenu items={sumMenuItems} setLink={setLink} activeFullScreen={activeFullScreen} />
            <div className="frame-container">
                <iframe
                    className="agrivi-iframe"
                    title="Zastita bilja"
                    src={`${process.env.REACT_APP_AGRIVI_URL}${link}${search}`}
                    onLoad={() => setIsBackButtonVisible(true)}
                />
                {activeFullScreen && isBackButtonVisible && (
                    <div
                        className="back-button circle"
                        onClick={() => {
                            setActiveFullScreen(false);
                            setIsBackButtonVisible(false);
                        }}
                    >
                        <ChevronLeftIcon />
                    </div>
                )}
            </div>
        </div>
    );
};
