import React, { useEffect, useRef, useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import sub from "date-fns/sub";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfQuarter from "date-fns/startOfQuarter";
import endOfQuarter from "date-fns/endOfQuarter";
import subQuarters from "date-fns/subQuarters";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import { Input } from "..";
import { Navbar } from "./Navbar";
import "../../../../css/rangeDatePicker.component.css";
import { DayModifiers, Modifiers } from "react-day-picker";
import { DateSideControls } from "./DateSideControls";
import { format } from "date-fns";
interface Props {
  onClick: (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedDays: {
    from: Date;
    to: Date;
  };
  modifiers: Partial<Modifiers> | undefined;
  updateRange: (from: Date, to: Date) => void;
}
const DateInput = React.forwardRef((props: any, ref) => {
  return (
    <Input
      {...props}
      name="DatePickerInput"
      id="DatePickerInput"
      className="day-picker-input"
      type="text"
      size="small"
      isValid={true}
      errorMessage=""
      lefIcon={<CalendarIcon />}
      rightIcon={<ChevronIcon />}
    />
  );
});

const WEEKDAYS_SHORT = ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"];
const firstDayOfWeek = {
  hr: 1,
};

export const RangeDatePicker: React.FC<Props> = ({
  onClick,
  selectedDays,
  modifiers,
  updateRange,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedControl, setSelectedControl] = useState(0);
  const dateRangeContainerRef = useRef<HTMLDivElement>(null);
  const dateRangeRef = useRef<DayPickerInput>(null);
  const today = new Date();

  const handleClick = (e: any) => {
    const dayPickerNode = dateRangeContainerRef.current;

    if (dayPickerNode && !dayPickerNode.contains(e.target)) {
      dateRangeRef.current?.hideDayPicker();
      return;
    }

    setShowOverlay(true);
  };

  const setToday = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const { id } = e.currentTarget;

    setSelectedControl(parseInt(id));
    updateRange(today, today);
  };

  const setYesterday = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const { id } = e.currentTarget;
    setSelectedControl(parseInt(id));
    const yesterday = sub(today, { days: 1 });
    updateRange(yesterday, yesterday);
  };

  const setCurrentWeek = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const { id } = e.currentTarget;
    setSelectedControl(parseInt(id));
    const weekStart = startOfWeek(today, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(today, { weekStartsOn: 1 });
    updateRange(weekStart, weekEnd);
  };

  const setLastWeek = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const { id } = e.currentTarget;
    setSelectedControl(parseInt(id));
    const lastWeekDay = sub(today, { days: 7 });
    const lastWeekStart = startOfWeek(lastWeekDay, { weekStartsOn: 1 });
    const lastWeekEnd = endOfWeek(lastWeekDay, { weekStartsOn: 1 });
    updateRange(lastWeekStart, lastWeekEnd);
  };

  const setCurrentMonth = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const { id } = e.currentTarget;
    setSelectedControl(parseInt(id));
    const currentMonthStart = startOfMonth(today);
    const currentMonthEnd = endOfMonth(today);
    updateRange(currentMonthStart, currentMonthEnd);
  };

  const setLastMonth = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const { id } = e.currentTarget;
    setSelectedControl(parseInt(id));
    const lastMonth = sub(today, { months: 1 });
    const lastMonthStart = startOfMonth(lastMonth);
    const lastMonthEnd = endOfMonth(lastMonth);

    updateRange(lastMonthStart, lastMonthEnd);
  };

  const setCurrentQuarter = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const { id } = e.currentTarget;
    setSelectedControl(parseInt(id));
    const currentQuarterStart = startOfQuarter(today);
    const currentQuarterEnd = endOfQuarter(today);
    updateRange(currentQuarterStart, currentQuarterEnd);
  };

  const setPreviousQuarter = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const { id } = e.currentTarget;
    setSelectedControl(parseInt(id));
    const prevQuarter = subQuarters(today, 1);
    const prevQuarterStart = startOfQuarter(prevQuarter);
    const prevQuarterEnd = endOfQuarter(prevQuarter);
    updateRange(prevQuarterStart, prevQuarterEnd);
  };

  const formatValue = (date: Date) => {
    return format(date, "dd.MM.yy");
  };

  const Overlay = ({ classNames, selectedDay, children, ...props }: any) => {
    return (
      <div className={classNames.overlayWrapper} {...props}>
        <div className={classNames.overlay}>
          <DateSideControls>
            <span
              id="1"
              onClick={setToday}
              className={`controls-text ${
                selectedControl === 1 ? "controls-text--active" : ""
              }`}
            >
              Danas
            </span>
            <span
              id="2"
              onClick={setYesterday}
              className={`controls-text ${
                selectedControl === 2 ? "controls-text--active" : ""
              }`}
            >
              Jučer
            </span>
            <span
              id="3"
              onClick={setCurrentWeek}
              className={`controls-text ${
                selectedControl === 3 ? "controls-text--active" : ""
              }`}
            >
              Ovaj tjedan
            </span>
            <span
              id="4"
              onClick={setLastWeek}
              className={`controls-text ${
                selectedControl === 4 ? "controls-text--active" : ""
              }`}
            >
              Prošli tjedan
            </span>
            <span
              id="5"
              onClick={setCurrentMonth}
              className={`controls-text ${
                selectedControl === 5 ? "controls-text--active" : ""
              }`}
            >
              Ovaj mjesec
            </span>
            <span
              id="6"
              onClick={setLastMonth}
              className={`controls-text ${
                selectedControl === 6 ? "controls-text--active" : ""
              }`}
            >
              Prošli mjesec
            </span>
            <span
              id="7"
              onClick={setCurrentQuarter}
              className={`controls-text ${
                selectedControl === 7 ? "controls-text--active" : ""
              }`}
            >
              Ovaj kvartal
            </span>
            <span
              id="8"
              onClick={setPreviousQuarter}
              className={`controls-text ${
                selectedControl === 8 ? "controls-text--active" : ""
              }`}
            >
              Prošli kvartal
            </span>
          </DateSideControls>
          {children}
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div ref={dateRangeContainerRef}>
      <DayPickerInput
        overlayComponent={Overlay}
        value={
          selectedDays.from &&
          selectedDays.to &&
          `${formatValue(selectedDays.from)} - ${formatValue(selectedDays.to)}`
        }
        component={DateInput}
        ref={dateRangeRef}
        inputProps={{ name: "DatePickerInput" }}
        hideOnDayClick={false}
        keepFocus={true}
        placeholder="Odaberite datum"
        onDayPickerShow={() => setShowOverlay(true)}
        onDayPickerHide={() => setShowOverlay(false)}
        showOverlay={showOverlay}
        dayPickerProps={{
          navbarElement: <Navbar />,
          className: "Range",
          locale: "hr",
          numberOfMonths: 2,
          weekdaysShort: WEEKDAYS_SHORT,
          firstDayOfWeek: firstDayOfWeek["hr"],
          modifiers: modifiers,
          onDayClick: onClick,
          selectedDays: selectedDays,
        }}
      />
    </div>
  );
};
