import { UserAccount } from "services";
import { mapCounty, mapUserRoles, mapUserType } from "./mappingUtils";

export const mapUserProfile = (userAccount: UserAccount) => {
  const role = mapUserRoles(userAccount.roleType);
  const county = mapCounty(userAccount.countyId);
  const userType = mapUserType(userAccount.userType);

  return {
    uid: userAccount.uid ?? "",
    mibpg: userAccount.mibpg ?? "",
    email: userAccount.email ?? "",
    username: userAccount.username ?? "",
    legalEntityName: userAccount.legalEntityName ?? "",
    firstName: userAccount.firstName ?? "",
    lastName: userAccount.lastName ?? "",
    street: userAccount.street ?? "",
    streetNumber: userAccount.streetNumber ?? "",
    postalNumber: userAccount.postalNumber ?? "",
    city: userAccount.city ?? "",
    settlement: userAccount.settlement ?? "",
    settlementId: userAccount.settlementId ?? "",
    country: userAccount.country ?? "",
    phoneNumber: userAccount.phoneNumber ?? "",
    mobileNumber: userAccount.mobileNumber ?? "",
    county: county?.label ?? "",
    countyId: county?.value ?? "",
    roleType: role,
    userType,
    notificationType: userAccount.notificationType,
    infoType: userAccount.infoType,
    members: userAccount.members,
  };
};

export type MappedUserProfile = ReturnType<typeof mapUserProfile>;
