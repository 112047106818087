import { InputLabel, RadioInput } from "modules/common";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddOrUpdatePresentationRequest } from "services";
import { RootState } from "store";

interface Props {
  onChange: (data: Partial<AddOrUpdatePresentationRequest>) => void;
  data:
    | Pick<
        AddOrUpdatePresentationRequest,
        "measures" | "operationTypes" | "author"
      >
    | undefined;
}

export const PresentationFormThird: React.FC<Props> = ({ onChange, data }) => {
  const { items: measures } = useSelector((state: RootState) => state.measures);
  const { items: operationTypes } = useSelector(
    (state: RootState) => state.operationTypes
  );

  const [selectedMeasure, setSelectedMeasure] = useState(
    data?.measures || null
  );
  const [selectedOperation, setSelectedOperation] = useState(
    data?.operationTypes || null
  );
  const [author, setAuthor] = useState(data?.author || "");

  const handleMeasureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSelectedMeasure(value);
    onChange({ ...data, measures: value });
  };

  const handleOperationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget;
    setSelectedOperation(value);
    onChange({ ...data, operationTypes: value });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setAuthor(value);
    onChange({ ...data, author: value });
  };

  return (
    <>
      <InputLabel isValid labelFor="Type" labelText="Mjera" />
      {measures.map((value, index) => {
        return (
          <div key={index} className="mb-16">
            <RadioInput
              htmlFor={`${value}${index}`}
              onChange={handleMeasureChange}
              size="base"
              checked={selectedMeasure === value}
              value={value}
              text={value}
            />
          </div>
        );
      })}
	  <div className="mb-16">
		<RadioInput
			htmlFor="other"
			onChange={handleMeasureChange}
			size="base"
			checked={selectedMeasure === 'other'}
			value="other"
			text="Ostalo"
		/>
	  </div>

      <div className="separator" />

      {operationTypes.length && (
        <>
          <InputLabel isValid labelFor="Type" labelText="Operacija" />
          {operationTypes.map((value, index) => {
            return (
              <div key={index} className="mb-16">
                <RadioInput
                  htmlFor={`${value}${index}`}
                  onChange={handleOperationChange}
                  size="base"
                  checked={selectedOperation === value}
                  value={value}
                  text={value}
                />
              </div>
            );
          })}

          <div className="separator" />
        </>
      )}

      <InputLabel isValid labelFor="Type" labelText="Ime autora" />
      <input
        className={`form-input form-input--large `}
        onChange={handleInputChange}
        value={author}
      />
    </>
  );
};
