import {
  Button,
  Input,
  RoleType,
  OptionType,
  Loading,
  NotificationType,
  useTitle,
} from "modules/common";
import React, { useCallback, useEffect, useState } from "react";
import {
  FileUpload,
  getAccountList,
  getAccountListRequest,
  NotificationSelect,
} from "modules/dashboard";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { RootState } from "store";
import {
  CommunicationAdditionalFiltersModal,
  CommunicationFilters,
  CommunicationUsersModal,
  sendEmailAsync,
} from "modules/communication";
import { getMeasures, getOperationTypes } from "modules/education";
import { Controller, useForm } from "react-hook-form";

const DefaultPageSize = {
  value: "10",
  label: "10",
};

const INPUT_DEBOUNCE_TIME = 500; //miliseconds

export const Communication: React.FC = () => {
  useTitle('Komunikacija s korisnicima - eSavjetnik');
  const dispatch = useDispatch();
  const { data, userAccountCount, isFetchingAccountList } = useSelector(
    (state: RootState) => state.accountList
  );
  const { isLoadingEmail, isLoadingNotification, email } = useSelector(
    (state: RootState) => state.communications
  );

  const {
    register,
    formState: { errors, isValid },
    getValues,
    reset,
    control,
  } = useForm<{ subject: string; body: string }>({
    mode: "onChange",
  });

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [roleFilter, setRoleFilter] = useState<number>(0);
  const [countyFilter, setCountyFilter] = useState<number[]>([]);
  const [cityFilter, setCityFilter] = useState<number[]>([]);
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const [yearFilter, setYearFilter] = useState<string[]>([]);
  const [obligationFilter, setObligationFilter] = useState<boolean[]>([]);
  const [measureFilter, setMeasureFilter] = useState<string[]>([]);
  const [operationFilter, setOperationFilter] = useState<string[]>([]);
  const [notificationFilter, setNotificationFilter] = useState<
    NotificationType[]
  >([NotificationType.Email]);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  function toggleFilterModal() {
    setShowFilterModal(!showFilterModal);
  }

  const toggleUsersModal = () => {
    setSearchTerm(null);
    setShowUsersModal(!showUsersModal);
    setCurrentPage(1);
  };


  // Get uploaded files
  function onFileUpload(file: File | null) {
      setUploadedFile(file);
  }


  function handleClearFile() {
      setUploadedFile(null);
  }
  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!notificationFilter || !notificationFilter.length) {
      return;
    }

    const uids = userFilter.includes("allUsers") ? null : userFilter;

    dispatch(
      sendEmailAsync({
        emailRequest: {
          id: email?.notificationFormView.id,
          body: getValues().body,
          isHtml: false,
          isEmailOnly: false,
          subject: getValues().subject,
          toUids: uids,
          userRoleFilter: [roleFilter],
          preferredChannels: notificationFilter,
        },
        attachmentRequest: {
          id: 0,
          attachment: uploadedFile,
        },
      })
    );

    setUserFilter([]);
    setUploadedFile(null);
    reset();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (
        currentPage: number,
        size: number,
        searchTerm: string | null,
        roles: number,
        counties: number[],
        cities: number[],
        measures: string[],
        operations: string[],
        entryYear: string[],
        isDone: boolean[]
      ) =>
        dispatch(
          getAccountList({
            pageNumber: currentPage,
            pageSize: size,
            search: searchTerm,
            userRoleFilter: [roles],
            countyFilter: counties,
            citiesMunicipalityFilter: cities,
            measureFilter: measures,
            operationFilter: operations,
            entryYearFilter: entryYear,
            isDoneFilter: isDone,
            statusFilter: -1,
          })
        ),
      INPUT_DEBOUNCE_TIME
    ),
    []
  );

  useEffect(() => {
    const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
    dispatch(getAccountListRequest());
    debouncedSearch(
      currentPage,
      size,
      searchTerm,
      roleFilter,
      countyFilter,
      cityFilter,
      measureFilter,
      operationFilter,
      yearFilter,
      obligationFilter
    );

    dispatch(getMeasures());
    dispatch(getOperationTypes());
  }, [
    countyFilter,
    currentPage,
    debouncedSearch,
    dispatch,
    pageSize?.value,
    roleFilter,
    searchTerm,
    cityFilter,
    measureFilter,
    operationFilter,
    yearFilter,
    obligationFilter,
  ]);
  return (
    <>
      <section className="user-administration">
        <h1 className="user-administration__heading mb-28">
          Komunikacija s korisnicima
        </h1>

        <CommunicationFilters
          countyFilter={countyFilter}
          setCountyFilter={setCountyFilter}
          cityFilter={cityFilter}
          setCityFilter={setCityFilter}
          roleFilter={roleFilter}
          setRoleFilter={setRoleFilter}
          toggleModal={toggleFilterModal}
          setCurrentPage={setCurrentPage}
        />

        <div className="f mb-44">
          <div className="mr-16">
            <Button
              size="small"
              type="button"
              variant="primary-ghost"
              isDisabled={false}
              onClick={toggleUsersModal}
            >
              Lista primatelja
            </Button>
          </div>

          <p className="communication__filter--text">
            Standardno su odabrani svi korisnici sa liste. Ukoliko želite
            poslati poruku samo određenom skupu korisnika potrebno je unutar
            liste to definirati.
          </p>
        </div>

        <form onSubmit={onSubmit}>
          <div className="input-subject mb-20">
            <label className="label label--alt" htmlFor="subject">
              Naslov poruke
            </label>
            <Controller
              name="subject"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="mt-4"
                  errorMessage=""
                  id="subject"
                  isValid={!errors.subject}
                  size="large"
                  type="text"
                  placeholder="Text naslova"
                  name="subject"
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="communication__message--grid">
            <div>
              <label className="label label--alt" htmlFor="body">
                Poruka
              </label>
              <textarea
                id="body"
                className={`form-input form-input--large mt-4 ${
                  errors.body && "form-input--error"
                }`}
                rows={10}
                placeholder="Text poruke"
                {...register("body", { required: true })}
              />
            </div>
            <NotificationSelect
              title="Način slanja"
              notificationFilter={notificationFilter}
              setNotificationFilter={setNotificationFilter}
            />
          </div>

                  <FileUpload
                      onUpload={onFileUpload}
                      acceptedFiles=".doc, .docx, .xls, .xlsx, .pdf"
                      isActive={uploadedFile ? true : false}
                      onClear={handleClearFile}
                      maxFileSize={20}
                    />
          {isLoadingEmail || isLoadingNotification ? (
            <Loading className="loading-container--sml" />
          ) : (
            <div className="f">
              <Button
                size="small"
                type="submit"
                variant="primary"
                isDisabled={
                  !isValid ||
                  isLoadingEmail ||
                  isLoadingNotification ||
                  !notificationFilter ||
                  !notificationFilter.length
                }
              >
                Pošaljite poruku
              </Button>
              <button
                type="reset"
                className="btn btn--ghost ml-20"
                disabled={isLoadingEmail || isLoadingNotification}
                onClick={() => reset()}
              >
                Odustanite
              </button>
            </div>
          )}
        </form>
      </section>

      <CommunicationAdditionalFiltersModal
        isActive={showFilterModal}
        toggleModal={toggleFilterModal}
        yearFilter={yearFilter}
        setYearFilter={setYearFilter}
        obligationFilter={obligationFilter}
        setObligationFilter={setObligationFilter}
        measureFilter={measureFilter}
        setMeasureFilter={setMeasureFilter}
        operationFilter={operationFilter}
        setOperationFilter={setOperationFilter}
      />
      <CommunicationUsersModal
        isActive={showUsersModal}
        toggleModal={toggleUsersModal}
        data={data}
        isLoading={isFetchingAccountList}
        count={userAccountCount}
        setUserFilter={setUserFilter}
        userFilter={userFilter}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        DefaultPageSize={DefaultPageSize}
      />
    </>
  );
};
