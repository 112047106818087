import { FormGroup, InputLabel } from 'modules/common';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EducationFormInfo } from '../models';
import { UserTypes, emailRegex, phoneRegex } from 'modules/common';
import { sessionCache } from 'modules/auth';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const isFarmer = (userType: number) => UserTypes.Farmer === userType;

interface Props {
  onChange: (data: EducationFormInfo) => void;
  data: EducationFormInfo;
  formValid: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export const EducationCarrierForm: React.FC<Props> = ({
  onChange,
  data,
  formValid,
  isLoading,
}) => {
  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<EducationFormInfo>({
    mode: 'onChange',
    defaultValues: {
      ...data,
    },
  });

  const user = sessionCache().getSession();
  const { profile } = useSelector((state: RootState) => state.profile);
  const userType = user?.userType.value ?? profile?.userType?.value;

  function handleChange() {
    const data = getValues();
    onChange(data);
  }

  useEffect(() => {
    formValid(isValid);
  }, [isValid, formValid]);

  return (
    <form className="educations_modal-form" onChange={handleChange}>
      {!isFarmer(userType ?? UserTypes.Farmer) ? (
        <FormGroup>
          <InputLabel
            labelFor="mibpg"
            labelText="MIBPG"
            isValid={!errors.mibpg}
          />
          <input
            value={data.mibpg}
            className={`form-input form-input--large ${
              errors.mibpg && 'form-input--error'
            }`}
            {...register('mibpg', {
              required: true,
              pattern: /^[0-9]*$/,
            })}
          />
        </FormGroup>
      ) : null}
      <FormGroup>
        <InputLabel
          labelFor="firstName"
          labelText="Ime"
          isValid={!errors.firstName}
        />
        <input
          value={data.firstName}
          className={`form-input form-input--large ${
            errors.firstName && 'form-input--error'
          }
          ${
            isFarmer(userType ?? UserTypes.Farmer) &&
            'educations__modal__disabled-input'
          }
          `}
          {...register('firstName', { required: true })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel
          labelFor="lastName"
          labelText="Prezime"
          isValid={!errors.lastName}
        />
        <input
          value={data.lastName}
          className={`form-input form-input--large ${
            errors.lastName && 'form-input--error'
          }
          ${
            isFarmer(userType ?? UserTypes.Farmer) &&
            'educations__modal__disabled-input'
          }
          `}
          {...register('lastName', { required: true })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel labelFor="oib" labelText="OIB" isValid={!errors.oib} />
        <input
          value={data.oib}
          className={`form-input form-input--large 
          ${errors.oib && 'form-input--error'}
          ${
            isFarmer(userType ?? UserTypes.Farmer) &&
            'educations__modal__disabled-input'
          }
          `}
          {...register('oib', {
            required: false,
            pattern: /^\d{11}$/,
          })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel
          labelFor="email"
          labelText="E-mail adresa"
          isValid={!errors.email}
        />
        <input
          value={data.email || ''}
          className={`form-input form-input--large ${
            errors.email && 'form-input--error'
          }`}
          {...register('email', {
            pattern: emailRegex,
          })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel
          labelFor="mobileNumber"
          labelText="Broj telefona"
          isValid={!errors.mobileNumber}
        />
        <input
          value={data.mobileNumber || ''}
          className={`form-input form-input--large ${
            errors.mobileNumber && 'form-input--error'
          }`}
          {...register('mobileNumber', {
            pattern: phoneRegex,
          })}
        />
      </FormGroup>
    </form>
  );
};
