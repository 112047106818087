import { ChartOptions } from "chart.js";

export const subscriptionChartOptions: ChartOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  elements: {
    arc: {
      borderWidth: 0,
      backgroundColor: [
        "#EA776B",
        "#F6BC76",
        "#29D0DB",
        "#6458EF",
        "#E263E4",
        "#CDE72A",
      ],
    },
  },
  responsive: false,
  cutoutPercentage: 75,
};

export const regisrationChartOptions: ChartOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  elements: {
    arc: {
      borderWidth: 0,
      backgroundColor: ["#EA776B", "#6458EF", "#CDE72A"],
    },
  },
  responsive: false,
  cutoutPercentage: 75,
};
