import { createService } from "services/serviceFactory";
import { headers } from "../headers";
import { UserActivityRequest } from "./request";

const loggerService = createService("logger");

async function fetchUserActivity(
  data: UserActivityRequest,
  accessToken: string
) {
  return loggerService.post("/logger/v1/Logger/GetUserActivityLogList", data, {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export { fetchUserActivity };
