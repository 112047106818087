import { activateUserAccount } from "services";
import { AppThunk } from "store";

import {
  activateAccountError,
  activateAccountRequest,
  activateAccountSuccess,
} from "./reducer";

export const activateAccount = (code: string): AppThunk => async (dispatch) => {
  try {
    dispatch(activateAccountRequest());

    const response = await activateUserAccount(code);
    const [first] = response.data.statuses;
    dispatch(activateAccountSuccess({ successMessage: first.message }));
  } catch (error: any) {
    const [first] = error.response.data.statuses;
    dispatch(activateAccountError({ errorMessage: first.message }));
  }
};
