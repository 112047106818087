import React from "react";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";

interface Props {
    items: {
        label: string;
        link: string;
        fullScreen?: boolean;
    }[];
    setLink: React.Dispatch<React.SetStateAction<string>>;
    activeFullScreen: boolean;
}

export const PanelMenu: React.FC<Props> = ({ items, setLink, activeFullScreen }) => (
    <div className={`panel-menu-container ${activeFullScreen ? "activate-fullscreen" : ""}`}>
        {items?.map((item, index) => (
            <span key={index} className="panel-menu-item" onClick={() => setLink(item.link)}>
                {item.label}
                <div className={item.fullScreen ? "circle" : ""}>
                    <ChevronRightIcon />
                </div>
            </span>
        ))}
    </div>
);
