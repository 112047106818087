import { Button, Checkbox } from "modules/common";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EducationItem, EducationReminder } from "services";
import { RootState } from "store";
import { reminders } from "../constants";
import { useReminderHandler } from "../hooks";

interface Props {
  isReminderOpen: boolean;
  pickedEducation?: EducationItem;
  mappedEducation: EducationItem;
  handleAddReminder: (reminder: EducationReminder) => void;
}

export const EducationReminders: React.FC<Props> = ({
  isReminderOpen,
  pickedEducation,
  mappedEducation,
  handleAddReminder,
}) => {
  const { pickedReminders } = useReminderHandler(pickedEducation);
  const remindersFromPickedEducation = pickedReminders.find(
    (reminder) => reminder.education.uid === pickedEducation?.uid
  );

  const [checkedReminders, setCheckedReminders] = useState<number[]>([]);
  const { profile } = useSelector((state: RootState) => state.profile);

  function handleButtonClick() {
    if (!pickedEducation || !profile) return;
    if (pickedEducation.id !== mappedEducation.id) return;

    handleAddReminder({
      education: pickedEducation,
      accountUid: profile.uid,
      remindInDays: checkedReminders,
    });
  }

  useEffect(() => {
    if (!remindersFromPickedEducation) {
      setCheckedReminders([]);
      return;
    }

    setCheckedReminders(remindersFromPickedEducation?.remindInDays);
  }, [pickedEducation]);

  return (
    <div
      className={`education__list__reminder-container education__list__reminder-container--${
        isReminderOpen && pickedEducation?.id === mappedEducation.id
          ? "show"
          : "hide"
      }`}
    >
      {reminders.map((reminder) => (
        <div key={reminder.id}>
          <Checkbox
            type="checkbox"
            htmlFor={`education-${mappedEducation.id}-${reminder.id}-reminder`}
            value={reminder.value.toString()}
            checked={checkedReminders.includes(reminder.value)}
            onChange={() => {
              setCheckedReminders((prev) => {
                return prev.find((r) => r === reminder.value)
                  ? prev.filter((r) => r !== reminder.value)
                  : [...prev, reminder.value];
              });
            }}
            size="small"
            text={<div className="filter__item__text">{reminder.label}</div>}
          />
        </div>
      ))}
      <Button
        size="xsmall"
        variant="primary-ghost"
        className="btn--nowrap mx--auto"
        isDisabled={false}
        onClick={handleButtonClick}
      >
        Potvrdi
      </Button>
    </div>
  );
};
