import { createService } from "services/serviceFactory";
import { SignInRequest, SignUpRequest } from "./request";
import { SignInResponse, TokenRenewResponse } from "./response";

const authService = createService("auth");

async function signUp(data: SignUpRequest) {
  return authService.post("/auth/v1/Auth/Register", data);
}

async function signIn(data: SignInRequest) {
  return authService.post<SignInResponse>("/auth/v1/Auth/Login", data);
}

async function renewToken(refreshToken: string) {
  return authService.post<TokenRenewResponse>("/auth/v1/Auth/RefreshTokens", {
    refreshToken,
  });
}

async function sendForgotPasswordEmail(email: string) {
  return authService.post("/auth/v1/Auth/SendForgotPasswordEmail", { email });
}

async function activateUserAccount(code: string) {
  return authService.post("/auth/v1/Auth/ActivateUserAccount", { code });
}

async function verifyResetPasswordCode(code: string) {
  return authService.post("/auth/v1/Auth/VerifyResetPasswordCode", { code });
}

async function resetPassword(code: string, password: string) {
  return authService.post("/auth/v1/Auth/ResetPassword", { code, password });
}

export {
  signIn,
  signUp,
  renewToken,
  sendForgotPasswordEmail,
  activateUserAccount,
  verifyResetPasswordCode,
  resetPassword,
};
