import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { chartOptions } from "./chartOptions";

interface Props {
  data: number[];
}
export const HorizontalBarChart: React.FC<Props> = ({ data }) => {
  return (
    <HorizontalBar
      data={{
        labels: ["web", "mobile"],
        datasets: [
          {
            data: data,
            barThickness: 12,
          },
        ],
      }}
      options={chartOptions}
      width={250}
      height={40}
    />
  );
};
