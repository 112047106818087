import {
  Button,
  Checkbox,
  Input,
  Loading,
  Modal,
  ModalFooter,
  ModalHeader,
  NoResults,
  OptionType,
  Pagination,
} from "modules/common";
import { MappedUserAccounts } from "modules/dashboard";
import React from "react";
import { xor } from "lodash";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ValueType } from "react-select";

const PAGE_NEIGHBOURS = 1;

interface Props {
  isActive: boolean;
  toggleModal: () => void;
  data: MappedUserAccounts | null;
  isLoading: boolean;
  count: number;
  userFilter: string[];
  setUserFilter: React.Dispatch<React.SetStateAction<string[]>>;
  searchTerm: string | null;
  setSearchTerm: React.Dispatch<React.SetStateAction<string | null>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: OptionType | null;
  setPageSize: React.Dispatch<React.SetStateAction<OptionType | null>>;
  DefaultPageSize: OptionType;
}

export const CommunicationUsersModal: React.FC<Props> = ({
  isActive,
  toggleModal,
  data,
  isLoading,
  count,
  userFilter,
  setUserFilter,
  searchTerm,
  setSearchTerm,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  DefaultPageSize,
}) => {
  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchTerm(value);
    setCurrentPage(1);
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "allUsers") {
      const allUsers = data?.map((i) => i.uid);

		if (event.target.checked && allUsers) {
			setUserFilter(filter => ([...new Set([...filter, ...allUsers])]));
			return;
      	} else {
			setUserFilter([]);
			return;
		}
    }
    const selectedUser = data?.find((i) => i.uid === event.target.value);

    if (!selectedUser) {
      return;
    }

    // Create array of unique values
    const newArray = xor(userFilter, [selectedUser.uid]);
    setUserFilter(newArray);
  }

  const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
    setPageSize(value);
    setCurrentPage(1);
  };
  const handlePageClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setCurrentPage(Number(parseInt(event.currentTarget.id)));
  };

  const handleNexPageClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPageClick = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <Modal
      isActive={isActive}
      onClose={toggleModal}
      modalHeader={
        <ModalHeader
          onClose={toggleModal}
          title="Lista primatelja"
          isLoading={false}
        />
      }
      modalFooter={
        <ModalFooter>
          <Button
            type="button"
            size="small"
            variant="primary"
            isDisabled={false}
            onClick={toggleModal}
          >
            <span>Odaberi primatelje</span>
          </Button>
        </ModalFooter>
      }
    >
      <div style={{ marginLeft: 40, marginTop: 20 }}>
        <div style={{ marginRight: 40 }}>
          <Input
            name="naziv"
            id="naziv"
            type="text"
            size="small"
            placeholder="Naziv"
            isValid={true}
            onChange={handleSearchChange}
            errorMessage=""
            lefIcon={<SearchIcon className="search-icon" />}
          />
          {searchTerm && searchTerm.length ? null : (
            <div className="f f-justify-between f-align-items-center mt-28">
              <div>
                <Checkbox
                  type="checkbox"
                  value="allUsers"
                  checked={Boolean(data?.every((item) => userFilter.includes(item.uid)))}
                  onChange={onChange}
                  size="small"
                  htmlFor="allUsers"
                  text={
                    <div className="filter__item__text">
                      Odaberi sve korisnike
                    </div>
                  }
                />
              </div>
              <p className="educations__item-subheading educations__item-subheading--third">
                Odabrano {userFilter.length}/{count}
              </p>
            </div>
          )}
        </div>

        <div style={{ maxHeight: 700, overflowY: "scroll", paddingBottom: 32 }}>
          {isLoading ? (
            <Loading />
          ) : data && data.length ? (
            <>
              {data.map((value, index) => {
                const fullName =
                  (value.firstName || value.lastName) &&
                  `${value.firstName ? value.firstName : ""} ${
                    value.lastName ? value.lastName : ""
                  }`;

                const address =
                  (value.city || value.email) &&
                  `${value.city ? value.city : ""} (${
                    value.email ? value.email : ""
                  })`;

                return (
                  <div key={index}>
                    <div className="separator" />
                    <Checkbox
                      type="checkbox"
                      value={value.uid}
                      checked={Boolean(userFilter.includes(value.uid))}
                      onChange={onChange}
                      size="small"
                      htmlFor={value.uid}
                      text={
                        <div className="filter__item__text">
                          {value.userType} {fullName && `- ${fullName}`}{" "}
                          {address && `- ${address}`}
                        </div>
                      }
                    />
                  </div>
                );
              })}
              <Pagination
                className="mt-24 mr-40"
                currentPage={currentPage}
                numberOfPages={Math.ceil(
                  count / parseInt(pageSize?.value ?? DefaultPageSize.value)
                )}
                pageNeighbours={PAGE_NEIGHBOURS}
                pageSize={pageSize ?? DefaultPageSize}
                onPageSizeChange={handlePageSizeChange}
                handlepageClick={handlePageClick}
                next={handleNexPageClick}
                prev={handlePrevPageClick}
              />
            </>
          ) : (
            <NoResults className="mt-24" />
          )}
        </div>
      </div>
    </Modal>
  );
};
