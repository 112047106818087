import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import { Input } from "..";
import { Navbar } from "./Navbar";
import format from "date-fns/format";
import "../../../../css/datePicker.component.css";
import { DayModifiers } from "react-day-picker";

interface Props {
  onClick: (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedDay: Date | null;
}
const DateInput = React.forwardRef((props: any, ref) => {
  return (
    <Input
      {...props}
      name="DatePickerInput"
      id="DatePickerInput"
      className="day-picker-input"
      type="text"
      size="small"
      isValid={true}
      errorMessage=""
      lefIcon={<CalendarIcon />}
      rightIcon={<ChevronIcon />}
    />
  );
});

const WEEKDAYS_SHORT = ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"];
export const WEEKDAYS_LONG = [
  "Nedjelja",
  "Ponedjeljak",
  "Utorak",
  "Srijeda",
  "Četvrtak",
  "Petak",
  "Subota",
];

export const DatePicker: React.FC<Props> = ({ onClick, selectedDay }) => {
  return (
    <>
      <DayPickerInput
        component={DateInput}
        placeholder="Odaberite datum"
        inputProps={{ name: "DatePickerInput" }}
        formatDate={(date) => {
          return format(date, "dd.MM.yyyy.");
        }}
        format="dd.MM.yyyy."
        dayPickerProps={{
          navbarElement: <Navbar />,
          locale: "hr",
          weekdaysShort: WEEKDAYS_SHORT,
          onDayClick: onClick,
          selectedDays: selectedDay || undefined,
        }}
      />
    </>
  );
};
