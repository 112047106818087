import { RadioInput, userRoles } from "modules/common";
import React from "react";

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    roleType: number;
}
export const RolesList: React.FC<Props> = ({ roleType, onChange }) => {
    // TODO: change this to use newRoles
    const role = Object.values(userRoles).find(role => role.value === roleType);

    return (
        <div>
            <RadioInput
                value={role?.value.toString() ?? ""}
                checked={true}
                onChange={onChange}
                size="small"
                htmlFor={role?.label ?? "" + 1}
                text={<div className="filter__item__text">{role?.label}</div>}
                isDisabled={true}
            />
        </div>
    );
};
