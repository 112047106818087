import React from "react";
import { ReactComponent as SortIcon } from "assets/icons/sortIcon.svg";
import { HeaderGroup } from "react-table";

interface Props {
  headerGroups: HeaderGroup<object>[];
}
export const TableHeader: React.FC<Props> = ({ headerGroups }) => {
  return (
    <thead className="table__head">
      {headerGroups.map((headerGroup) => (
        <tr className="table__row" {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column: any) => (
            <th
              className={`table__header ${column.className ?? ""}`}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              {column.render("Header")}
              <span className="table__header__icons">
                <SortIcon />
              </span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
