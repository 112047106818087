import React from "react";
import ReactTooltip from "react-tooltip";

interface Props {
  tooltipText: string;
  tooltipPosition?: "top" | "right" | "bottom" | "left";
  icon: React.ReactNode;
  id: string;
}
export const Tooltip: React.FC<Props> = ({
  tooltipPosition,
  tooltipText,
  icon,
  id,
}) => {
  return (
    <>
      <p id={id} className="icon-wrapper" data-tip={tooltipText}>
        {icon}
      </p>
      <ReactTooltip
        className="tooltip"
        place={tooltipPosition}
        effect="solid"
        arrowColor="#ffffff"
      />
    </>
  );
};
