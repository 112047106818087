import React from "react";

export const userActivityColumns = [
  {
    Header: "PODACI O KORISNIKU",
    accessor: (data: any) => (
      <div className="user-info-container">
        <span className="user-info">
          {data.firstName} {data.lastName}
        </span>
        <span className="user-id">{data.userUid}</span>
      </div>
    ),
    className: "user-info-header",
  },

  {
    Header: "APLIKACIJA",
    accessor: "application",
    className: "application-header",
  },
  {
    Header: "ULOGA",
    accessor: "userRole",
    className: "role-header",
  },
  {
    Header: "AKCIJA",
    accessor: (data: any) => (
      <span className="user-action">{data.userAction}</span>
    ),
  },
];
