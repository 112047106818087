import React from "react";

interface Props {
  className?: string;
}
export const NoResults: React.FC<Props> = ({ className }) => {
  return (
    <div className={`results-container ${className ?? ""}`}>
      Rezultati pretrage nisu dostupni ili ne postoje. Molimo pokušajte ponovno.
    </div>
  );
};
