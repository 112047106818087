import { fetchUserActivity, UserActivityRequest } from "services";
import { mapUserActivites } from "modules/dashboard";
import { AppThunk } from "store";
import { getUserActivityError, getUserActivitySuccess } from "./reducer";

export const getUserActivityData =
  (data: UserActivityRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      if (state.signIn.user) {
        const { accessToken } = state.signIn.user;
        const response = await fetchUserActivity(data, accessToken);
        dispatch(
          getUserActivitySuccess({
            data: mapUserActivites(response.data.userActivityLogList),
            userActivityLogCount: response.data.userActivityLogCount,
          })
        );
      }
    } catch (error: any) {
      const [first] = error.response.data.statuses;
      dispatch(getUserActivityError({ errorMessage: first.message }));
    }
  };
