export enum UserAccountActiveStatus {
    None = -1,
    Inactive = 0,
    Active = 1,
}

export const userAccountActiveStatus = {
    Inactive: {
        value: UserAccountActiveStatus.Inactive,
        label: "Neaktivan",
    },
    Active: {
        value: UserAccountActiveStatus.Active,
        label: "Aktivan",
    },
};
