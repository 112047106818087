import { Can } from "modules/auth";
import React from "react";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "routing";
import { useSelector } from "react-redux";
import { UserTypes } from "modules/common";
import { RootState } from "store";
import { sessionCache } from "modules/auth";

const isFarmer = (userType: number) => UserTypes.Farmer === userType;

export const EducationTabNavigation: React.FC = () => {
  const user = sessionCache().getSession();
  const { profile } = useSelector((state: RootState) => state.profile);
  const userType = user?.userType.value ?? profile?.userType?.value;

  return (
    <div className="separator">
      <Can I="view" a="EducationModule">
        <NavLink
          activeClassName="educations__filter--active"
          className="educations__filter mr-12"
          to={AppRoutes.Education}
        >
          {isFarmer(userType ?? UserTypes.Farmer)
            ? "Edukacije"
            : "Analiza edukacija"}
        </NavLink>
      </Can>
      <Can I="view" a="PresentationsModule">
        <NavLink
          activeClassName="educations__filter--active"
          className="educations__filter"
          to={AppRoutes.Presentations}
        >
          Prezentacije
        </NavLink>
      </Can>
    </div>
  );
};
