import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MappedUserAccount } from "modules/dashboard";

interface State {
  isFetchingUserData: boolean;
  fetchError: boolean;
  fetchSuccess: boolean;
  errorMessage: string;
  userData: MappedUserAccount | null;
}

interface GetUserAccountSuccess {
  user: MappedUserAccount;
}
interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  isFetchingUserData: false,
  fetchError: false,
  fetchSuccess: false,
  errorMessage: "",
  userData: null,
};

const userAccountSlice = createSlice({
  name: "UserAccount",
  initialState: initialState,
  reducers: {
    getUserAccountRequest: (state) => {
      state.isFetchingUserData = true;
      state.userData = null;
    },
    getUserAccountSuccess: (
      state,
      action: PayloadAction<GetUserAccountSuccess>
    ) => {
      state.isFetchingUserData = false;
      state.fetchSuccess = true;
      state.userData = action.payload.user;
    },
    getUserAccountError: (state, action: PayloadAction<RequestError>) => {
      state.isFetchingUserData = false;
      state.fetchError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = userAccountSlice;

export const {
  getUserAccountError,
  getUserAccountRequest,
  getUserAccountSuccess,
} = actions;

export const userAccountReducer = userAccountSlice.reducer;
