import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MappedUserAccounts } from "modules/dashboard";

interface State {
  isFetchingAccountList: boolean;
  fetchError: boolean;
  fetchSuccess: boolean;
  errorMessage: string;
  data: MappedUserAccounts | null;
  userAccountCount: number;
}

interface GetUserAccountsSuccess {
  userAccounts: MappedUserAccounts;
  userAccountCount: number;
}

interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  isFetchingAccountList: false,
  fetchError: false,
  fetchSuccess: false,
  errorMessage: "",
  data: null,
  userAccountCount: 0,
};

const accountListSlice = createSlice({
  name: "AccountList",
  initialState: initialState,
  reducers: {
    getAccountListRequest: (state) => {
      state.isFetchingAccountList = true;
    },
    getAccountListSuccess: (
      state,
      action: PayloadAction<GetUserAccountsSuccess>
    ) => {
      state.isFetchingAccountList = false;
      state.fetchSuccess = true;
      state.data = action.payload.userAccounts;
      state.userAccountCount = action.payload.userAccountCount;
    },
    getAccountListError: (state, action: PayloadAction<RequestError>) => {
      state.isFetchingAccountList = false;
      state.fetchError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = accountListSlice;

export const {
  getAccountListRequest,
  getAccountListSuccess,
  getAccountListError,
} = actions;

export const accountListReducer = accountListSlice.reducer;
