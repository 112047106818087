import { InputLabel, RadioInput } from 'modules/common';
import { FileUpload } from 'modules/dashboard';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PresentationFormState } from '.';
import { FileType } from '..';
import { getOperationTypes } from 'modules/education';

interface Props {
  onChange: (data: unknown) => void;
  data: PresentationFormState | undefined;
  isEditPresentation: boolean;
}

export const PresentationFormFirst: React.FC<Props> = ({
  onChange,
  data,
  isEditPresentation,
}) => {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(data?.type);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSelectedType(value);
    onChange({ ...data, type: value });
  };

  // Get uploaded files
  function onFileUpload(file: File | null) {
    onChange({ ...data, file });
  }

  function handleClearFile() {
    onFileUpload(null);
    }


  useEffect(() => {
    dispatch(getOperationTypes());
  }, []);

  useEffect(() => {
    if (!data) {
      setSelectedType(undefined);
      return;
    }
    setSelectedType(data.type);
  }, [data?.type]);

  return (
    <>
      <InputLabel isValid labelFor="Type" labelText="Vrsta" />
      {FileType.map((value, index) => {
        return (
          <div key={index} className="mb-16">
            <RadioInput
              htmlFor={`${value.type}${index}`}
              onChange={handleRadioChange}
              size="base"
              checked={data?.type === value.type}
              value={value.type}
              text={value.type}
            />
          </div>
        );
      })}
      <div className="separator" />
      <FileUpload
        onUpload={onFileUpload}
        acceptedFiles={
          FileType.find((filetype) => filetype.type === selectedType)
            ?.formats || ''
        }
        isLoading={isEditPresentation ? Boolean(data) : false}
        subtitle={data?.file?.name}
        isActive={data?.file ? true : false}
        onClear={handleClearFile}
        isEditPresentation={isEditPresentation}
		isDisabled={!data?.type}
      />
    </>
  );
};