import React from "react";
import { Row, TableBodyPropGetter, TableBodyProps } from "react-table";

interface Props {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object>
  ) => TableBodyProps;
  rows: Row<object>[];
  prepareRow: (row: Row<object>) => void;
}
export const TableBody: React.FC<Props> = ({
  getTableBodyProps,
  rows,
  prepareRow,
}) => {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <tr className="table__row" {...row.getRowProps()}>
            {row.cells.map((cell: any) => {
              const columnClass = cell.column.className ?? "";
              return (
                <td
                  className={`administration-table__data ${columnClass}`}
                  {...cell.getCellProps()}
                >
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
