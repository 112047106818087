enum ActionType {
  ChangeUserRole = 0x001,
  SendMessage = 0x002,
  ChangeInfoType = 0x004,
  ChangeNotificationType = 0x008,
  AddStation = 0x010,
  ChangeStation = 0x020,
  AddBoundryValues = 0x040,
  ChangeBoundryValues = 0x080,
  AddCulture = 0x100,
  DeleteCulture = 0x200,
  UploadDocument = 0x400,
  DeactivateDocument = 0x800,
}

export const actionTypes: Record<string, { value: number; label: string }> = {
  ChangeUserRole: { value: ActionType.ChangeUserRole, label: "Promjena uloge" },
  SendMessage: { value: ActionType.SendMessage, label: "Slanje poruke" },
  ChangeInfoType: {
    value: ActionType.ChangeInfoType,
    label: "Promjena predbilježbe",
  },
  ChangeNotificationType: {
    value: ActionType.ChangeNotificationType,
    label: "Promjena načina obavještavanja",
  },
  AddStation: { value: ActionType.AddStation, label: "Unos stanice" },
  ChangeStation: { value: 20, label: "Promjena stanice" },
  ChangeBoundryValues: {
    value: ActionType.ChangeBoundryValues,
    label: "Promjena graničnih vrijednosti",
  },
  AddCulture: { value: ActionType.AddCulture, label: "Unos kulture" },
  UploadDocument: {
    value: ActionType.UploadDocument,
    label: "Učitan dokument",
  },
  DeactivateDocument: {
    value: ActionType.DeactivateDocument,
    label: "Deaktiviran dokument",
  },
};
