import { xor } from "lodash";
import {
  Checkbox,
  Filter,
  FilterGroup,
  Loading,
  RoleFilterSingle,
  useOutsideClick,
} from "modules/common";
import {
  getCityMunicipalitiesByCountyIds,
  getCounties,
} from "modules/dashboard";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

interface Props {
  roleFilter: number;
  setRoleFilter: (value: React.SetStateAction<number>) => void;
  countyFilter: number[];
  setCountyFilter: React.Dispatch<React.SetStateAction<number[]>>;
  cityFilter: number[];
  setCityFilter: React.Dispatch<React.SetStateAction<number[]>>;
  toggleModal: () => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const CommunicationFilters: React.FC<Props> = ({
  roleFilter,
  setRoleFilter,
  countyFilter,
  setCountyFilter,
  cityFilter,
  setCityFilter,
  toggleModal,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();
  const countyFilterRef = useRef<HTMLDivElement>(null);
  const cityFilterRef = useRef<HTMLDivElement>(null);
  const roleFilterRef = useRef<HTMLDivElement>(null);

  const {
    counties: ReducerCounties,
    cityMunicipalities: ReducerCities,
    isLoading,
  } = useSelector((state: RootState) => state.address);

  const [showRoleFilter, setShowRoleFilter] = useState(false);
  const [showCountyFilter, setShowCountyFilter] = useState(false);
  const [showCityFilter, setShowCityFilter] = useState(false);

  const onRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setRoleFilter(parseInt(value));
    setCurrentPage(1);
  };

  function onCountyChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedCounty = ReducerCounties?.find(
      (i) => i.valueName === event.target.value
    );

    if (!selectedCounty || !selectedCounty.id) {
      return;
    }

    // Create array of unique values
    const newArray = xor(countyFilter, [selectedCounty.id]);
    setCurrentPage(1);
    setCountyFilter(newArray);
    setCityFilter([]);
  }

  /** Create one function for react-select onChange */
  function onCityChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedCity = ReducerCities.find(
      (i) => i.name === event.target.value
    );

    if (!selectedCity || !countyFilter.length) {
      return;
    }

    // Create array of unique values
    const newArray = xor(cityFilter, [selectedCity.id]);
    setCurrentPage(1);
    setCityFilter(newArray);
  }

  const handleRoleFilterOutsideClick = () => {
    setShowRoleFilter(false);
  };

  const handleCountyFilterOutsideClick = () => {
    setShowCountyFilter(false);
  };

  const handleCityFilterOutsideClick = () => {
    setShowCityFilter(false);
  };

  useOutsideClick(roleFilterRef, handleRoleFilterOutsideClick);
  useOutsideClick(countyFilterRef, handleCountyFilterOutsideClick);
  useOutsideClick(cityFilterRef, handleCityFilterOutsideClick);

  const handleRoleFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setRoleFilter(0);
  };

  const handleCountyFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setCountyFilter([]);
  };

  const handleCityFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setCityFilter([]);
  };

  const handleRoleFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowRoleFilter(!showRoleFilter);
  };

  const handleCountyFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowCountyFilter(!showCountyFilter);
    dispatch(getCounties());
  };

  function handleCityFilterClick() {
    setShowCityFilter(!showCityFilter);

    if (!countyFilter.length) {
      return;
    }

    dispatch(getCityMunicipalitiesByCountyIds(countyFilter));
  }

  return (
    <FilterGroup className="mb-44" text="Filteri">
      <div className="mr-4 " ref={roleFilterRef}>
        <RoleFilterSingle
          title="Kategorija primatelja"
          onChange={onRoleChange}
          onClear={handleRoleFilterClear}
          onClick={handleRoleFilterClick}
          value={roleFilter}
          isActive={Boolean(roleFilter)}
          showDropdown={showRoleFilter}
        />
      </div>
      <div className="mr-4" ref={countyFilterRef}>
        <Filter
          isActive={Boolean(countyFilter.length)}
          showDropdown={showCountyFilter}
          onClear={handleCountyFilterClear}
          onClick={handleCountyFilterClick}
          text="Županija"
        >
          {isLoading ? (
            <Loading />
          ) : (
            ReducerCounties.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value.valueName}
                  checked={Boolean(countyFilter.includes(value.id!))}
                  onChange={onCountyChange}
                  size="small"
                  htmlFor={value.valueName}
                  text={<div className="filter__item__text">{value.name}</div>}
                />
              </div>
            ))
          )}
        </Filter>
      </div>

      <div className="mr-4" ref={cityFilterRef}>
        <Filter
          isActive={Boolean(cityFilter.length)}
          showDropdown={showCityFilter}
          onClear={handleCityFilterClear}
          onClick={handleCityFilterClick}
          text="Grad"
        >
          {isLoading ? (
            <Loading />
          ) : ReducerCities.length ? (
            ReducerCities.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value.name}
                  checked={Boolean(cityFilter.includes(value.id))}
                  onChange={onCityChange}
                  size="small"
                  htmlFor={value.name}
                  text={<div className="filter__item__text">{value.name}</div>}
                />
              </div>
            ))
          ) : (
            <p>Odaberite županiju</p>
          )}
        </Filter>
      </div>
      <div className="mr-4">
        <Filter
          isActive={false}
          showDropdown={false}
          onClear={() => console.log("onClear")}
          onClick={toggleModal}
          text="Dodatni filteri"
        />
      </div>
    </FilterGroup>
  );
};
