export function getFormData<T>(data: T) {
    const formData = new FormData();
    for (const k in data) {
        let prop = data[k];
        if (prop === null) continue;
        else if (Array.isArray(prop)) {
            prop.forEach(item => {
                formData.append(k, item);
            });
            continue;
        } else {
            formData.append(k as string, prop as string | Blob);
        }
    }
    return formData;
}
