import React from "react";

interface Props {
  type: "info" | "error" | "success";
  className?: string;
}
export const ValidationSummary: React.FC<Props> = ({
  className,
  children,
  type,
}) => {
  const getType = () => {
    switch (type) {
      case "error":
        return "summary--error";
      case "success":
        return "summary--success";
      case "info":
        return "summary--info";
      default:
        return;
    }
  };

  return (
    <div className={`summary ${getType()} ${className ?? ""}`}>{children}</div>
  );
};
