import React from "react";
import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";

interface Props {
  text: string;
  isActive: boolean;
  showDropdown: boolean;
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
export const Filter: React.FC<Props> = ({
  text,
  children,
  className,
  isActive,
  onClear,
  onClick,
  showDropdown,
}) => {
  return (
    <div className="filter-container">
      <button
        onClick={onClick}
        className={`filter filter--base ${className ?? ""} ${
          isActive ? "filter--active" : ""
        }`}
      >
        <span className="filter__content">
          {text}
          {isActive && <CancelIcon onClick={onClear} className="filter-icon" />}
        </span>
      </button>
      <div
        className={`filter__dropdown ${
          showDropdown ? "filter__dropdown--active" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};
