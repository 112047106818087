import React from "react";
import { ReactComponent as ExclamationCircleIcon } from "assets/icons/exclamation_circle.svg";

interface Props {
  text: string;
}
export const InfoNote: React.FC<Props> = ({ text }) => {
  return (
    <div className="info-note">
      <ExclamationCircleIcon />
      <p className="info-note__text">{text}</p>
    </div>
  );
};

/* E-mail obavještavanje je podešeno pod standardno, ukoliko isključite
sve opcije nećete moći primati pravovremeno obavijesti o novostima i
vašim obavezama. */
