import { Button, Modal, ModalFooter, ModalHeader } from "modules/common";
import React from "react";
import { useDispatch } from "react-redux";
import { deletePresentation } from "..";

interface Props {
  isVisible: boolean;
  toggleModal: () => void;
  id?: number;
}
export const PresentationDeleteModal: React.FC<Props> = ({
  isVisible,
  toggleModal,
  id,
}) => {
  const dispatch = useDispatch();

  function submit() {
    if (!id) {
      return;
    }

    dispatch(deletePresentation(id));
    toggleModal();
  }

  return (
    <Modal
      isActive={isVisible}
      onClose={toggleModal}
      modalHeader={
        <ModalHeader
          onClose={toggleModal}
          title="Ukloni prezentaciju"
          isLoading={false}
        />
      }
      modalFooter={
        <ModalFooter>
          <Button
            type="button"
            size="small"
            variant="primary-ghost"
            isDisabled={false}
            onClick={toggleModal}
          >
            <span>Odustani</span>
          </Button>
          <Button
            type="button"
            size="small"
            variant="warning-ghost"
            isDisabled={false}
            onClick={submit}
          >
            <span>Ukloni</span>
          </Button>
        </ModalFooter>
      }
    >
      <p className="educations_modal-form">
        Želite li ukloniti odabranu prezentaciju?
      </p>
    </Modal>
  );
};
