import React, { useRef, useState } from "react";
import {
    CountyFilter,
    FilterGroup,
    RoleFilter,
    RoleFilterSingle,
    useOutsideClick,
} from "modules/common";
import { StatusFilter } from "modules/common/components/StatusFilter";

interface Props {
    roleFilter: number[];
    countyFilter: number[];
    statusFilter: number;
    setCountyFilter: React.Dispatch<React.SetStateAction<number[]>>;
    setRoleFilter: (value: React.SetStateAction<number[]>) => void;
    setStatusFilter: (value: React.SetStateAction<number>) => void;
}

export const AdministrationFilters: React.FC<Props> = ({
    roleFilter,
    countyFilter,
    statusFilter,
    setCountyFilter,
    setRoleFilter,
    setStatusFilter,
}) => {
    const [showCountyFilter, setShowCountyFilter] = useState(false);
    const [showRoleFilter, setShowRoleFilter] = useState(false);
    const [showStatusFilter, setShowStatusFilter] = useState(false);
    const countyFilterRef = useRef<HTMLDivElement>(null);
    const roleFilterRef = useRef<HTMLDivElement>(null);
    const statusFilterRef = useRef<HTMLDivElement>(null);

    const isRoleFilterActive = roleFilter.length > 0;
    const isCountryFilterActive = countyFilter.length > 0;
    const isStatusFilterActive = statusFilter > -1;

    const handleCountyFilterOutsideClick = () => {
        setShowCountyFilter(false);
    };

    const handleRoleFilterOutsideClick = () => {
        setShowRoleFilter(false);
    };

    const handleStatusFilterOutsideClick = () => {
        setShowStatusFilter(false);
    };

    useOutsideClick(countyFilterRef, handleCountyFilterOutsideClick);
    useOutsideClick(roleFilterRef, handleRoleFilterOutsideClick);
    useOutsideClick(statusFilterRef, handleStatusFilterOutsideClick);

    const handleCountyFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);
        setCountyFilter(prev => {
            if (!prev.includes(valueNumb)) {
                return [...prev, valueNumb];
            } else {
                return [...prev.filter(s => s !== valueNumb)];
            }
        });
    };

    const handleRoleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);
        setRoleFilter(prev => {
            if (!prev.includes(valueNumb)) {
                return [...prev, valueNumb];
            } else {
                return [...prev.filter(s => s !== valueNumb)];
            }
        });
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setStatusFilter(parseInt(value));
    };

    const handleCountyFilterClear = (_event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        setCountyFilter([]);
    };

    const handleRoleFilterClear = (_event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        setRoleFilter([]);
    };

    const handleStatusFilterClear = (_event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        setStatusFilter(-1);
    };

    const handleCountyFilterClick = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowCountyFilter(prev => !prev);
    };

    const handleRoleFilterClick = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowRoleFilter(prev => !prev);
    };

    const handleStatusFilterClick = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowStatusFilter(prev => !prev);
    };

    return (
        <FilterGroup text="Filteri">
            <div className="mr-2" ref={roleFilterRef}>
                <RoleFilter
                    onChange={handleRoleFilterChange}
                    onClear={handleRoleFilterClear}
                    onClick={handleRoleFilterClick}
                    value={roleFilter}
                    isActive={isRoleFilterActive}
                    showDropdown={showRoleFilter}
                />
            </div>
            <div className="mr-2" ref={countyFilterRef}>
                <CountyFilter
                    onChange={handleCountyFilterChange}
                    onClear={handleCountyFilterClear}
                    onClick={handleCountyFilterClick}
                    value={countyFilter}
                    isActive={isCountryFilterActive}
                    showDropdown={showCountyFilter}
                />
            </div>
            <div className="mr-2" ref={statusFilterRef}>
                <StatusFilter
                    onChange={handleStatusFilterChange}
                    onClear={handleStatusFilterClear}
                    onClick={handleStatusFilterClick}
                    value={statusFilter}
                    isActive={isStatusFilterActive}
                    showDropdown={showStatusFilter}
                />
            </div>
        </FilterGroup>
    );
};
