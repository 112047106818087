import { AppRoutes } from 'routing';

export function checkRedirectUrl(pathname?: string) {
  return (
    pathname !== AppRoutes.SignIn &&
    pathname !== AppRoutes.SignUp &&
    pathname !== AppRoutes.ForgotPassword &&
    pathname !== AppRoutes.ChangePassword &&
    pathname !== AppRoutes.ActivateAccount
  );
}
