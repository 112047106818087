import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  forgotPasswordError: boolean;
  errorMessage: string;
  successMessage: string;
  forgotPasswordSuccess: boolean;
  passwordMailSent: boolean;
}

interface ForgotPasswordError {
  errorMessage: string;
}

const initialState: State = {
  isLoading: false,
  forgotPasswordError: false,
  forgotPasswordSuccess: false,
  passwordMailSent: false,
  errorMessage: "",
  successMessage: "",
};
const forgotPasswordSlice = createSlice({
  name: "ForgotPassword",
  initialState: initialState,
  reducers: {
    forgotPasswordRequest: (state) => {
      state.forgotPasswordError = false;
      state.forgotPasswordSuccess = false;
      state.isLoading = true;
    },
    forgotPasswordSuccess: (state) => {
      state.isLoading = false;
      state.forgotPasswordSuccess = true;
      state.passwordMailSent = true;
    },
    forgotPasswordError: (
      state,
      action: PayloadAction<ForgotPasswordError>
    ) => {
      state.isLoading = false;
      state.forgotPasswordError = true;
      state.errorMessage = action.payload.errorMessage;
      state.passwordMailSent = false;
    },
  },
});

const { actions } = forgotPasswordSlice;

export const {
  forgotPasswordError,
  forgotPasswordRequest,
  forgotPasswordSuccess,
} = actions;

export const forgotPasswordReducer = forgotPasswordSlice.reducer;
