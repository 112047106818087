import React from "react";

interface Props {
  labelFor: string;
  labelText: string;
  isValid: boolean;
  rightIcon?: React.ReactNode;
}

export const InputLabel: React.FC<Props> = ({
  labelFor,
  labelText,
  isValid,
  rightIcon,
}) => (
  <div className="label-container">
    <label
      className={`label ${!isValid ? "label--error" : ""}`}
      htmlFor={labelFor}
    >
      {labelText}
    </label>
    {rightIcon ? rightIcon : null}
  </div>
);
