export enum UserTypes {
  Citizen = 1,
  Farmer = 2,
  // Fisherman = 3,
  EmployeeMp = 4,
  EmployeeApprrr = 5,
  EmployeeAdviser = 6,
}

export const userTypes: Record<string, { value: number; label: string }> = {
  Citizen: {
    value: UserTypes.Citizen,
    label: "Građanin",
  },
  Farmer: {
    value: UserTypes.Farmer,
    label: "Poljoprivrednik",
  },
  // Fisherman: {
  //   value: UserTypes.Fisherman,
  //   label: "Ribar",
  // },
  EmployeeMP: {
    value: UserTypes.EmployeeMp,
    label: "Djelatnik MP",
  },
  EmployeeAPPRRR: {
    value: UserTypes.EmployeeApprrr,
    label: "Djelatnik APPRRR",
  },
  EmployeeAdviser: {
    value: UserTypes.EmployeeAdviser,
    label: "Savjetnik",
  },
};
