import {
  EducationListItems,
  EducationReminder,
  EducationReminderFromResponse,
} from "services";

// Here I'm spreading the original array because original array
// can't be modified.
function handleAddEducationsWithReminders(
  newEducationWithReminder: EducationReminder,
  existingEducationsWithReminders?: EducationReminder[]
): EducationListItems {
  if (!existingEducationsWithReminders) {
    return {
      count: 1,
      items: [newEducationWithReminder],
    };
  }

  const isEducationHavingAReminder = existingEducationsWithReminders.find(
    ({ education }) => education.uid === newEducationWithReminder.education.uid
  );

  // If new education isn't in the array. Add it.
  if (!isEducationHavingAReminder) {
    let educationsWithReminders = [...existingEducationsWithReminders];
    educationsWithReminders.push(newEducationWithReminder);
    return {
      count: educationsWithReminders.length,
      items: educationsWithReminders,
    };
  }

  // If new education is in the array, filter it out.
  let updatedEducations = [
    ...existingEducationsWithReminders.filter(
      ({ education }) =>
        education.uid !== newEducationWithReminder.education.uid
    ),
  ];

  // And add the updated education only of any of the reminders are picked.
  Boolean(newEducationWithReminder.remindInDays.length) &&
    updatedEducations.push(newEducationWithReminder);

  return {
    count: updatedEducations.length,
    items: updatedEducations,
  };
}

function handlePagination(
  reminders: EducationReminder[],
  pageSize: number,
  pageNumber: number
) {
  if (reminders.length < pageSize) return reminders;
  const helper = reminders;

  const isEnd = helper.slice(pageSize * (pageNumber - 1)).length < pageSize;

  if (Boolean(isEnd)) {
    return reminders.slice(pageSize * (pageNumber - 1));
  }
  return reminders.slice(pageSize * (pageNumber - 1), pageSize);
}

function handleFormatEducationsWithReminders(
  educationReminderResponse: EducationReminderFromResponse[]
) {
  if (!Boolean(educationReminderResponse.length)) return undefined;

  let educationRemindersHelper: EducationReminder[] = [];

  // Create a group of reminders by their educationUid
  const groupedReminders = educationReminderResponse.reduce((prev, curr) => {
    prev[curr.educationUid] = prev[curr.educationUid] || [];
    prev[curr.educationUid].push(curr);
    return prev;
  }, Object.create(null));

  // Go through every grouped reminder and use every childs first element for important parts.
  for (let i = 0; i < Object.values(groupedReminders).length; i++) {
    // Object.values had to be type casted because Object.values returns unknown.
    const reminders = Object.values(groupedReminders)[
      i
    ] as EducationReminderFromResponse[];
    let remindInDaysHelper: number[] = [];

    reminders.every((reminder) =>
      remindInDaysHelper.push(reminder.remindInDays)
    );

    educationRemindersHelper.push({
      accountUid: reminders[0].accountUid,
      education: {
        ...reminders[0].education,
        id: reminders[0].educationId,
        uid: reminders[0].educationUid,
      },
      remindInDays: remindInDaysHelper,
    });
  }
  return {
    count: educationRemindersHelper.length,
    items: educationRemindersHelper,
  };
}

function handleGetEducationsWithReminders(
  educationReminderResponse: EducationReminderFromResponse[]
): EducationListItems | undefined {
  return handleFormatEducationsWithReminders(educationReminderResponse);
}

function handleGetPaginatedEducationsWithReminders(
  educationReminderResponse: EducationReminderFromResponse[],
  pageSize: number,
  pageNumber: number
): EducationListItems | undefined {
  const allReminders = handleFormatEducationsWithReminders(
    educationReminderResponse
  );
  if (!allReminders) return undefined;

  return {
    count: allReminders.count,
    items: handlePagination(allReminders.items, pageSize, pageNumber),
  };
}

export {
  handleAddEducationsWithReminders,
  handleGetEducationsWithReminders,
  handleGetPaginatedEducationsWithReminders,
};
