import { SkeletonText } from "modules/common";
import React from "react";
import Masonry from "react-masonry-css";

export const SystemAdministrationSkeleton: React.FC = () => (
  <>
    <Masonry
      className="masonry-grid mt-28"
      columnClassName="masonry-grid__column"
    >
      <SkeletonText width="100%" height="213px" />
      <SkeletonText width="100%" height="421px" />
      <SkeletonText width="100%" height="541px" />
      <SkeletonText width="100%" height="333px" />
    </Masonry>
    <SkeletonText width="100%" height="683px" />
  </>
);
