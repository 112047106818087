import { userRoles } from "modules/common";
import React from "react";
import { Filter, RadioInput } from "../";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isActive: boolean;
  showDropdown: boolean;
  value: number;
  title?: string;
}

export const RoleFilterSingle: React.FC<Props> = ({
  onChange,
  onClick,
  onClear,
  isActive,
  showDropdown,
  value,
  title,
}) => {
  return (
    <Filter
      isActive={isActive}
      showDropdown={showDropdown}
      onClear={onClear}
      onClick={onClick}
      text={title || "Uloga"}
    >
      {/*   <Can I="view" a="CitizenFilter">
        </Can> */}
      <div>
        <RadioInput
          value={userRoles.Citizen.value.toString()}
          checked={userRoles.Citizen.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Citizen.label}
          text={
            <div className="filter__item__text">{userRoles.Citizen.label}</div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.Farmer.value.toString()}
          checked={userRoles.Farmer.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Farmer.label}
          text={
            <div className="filter__item__text">{userRoles.Farmer.label}</div>
          }
        />
      </div>
      {/* <div>
        <RadioInput
          value={userRoles.Fisherman.value.toString()}
          checked={userRoles.Fisherman.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Fisherman.label}
          text={
            <div className="filter__item__text">
              {userRoles.Fisherman.label}
            </div>
          }
        />
      </div> */}
      <div>
        <RadioInput
          value={userRoles.EmployeeMp.value.toString()}
          checked={userRoles.EmployeeMp.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.EmployeeMp.label}
          text={
            <div className="filter__item__text">
              {userRoles.EmployeeMp.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.EmployeeApprrr.value.toString()}
          checked={userRoles.EmployeeApprrr.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.EmployeeApprrr.label}
          text={
            <div className="filter__item__text">
              {userRoles.EmployeeApprrr.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.EmployeeAdviser.value.toString()}
          checked={userRoles.EmployeeAdviser.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.EmployeeAdviser.label}
          text={
            <div className="filter__item__text">
              {userRoles.EmployeeAdviser.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.MpAdminFarmer.value.toString()}
          checked={userRoles.MpAdminFarmer.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MpAdminFarmer.label}
          text={
            <div className="filter__item__text">
              {userRoles.MpAdminFarmer.label}
            </div>
          }
        />
      </div>
      {/* <div>
        <RadioInput
          value={userRoles.MpAdminFisherman.value.toString()}
          checked={userRoles.MpAdminFisherman.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MpAdminFisherman.label}
          text={
            <div className="filter__item__text">
              {userRoles.MpAdminFisherman.label}
            </div>
          }
        />
      </div> */}
      <div>
        <RadioInput
          value={userRoles.MpSuperAdmin.value.toString()}
          checked={userRoles.MpSuperAdmin.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MpSuperAdmin.label}
          text={
            <div className="filter__item__text">
              {userRoles.MpSuperAdmin.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.AdviserAdminFarmer.value.toString()}
          checked={userRoles.AdviserAdminFarmer.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.AdviserAdminFarmer.label}
          text={
            <div className="filter__item__text">
              {userRoles.AdviserAdminFarmer.label}
            </div>
          }
        />
      </div>
      {/* <div>
        <RadioInput
          value={userRoles.AdviserAdminFisherman.value.toString()}
          checked={userRoles.AdviserAdminFisherman.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.AdviserAdminFisherman.label}
          text={
            <div className="filter__item__text">
              {userRoles.AdviserAdminFisherman.label}
            </div>
          }
        />
      </div> */}
      <div>
        <RadioInput
          value={userRoles.AdviserSuperAdmin.value.toString()}
          checked={userRoles.AdviserSuperAdmin.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.AdviserSuperAdmin.label}
          text={
            <div className="filter__item__text">
              {userRoles.AdviserSuperAdmin.label}
            </div>
          }
        />
      </div>
    </Filter>
  );
};
