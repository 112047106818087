import Select, { OptionTypeBase, StylesConfig } from "react-select";
import { Props as SelectComponentsProps } from "react-select/src/Select";
import { DropdownIndicator } from "./DropdownIndicator";
import { Control } from "./Control";

export interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}

export interface Props extends SelectComponentsProps<OptionType> {
  maxMenuHeight: number;
  size: "large" | "medium" | "small";
  isValid?: boolean;
  errorMessage: string;
}

const styles: StylesConfig<any, any, any> = {
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: () => ({
    color: "#242424",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    caretColor: "transparent",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

export function Dropdown(props: Props) {
  return (
    <>
      <Select<OptionType>
        {...props}
        maxMenuHeight={props.maxMenuHeight}
        styles={styles}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
      />

      {!props.isValid && (
        <p className="dropdown-error error-message">{props.errorMessage}</p>
      )}
    </>
  );
}
