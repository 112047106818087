import * as React from "react";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/checkbox-check.svg";
interface Props {
  checked: boolean;
  size: "base" | "small";
  type: "checkbox" | "radio";
  htmlFor: string;
  text: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<Props> = ({
  checked,
  type,
  className,
  htmlFor,
  size,
  value,
  text,
  isDisabled,
  onChange,
}) => {
  const getSize = () => {
    switch (size) {
      case "base":
        return "checkbox--base";
      case "small":
        return "checkbox--small";
      default:
        return;
    }
  };
  return (
    <>
      <input
        type={type}
        checked={checked}
        onChange={onChange}
        id={htmlFor}
        value={value}
        className={`checkbox ${getSize()} ${className ?? ""}`}
      />
      <label
        className={`checkbox-label ${
          isDisabled ? "checkbox-label--disabled" : ""
        }`}
        htmlFor={htmlFor}
      >
        <CheckIcon />
        {text}
      </label>
    </>
  );
};
