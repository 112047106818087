import { Button, Modal, ModalFooter, ModalHeader } from "modules/common";
import React from "react";

interface Props {
  isVisible: boolean;
  toggleModal: () => void;
}

export const TermsOfUseModal: React.FC<Props> = ({
  isVisible,
  toggleModal,
}) => {
  return (
    <Modal
      isActive={isVisible}
      onClose={toggleModal}
      modalHeader={
        <ModalHeader
          onClose={toggleModal}
          title="Uvjeti korištenja"
          isLoading={false}
        />
      }
      modalFooter={
        <ModalFooter>
          <Button
            type="button"
            size="small"
            variant="warning-ghost"
            isDisabled={false}
            onClick={toggleModal}
          >
            <span>Zatvori</span>
          </Button>
        </ModalFooter>
      }
    >
      <p className="mt-40 mr-40 mb-40 ml-40">
              <p>1. OPĆE ODREDBE</p>
              <p>Ministarstvo poljoprivrede, OIB: 7676736919, Ulica grada Vukovara 78, 10000 Zagreb (u daljnjem tekstu Ministarstvo), na način predviđen ovim Uvjetima: uređuje kreiranje korisničkog računa na web aplikaciji e-Savjetnik; prijava, pristup i korištenje sadržaja i raspoloživih podataka dostupnih putem web aplikacije e-Savjetnik.
              Web aplikacija e-Savjetnik namijenjena je poljoprivrednicima upisanima u Upisnik poljoprivrednika, zainteresiranim građanima te službenicima Ministarstva poljoprivrede.</p>
              <p>2. KREIRANJE KORISNIČKOG RAČUNA</p>
              <p>Kreiranje jedinstvenog korisničkog računa mogu izvršiti zainteresirani poljoprivrednici upisani u Upisnik poljoprivrednika i građani. Službenici Ministarstva poljoprivrede ne moraju kreirati korisnički račun već se u aplikaciju prijavljuju kroz svoje službene vjerodajnice. Poljoprivrednici se registriraju u sustav e-Savjetnik koristeći e-mail adresu registriranu u Upisniku poljoprivrednih gospodarstava. Nakon što se ispuni registracijski obrazac korisniku se šalje verifikacijska e-mail poruka i prijava u eSavjetnik je moguća tek nakon što je korisnik klikom na poveznicu u e-mailu potvrdio primitak maila i vlasništvo nad e-mail adresom registriranom u Upisniku poljoprivrednih gospodarstava.</p>
              <p>3. REGISTRACIJA NA WEB APLIKACIJU „e-SAVJETNIK“</p>
              <p>Korisnikom web aplikacije e-Savjetnik postaje se nakon izvršenog postupka registracije provedenog sukladno točki 2. ovih Uvjeta.
              Kreiranje korisničkog računa je dobrovoljno. Klikom na polje “Prihvaćam Uvjete korištenja„ Korisnik potvrđuje da je u cijelosti upoznat i prihvaća ove Uvjete na način da Ministarstvu daje privolu da njegove osobne podatke prikupljene na temelju i u svrhu izvršavanja danih usluga predviđenih u aplikaciji, obrađuje u svrhu pružanja usluga putem web aplikacije e-Savjetnik, a što obuhvaća:</p>
                  <ul>
                      <li>prikaz osobnih podataka Korisnika,</li>
                      <li>	pregledavanje računa i aktivnosti Korisnika,</li>
                  </ul>
              <p>sve u skladu s našim pravilima o zaštiti podataka Korisnika.</p>  
              <p>4. ZAHTJEV ZA BRISANJE KORISNIČKOG RAČUNA</p>
              <p> Ukoliko Korisnik želi izbrisati svoj korisnički račun u web aplikaciji e-Savjetnik, takav zahtjev može postaviti putem emaila strucna-podrska@mps.hr.</p>
              <p>5. ZAŠTITA OSOBNIH PODATAKA I PRIVATNOSTI PRILIKOM KORIŠTENJA WEB APLIKACIJE e-SAVJETNIK</p>
              <p>
                  <ul>
                      <li>Opće odredbe</li>
                      <p>Korištenjem usluga aplikacije e-Savjetnik povjeravate nam svoje osobne podatke. Ovim uvjetima Ministarstvo opisuje način postupanja sa osobnim podacima koji se Korisnicima daju na uvid i koji se od njega prikupljaju.
                      Ministarstvo pridaje osobitu pozornost zaštiti osobnih podataka i privatnosti svojih korisnika u skladu s važećim zakonskim i podzakonskim hrvatskim i europskim propisima i najboljom europskom praksom. Zaštita privatnosti korisnika aplikacije e-Savjetnik sastavni je dio naših usluga te važan čimbenik korisničkog iskustva.
                      Za potrebe korištenja aplikacije e-Savjetnik, Ministarstvo prikuplja i obrađuje one osobne podatke Korisnika koji su primjereni i nužni za postizanje određene svrhe te u skladu sa svrhom u koju su prikupljeni. Ministarstvo će osobne podatke korisnika čuvati u tajnosti te ih neće distribuirati, objavljivati, davati trećim stranama na korištenje niti ih na bilo koji drugi način učiniti dostupnima bilo kojoj trećoj fizičkoj ili pravnoj osobi bez prethodne suglasnosti Korisnika.</p>
                      <li>Zaštita podataka</li>
                      <p>Ministarstvo poštuje privatnost svojih Korisnika pa tako i Korisnika aplikacije e-Savjetnik te sa njihovim osobnim podacima postupa odgovorno i pažljivo poduzimajući sve potrebne tehničke, organizacijske i sigurnosne mjere u cilju zaštite istih od gubitka, izmjena, ili neovlaštenog pristupa.
                      Korisnikove osobne identifikacijske informacije čuvaju se na serveru, a pristup osobnim podacima je dozvoljen samo ovlaštenim službenicima Ministarstva.     </p>
                      <li>Prikupljanje podataka</li>
                      <p>Ministarstvo prati statističku posjećenost i način korištenja aplikacije e-Savjetnik radi prikupljanja podataka o uspješnosti usluga danih na korištenje Korisnicima.     </p>
                      <li>Povjerljivost podataka trećih osoba</li>
                      <p>Ovi Uvjeti odnose se samo na uporabu i korištenje aplikacije e-Savjetnik te u odnosu na podatke koje Ministarstvo prikuplja od Korisnika u svrhu omogućavanja korištenja navedenih servisa. Druge internetske stranice ili aplikacije kojima se može pristupiti putem aplikacije e-Savjetnik imaju svoje vlastite izjave o povjerljivosti i prikupljanju podataka te načine njihovog korištenja i objavljivanja na njima. Ako Korisnika prebaci na bilo koju takvu stranicu, preporučamo Korisniku da pregleda izjavu o povjerljivosti podataka te stranice. Ministarstvo nije odgovorno za načine i uvjete rada trećih strana s kojima nije u ugovornom odnosu.</p>
                  </ul>
              </p>
              <p>6. OBVEZE KORISNIKA</p>
              <p>Kao Korisnik dužni ste brinuti o sigurnosti Vaše Korisničke lozinke. Preporučljivo je da se lozinka ne daje drugim osobama. Vaša lozinka ne smije biti jednostavna, a preporučamo da lozinka sadržava minimalno 6 znamenki (brojke, slova i znakovi u kombinaciji). Također, lozinka ne bi smjela sadržavati: osobne podatke (ime, prezime, e-mail, kućna adresa, broj telefona, datum rođenja), brojeve u nizu ili identične brojeve, slova u nizu ili identična slova ili generičke riječi kao što su: lozinka, zaporka itd.
              Korisnik se obvezuje da će prilikom korištenja aplikacije iz ovih Uvjeta poduzimati odgovarajuće sigurnosne mjere svaki put nakon korištenja aplikacije kako bi se izbjegla mogućnost zlouporabe.
                Također, Ministarstvo ne odgovara za štetu koja nastane prilikom korištenja web aplikacije e-Savjetnik ukoliko je istu Korisnik koristio na nesavjestan način ili ukoliko je dao pristup trećim osobama, kao ni za možebitnu štetu i/ili posljedice nastale postupanjem protivno navedenim obvezama Korisnika.</p>
              <p>8. ZAVRŠNE ODREDBE</p>
              <p>Ministarstvo zadržava pravo izmijeniti i dopuniti odredbe ovih Uvjeta, uz obvezu objave promjena najmanje 15 dana prije stupanja na snagu, slanjem obavijesti putem aplikacije e-Savjetnik. Ovi Uvjeti stupaju na snagu najranije 15 dana od dana slanja obavijesti.</p>
      </p>
    </Modal>
  );
};
