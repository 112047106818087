import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, InputLabel, isEmpty, useTitle } from "modules/common";
import { AppRoutes } from "routing";
import {
  formErrorMessages,
  isValidEmail,
  EmailSent,
  AccountMessages,
  sendPasswordResetEmail,
} from "modules/auth";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Spinner } from "assets/icons/spinner.svg";
import { RootState } from "store";

interface FormState {
  email: string;
}

const Fields = {
  email: "email",
};

export const ForgotPassword: React.FC = () => {
  useTitle('Zaboravljena lozinka - eSavjetnik');
  const dispatch = useDispatch();
  const forgotPassword = useSelector(
    (state: RootState) => state.forgotPassword
  );
  const {
    isLoading,
    forgotPasswordError,
    errorMessage,
    passwordMailSent,
  } = forgotPassword;
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formState, setFormState] = useState<FormState>({
    email: "",
  });

  const clearFormError = (field: string) => {
    const filtered = Object.keys(formErrors)
      .filter((key) => key !== field)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: formErrors[key],
        };
      }, {});
    setFormErrors({ ...filtered });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      return;
    }

    if (!isValidEmail(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.invalidEmail,
      }));
      return;
    }

    clearFormError(name);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(sendPasswordResetEmail(formState.email));
  };

  const handleResend = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(sendPasswordResetEmail(formState.email));
  };

  const buttonDisabled =
    Object.values(formState).some((x) => x.length < 1) ||
    Object.values(formErrors).length > 0;

  return (
    <div>
      {passwordMailSent ? (
        <EmailSent onClick={handleResend} isLoading={isLoading} />
      ) : (
        <>
          <h1 className="forgot-password-heading mt-72">
            Zaboravljena lozinka
          </h1>
          <span className="forgot-password-signin">
            Želite nazad na prijavu?{" "}
            <Link
              className="forgot-password-signin__link"
              to={AppRoutes.SignIn}
            >
              Prijavite se
            </Link>
          </span>
          <p className="forgot-password-description">
            Unesite email adresu na koju ćemo vam poslati poveznicu za kreiranje
            nove lozinke.
          </p>
          {forgotPasswordError && (
            <AccountMessages error={true} message={errorMessage} />
          )}
          <form onSubmit={handleSubmit}>
            <div className="email-input">
              <InputLabel
                labelFor={Fields.email}
                labelText="Email adresa"
                isValid={formErrors[Fields.email] ? false : true}
              />
              <Input
                name={Fields.email}
                id={Fields.email}
                type="text"
                size="large"
                placeholder="Email adresa"
                isValid={formErrors[Fields.email] ? false : true}
                errorMessage={formErrors[Fields.email]}
                onBlur={handleEmailBlur}
                onChange={handleInputChange}
              />
            </div>

            {isLoading ? (
              <div className="ml-80">
                <Spinner className="spinner" />
              </div>
            ) : (
              <Button
                type="submit"
                variant="primary"
                size="large"
                isDisabled={buttonDisabled}
              >
                <span>Pošaljite poveznicu</span>
              </Button>
            )}
          </form>
        </>
      )}
    </div>
  );
};
