import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface UserActivity {
  userUid: string;
  firstName: string;
  lastName: string;
  userRole: string;
  userAction: string;
  application: string;
  createdAt: string;
}

interface State {
  isLoading: boolean;
  fetchError: boolean;
  fetchSuccess: boolean;
  userActivityLogCount: number;
  errorMessage: string | undefined;
  data: UserActivity[] | null;
}

interface GetUserActivitySuccess {
  data: UserActivity[];
  userActivityLogCount: number;
}

interface GetUserActivityError {
  errorMessage: string;
}

const initialState: State = {
  isLoading: false,
  fetchError: false,
  fetchSuccess: false,
  errorMessage: undefined,
  userActivityLogCount: 0,
  data: null,
};

const userActivitySlice = createSlice({
  name: "UserActivity",
  initialState: initialState,
  reducers: {
    getUserActivityRequest: (state) => {
      state.isLoading = true;
    },
    getUserActivitySuccess: (
      state,
      action: PayloadAction<GetUserActivitySuccess>
    ) => {
      state.isLoading = false;
      state.fetchSuccess = true;
      state.data = action.payload.data;
      state.userActivityLogCount = action.payload.userActivityLogCount;
    },
    getUserActivityError: (
      state,
      action: PayloadAction<GetUserActivityError>
    ) => {
      state.isLoading = false;
      state.fetchError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = userActivitySlice;

export const {
  getUserActivityError,
  getUserActivityRequest,
  getUserActivitySuccess,
} = actions;

export const userActivityReducer = userActivitySlice.reducer;
