export interface IDisplayOrInput {
    isEdit: boolean;
    children: [React.ReactNode, React.ReactNode];
}

export const DisplayOrInput = ({isEdit, children} : IDisplayOrInput) => {
    const [Display, Input] = children;
    return <>{isEdit ? Input : Display}</>;
};

export default DisplayOrInput;
