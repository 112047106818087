import React, { useEffect } from "react";
import { ReactComponent as LocationPin } from "assets/icons/location-pin.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getEducationById } from "modules/education";
import { Loading, NoResults, WEEKDAYS_LONG } from "modules/common";
import { format } from "date-fns";

interface Props {
  educationUid: string;
}

export const SpecificEducation: React.FC<Props> = ({ educationUid }) => {
  const dispatch = useDispatch();
  const { educationById, isLoading } = useSelector(
    (state: RootState) => state.educations
  );
  const { education } = educationById || {};

  useEffect(() => {
    dispatch(getEducationById(educationUid));
  }, [educationUid]);

  return isLoading ? (
    <Loading />
  ) : education ? (
    <>
      <div>
        <h3 className="education__item-date">
          {WEEKDAYS_LONG[new Date(education.startDate).getDay()]}
          {", "}
          {format(new Date(education.startDate), "dd.MM.yyyy.")}
        </h3>

        <div className="separator" />
      </div>
      <div className="f">
        <p className="education__item-time education__item-time--active">{`${format(
          new Date(education.startDate),
          "HH:mm"
        )} - ${format(new Date(education.endDate), "HH:mm")}`}</p>
        <div className="education__list-wrapper">
          {/* Header */}
          <div className="education__item-header">
            <h2 className="education__item-title">{education.title}</h2>
          </div>

          {/* Subheader */}
          <div className="f">
            {education.county && (
              <p className="educations__item-subheading educations__item-subheading--first">
                {education.county}
              </p>
            )}
            {education.expertArea && (
              <p className="educations__item-subheading educations__item-subheading--second">
                {education.expertArea}
              </p>
            )}
            {education.measure && (
              <p className="educations__item-subheading educations__item-subheading--third">
                {education.measure}
              </p>
            )}
          </div>

          {/* Location */}
          <p className="education__item-location">
            <LocationPin className="mr-12" /> {education.address}
          </p>

          {/* Details */}
          <div className="education__item-details">
            {/* col1 */}
            <div>
              <h5 className="education__item-label">Kapacitet</h5>
              <p className="mt-8 mb-24">{`${education.spotsAvailable}/${education.maxAttendees}`}</p>

              <h5 className="education__item-label">Obaveza</h5>
              <p
                className={`education__item-obligation ${
                  education.isEducationMandatoryForFarmer &&
                  "education__item-obligation--active"
                }`}
              >
                {education.isEducationMandatoryForFarmer ? "Da" : "Ne"}
              </p>
            </div>

            {/* col2 */}
            <div>
              <h5 className="education__item-label">Predavači</h5>
              {education.instructors.map((value, index) => {
                return (
                  <p className="mt-8" key={index}>
                    {value.name}
                  </p>
                );
              })}
            </div>

            {/* col3 */}
            <div>
              <h5 className="education__item-label">Napomena</h5>
              <p className="mt-8">{education.remark}</p>
            </div>
          </div>
          <div className="separator" />

          {education.educationStatuses?.length ? (
            <div>
              <p className="f mt-8">
                {education.educationStatuses.map((value, index) => {
                  const showComma =
                    index + 1 < education.educationStatuses.length;

                  return (
                    <React.Fragment key={index}>
                      {value.farmerName} {value.farmerLastName}
                      {showComma && ", "}
                    </React.Fragment>
                  );
                })}
              </p>
              <div className="separator" />
            </div>
          ) : null}
        </div>
      </div>
    </>
  ) : (
    <NoResults className="mt-24" />
  );
};
