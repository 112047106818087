import { toast } from 'react-toastify';
import {
  addOrUpdatePresentationFile,
  AddOrUpdatePresentationRequest,
  deletePresentationItem,
  downloadPresentationItem,
  getAllPresentations,
  PresentationsRequest,
} from 'services';
import { AppThunk } from 'store';
import {
  deletePresentationSuccess,
  getPresentationDownloadError,
  getPresentationDownloadRequest,
  getPresentationDownloadSuccess,
  getPresentationsError,
  getPresentationsRequest,
  getPresentationsSuccess,
  getPresentationUploadSuccess,
} from './PresentationSlice';

export const getPresentations =
  (data: PresentationsRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn } = getState();

      if (signIn.user) {
        dispatch(getPresentationsRequest());

        const { accessToken } = signIn.user;

        const response = await getAllPresentations(accessToken, data);

        dispatch(getPresentationsSuccess({ presentations: response.data }));
      }
    } catch (error: any) {
      dispatch(getPresentationsError({ errorMessage: error.message }));
    }
  };

export const addOrUpdatePresentation =
  (data: AddOrUpdatePresentationRequest, callback: VoidFunction): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn } = getState();

      if (signIn.user) {
        dispatch(getPresentationsRequest());

        const { accessToken } = signIn.user;

        var formData = new FormData();

        toast.success('Modal će se zatvoriti kad se datoteka prenese');
        formData.append('Id', data.id || '');
        formData.append('File', data.file);
        formData.append('Type', data.type);
        formData.append('ExpertArea.Name', data.expertArea);
        formData.append('OperationType.Name', data.operationTypes);
        formData.append('Measure.Name', data.measures);
        formData.append('Author', data.author);

        const response = await addOrUpdatePresentationFile(
          accessToken,
          formData
        );

        dispatch(getPresentationUploadSuccess({ response: response.data }));
        callback();
        dispatch(
          getPresentations({
            pageNumber: 1,
            pageSize: 10,
            isActive: true,
            title: null,
            fileTypes: null,
            expertAreas: null,
            measures: null,
            operationTypes: null,
            createdAt: null,
          })
        );
        toast.success('Datoteka uspješno prenesena');
      }
    } catch (error: any) {
      dispatch(getPresentationsError({ errorMessage: error.message }));
    }
  };

export const downloadPresentation =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn } = getState();

      if (signIn.user) {
        dispatch(getPresentationDownloadRequest());

        const { accessToken } = signIn.user;

        const response = await downloadPresentationItem(accessToken, id);

        const binaryString = window.atob(response.data.fileAsByteArray);
        const bytes = new Uint8Array(binaryString.length);
        const base64ToArrayBuffer = bytes.map((_, i) =>
          binaryString.charCodeAt(i)
        );

        const blob = new Blob([base64ToArrayBuffer], {
          type: response.data.contentType,
        });
        const fileName = `${response.data.title}${response.data.extension}`;
        const link = document.createElement('a');

        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        dispatch(getPresentationDownloadSuccess());
      }
    } catch (error: any) {
      dispatch(getPresentationDownloadError(error));
    }
  };

export const deletePresentation =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn } = getState();

      if (signIn.user) {
        const { accessToken } = signIn.user;

        await deletePresentationItem(accessToken, id);
        dispatch(deletePresentationSuccess());
        dispatch(
          getPresentations({
            pageNumber: 1,
            pageSize: 10,
            isActive: true,
            title: null,
            fileTypes: null,
            expertAreas: null,
            measures: null,
            operationTypes: null,
            createdAt: null,
          })
        );
        toast.success('Prezentacija uspješno obrisana');
      }
    } catch (error: any) {
      dispatch(getPresentationsError({ errorMessage: error.message }));
      toast.error('Pogreška prilikom brisanja prezentacija, pokušajte ponovo');
    }
  };
