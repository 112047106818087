import { differenceInSeconds } from "date-fns";
type VoidFunction = () => void;

export class Timer {
  private defaultTimerInterval = 5; // how often do we check for token expiration, default 5 seconds.
  private tokenExpirationTreshold = 60; // trigger token refresh 60 seconds before it expires.
  private timerHandle: NodeJS.Timeout | null = null;
  private static instance: Timer;
  private handler: VoidFunction | null = null;
  private expiresAt: Date | null = null;
  private constructor() {}

  static getInstance = () => {
    if (!Timer.instance) {
      Timer.instance = new Timer();
    }

    return Timer.instance;
  };

  private setTimer = (cb: VoidFunction, duration: number) =>
    setInterval(cb, duration);

  private clearTimer = (handle: NodeJS.Timeout) => clearInterval(handle);

  public cancel = () => {
    if (this.timerHandle) {
      this.clearTimer(this.timerHandle);
      this.timerHandle = null;
    }
  };

  private checkDiff = () => {
    const now = new Date(Date.now());
    
    if (
      differenceInSeconds(this.expiresAt!, now) <= this.tokenExpirationTreshold
    ) {
      this.cancel();
      // raise the event
      if (this.handler) {
        this.handler();
      }
    }
  };

  public addHandler = (timerHandler: VoidFunction) => {
    this.handler = timerHandler;
  };

  public start = (duration: number) => {
    if (duration <= 0) {
      duration = 1;
    }

    const expiresAt = new Date(duration * 1000);
    this.expiresAt = new Date(duration * 1000);

    //timer already set with same expiration, terminate
    if (this.expiresAt === expiresAt && this.timerHandle) {
      return;
    }

    this.cancel();

    this.expiresAt = expiresAt;

    // default timer is short to handle scenarios where the browser device sleeps, and then the timers end up getting delayed.
    let timerDuration = this.defaultTimerInterval;

    if (duration < timerDuration) {
      timerDuration = duration;
    }

    this.timerHandle = this.setTimer(this.checkDiff, timerDuration * 1000);
  };
}
