import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isUpdatingRoles: boolean;
  roleUpdateSuccess: boolean;
  roleUpdateError: boolean;
  errorMessage: string;
}

interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  errorMessage: "",
  isUpdatingRoles: false,
  roleUpdateError: false,
  roleUpdateSuccess: false,
};

const updateRolesSlice = createSlice({
  name: "UpdateUserRole",
  initialState: initialState,
  reducers: {
    updateRolesRequest: (state) => {
      state.isUpdatingRoles = true;
    },
    updateRolesSuccess: (state) => {
      state.isUpdatingRoles = false;
      state.roleUpdateSuccess = true;
    },
    updateRolesError: (state, action: PayloadAction<RequestError>) => {
      state.isUpdatingRoles = false;
      state.roleUpdateError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = updateRolesSlice;

export const {
  updateRolesRequest,
  updateRolesSuccess,
  updateRolesError,
} = actions;

export const updateRolesReducer = updateRolesSlice.reducer;
