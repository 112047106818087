import { useQueries, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getUserAccountsByUids, UserAccounts } from "services";
import { useAccessToken } from "./useAccessToken";

const ACCOUNT_STALE_TIME = 1000 * 60 * 5;

const getUserAccountByUid = async (id: string, accessToken: string) => {
    const resp = await getUserAccountsByUids([id], accessToken);
    return resp.data.accounts[0];
};

export const useAccounts = (ids: string[], options?: UseQueryOptions<UserAccounts>) => {
    const accessToken = useAccessToken();

    const queries: UseQueryOptions<UserAccounts>[] = ids.map(id => ({
        queryKey: ["account", id],
        queryFn: () => getUserAccountByUid(id, accessToken!),
        staleTime: ACCOUNT_STALE_TIME,
        enabled: !Boolean(accessToken) || !Boolean(id),
        ...options,
    }));

    return useQueries({ queries });
};

export const useAccount = (id?: string, options?: UseQueryOptions<UserAccounts>) => {
    const accessToken = useAccessToken();

    return useQuery({
        queryKey: ["account", id],
        queryFn: () => getUserAccountByUid(id!, accessToken!),
        staleTime: ACCOUNT_STALE_TIME,
        enabled: Boolean(accessToken) && Boolean(id),
        ...options,
    });
};
