import React from "react";

interface Props {
  width: string;
  height: string;
  className?: string;
}
export const SkeletonText: React.FC<Props> = ({ width, height, className }) => {
  return (
    <div
      className={`skeleton-loading ${className ?? ""}`}
      style={{ width, height }}
    />
  );
};
