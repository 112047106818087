enum County {
  BjelovarskoBilogorska = 0x000001,
  BrodskoPosavska = 0x000002,
  DubrovackoNeretvanska = 0x000004,
  GradZagreb = 0x000008,
  Istarska = 0x000010,
  Medimurska = 0x000020,
  Karlovacka = 0x000040,
  KoprivnickoKrizevacka = 0x000080,
  KrapinskoZagorska = 0x000100,
  LickoSenjska = 0x000200,
  PozeskoSlavonska = 0x000400,
  PrimorskoGoranska = 0x000800,
  OsjeckoBaranjska = 0x001000,
  SibenskoKninska = 0x002000,
  SisackoMoslavacka = 0x004000,
  SplitskoDalmatinska = 0x008000,
  VirovitickoPodravska = 0x010000,
  Varazdinska = 0x020000,
  VukovarskoSrijemska = 0x040000,
  Zadarska = 0x080000,
  Zagrebacka = 0x100000,
}

export const CountyFilterFields: Record<
  string,
  { value: number; label: string }
> = {
  bjelovarskoBilogorska: {
    label: "Bjelovarsko-bilogorska",
    value: County.BjelovarskoBilogorska,
  },
  brodskoPosavska: { value: County.BrodskoPosavska, label: "Brodsko-posavska" },
  dubrovackoNeretvanska: {
    value: County.DubrovackoNeretvanska,
    label: "Dubrovačko-neretvanska",
  },
  istarska: { value: County.Istarska, label: "Istarska" },
  karlovacka: { value: County.Karlovacka, label: "Karlovačka" },
  koprivnickoKrizevacka: {
    value: County.KoprivnickoKrizevacka,
    label: "Koprivničko-križevačka",
  },
  krapinskoZagorska: {
    value: County.KrapinskoZagorska,
    label: "Krapinsko-zagorska",
  },
  lickoSenjska: { value: County.LickoSenjska, label: "Ličko-senjska" },
  medjimurska: { value: County.Medimurska, label: "Međimurska" },
  osjeckoBaranjska: {
    value: County.OsjeckoBaranjska,
    label: "Osječko-baranjska",
  },
  pozeskoSlavonska: {
    value: County.PozeskoSlavonska,
    label: "Požeško-slavonska",
  },
  primorskoGoranska: {
    value: County.PrimorskoGoranska,
    label: "Primorsko-goranska",
  },
  sisackoMoslavacka: {
    value: County.SisackoMoslavacka,
    label: "Sisačko-moslavačka",
  },
  splitskoDalmatinska: {
    value: County.SplitskoDalmatinska,
    label: "Splitsko-dalmatinska",
  },
  sibenskoKninska: { value: County.SibenskoKninska, label: "Šibensko-kninska" },
  varazdinska: { value: County.Varazdinska, label: "Varaždinska" },
  virovitickoPodravska: {
    value: County.VirovitickoPodravska,
    label: "Virovitičko-podravska",
  },
  vukovarskoSrijemska: {
    value: County.VukovarskoSrijemska,
    label: "Vukovarsko-srijemska",
  },
  zadarska: { value: County.Zadarska, label: "Zadarska" },
  zagrebacka: { value: County.Zagrebacka, label: "Zagrebačka" },
  gradZagreb: { value: County.GradZagreb, label: "Grad Zagreb" },
};
