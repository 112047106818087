import { toast } from 'react-toastify';
import {
  educationApplication,
  EducationApplicationRequest,
  EducationsRequest,
  getAllEducations,
  getAllExpertAreas,
  getAllMeasures,
  getAllOperationTypes,
  addReminderToEducation,
  EducationReminder,
  EducationReminderRequest,
  getUsersEducationsWithReminder,
  getFarmHolder,
  getFarmMembers,
  getEducation,
} from 'services';
import { AppThunk } from 'store';
import {
  getEducationsError,
  getEducationsRequest,
  getEducationsSuccess,
  getMeasuresError,
  getMeasuresRequest,
  getMeasuresSuccess,
  getFarmHolderSuccess,
  getFarmHolderError,
  getFarmHolderRequest,
  getEducationApplicationSuccess,
  getOperationTypesRequest,
  getOperationTypesSuccess,
  getOperationTypesError,
  getExpertAreasRequest,
  getExpertAreasSuccess,
  getExpertAreasError,
  getEducationReminderError,
  getEducationsWithRemindersSuccess,
  getAllReminders,
  getFarmMembersSuccess,
  getFarmMembersError,
  getEducationByIdSuccess,
} from '.';
import {
  getAppliedEducationsSuccess,
  getEducationsApplicationError,
  getListenedEducationsSuccess,
} from './EducationsSlice';
import {
  handleAddEducationsWithReminders,
  handleGetEducationsWithReminders,
  handleGetPaginatedEducationsWithReminders,
} from '../utils';

export const getEducations =
  (data: Omit<EducationsRequest, 'mibpg'>): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn, profile } = getState();

      if (signIn.user && profile.profile) {
        dispatch(getEducationsRequest());

        const { accessToken } = signIn.user;
        const { mibpg } = profile.profile;

        const response = await getAllEducations(accessToken, {
          mibpg,
          ...data,
        });

        switch (data.status) {
          case null:
            dispatch(getEducationsSuccess({ educations: response.data }));
            return;
          case 1:
            dispatch(
              getAppliedEducationsSuccess({ educations: response.data })
            );
            return;
          case 2:
            dispatch(
              getListenedEducationsSuccess({ educations: response.data })
            );
            return;
          default:
            return;
        }
      }
    } catch (error: any) {
      dispatch(getEducationsError({ errorMessage: error.message }));
    }
  };

export const getEducationById =
  (educationUid: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn } = getState();

      if (!signIn.user || !educationUid) return;
      dispatch(getEducationsRequest());

      const { accessToken } = signIn.user;
      const response = await getEducation(accessToken, educationUid);

      dispatch(getEducationByIdSuccess({ education: response.data }));
    } catch (error: any) {
      dispatch(getEducationsError({ errorMessage: error.message }));
      toast.error('Problem prilikom spremanja podataka, pokušajte ponovno...');
    }
  };

export const getMeasures = (): AppThunk => async (dispatch, getState) => {
  try {
    const { signIn, measures } = getState();

    if (signIn.user && !measures.items?.length) {
      dispatch(getMeasuresRequest());
      const { accessToken } = signIn.user;
      const response = await getAllMeasures(accessToken);

      dispatch(getMeasuresSuccess({ measures: response.data.measures }));
    }
  } catch (error: any) {
    dispatch(getMeasuresError({ errorMessage: error }));
  }
};

export const getOperationTypes = (): AppThunk => async (dispatch, getState) => {
  try {
    const { signIn, operationTypes } = getState();

    if (signIn.user && !operationTypes.items?.length) {
      dispatch(getOperationTypesRequest());
      const { accessToken } = signIn.user;
      const response = await getAllOperationTypes(accessToken);

      dispatch(
        getOperationTypesSuccess({
          operationTypes: response.data.operationTypes,
        })
      );
    }
  } catch (error: any) {
    dispatch(getOperationTypesError({ errorMessage: error }));
  }
};

export const getExpertAreas = (): AppThunk => async (dispatch, getState) => {
  try {
    const { signIn, expertAreas } = getState();

    if (signIn.user && !expertAreas.items?.length) {
      dispatch(getExpertAreasRequest());
      const { accessToken } = signIn.user;
      const response = await getAllExpertAreas(accessToken);

      dispatch(
        getExpertAreasSuccess({
          expertAreas: response.data.expertAreas,
        })
      );
    }
  } catch (error: any) {
    dispatch(getExpertAreasError({ errorMessage: error }));
  }
};

export const getHolderFarm = (): AppThunk => async (dispatch, getState) => {
  try {
    const { signIn, profile } = getState();
    if (signIn.user && profile.profile) {
      dispatch(getFarmHolderRequest());

      const { accessToken } = signIn.user;
      const { mibpg } = profile.profile;

      const response = await getFarmHolder(accessToken, mibpg);

      dispatch(
        getFarmHolderSuccess({
          farmHolder: response.data,
        })
      );
    }
  } catch (error) {
    dispatch(
      getFarmHolderError({
        errorMessage:
          'Problem prilikom dohvaćanja podataka, pokušajte ponovno...',
      })
    );
  }
};

export const getMembersFarm = (): AppThunk => async (dispatch, getState) => {
  try {
    const { signIn, profile } = getState();

    if (signIn.user && profile.profile) {
      dispatch(getFarmHolderRequest());

      const { accessToken } = signIn.user;
      const { mibpg } = profile.profile;

      const response = await getFarmMembers(accessToken, mibpg);

      dispatch(
        getFarmMembersSuccess({
          farmMembers: response.data,
        })
      );
    }
  } catch (error: any) {
    dispatch(
      getFarmMembersError({
        errorMessage:
          'Problem prilikom dohvaćanja podataka, pokušajte ponovno...',
      })
    );
  }
};

export const educationSignUp =
  (data: Omit<EducationApplicationRequest, 'enrolled'>): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn, profile } = getState();

      if (signIn.user && profile.profile) {
        dispatch(getEducationsRequest());

        const { accessToken } = signIn.user;
        const { mibpg } = profile.profile;
        data.mibpg = mibpg == '' ? data.mibpg : mibpg;
        const response = await educationApplication(accessToken, {
          enrolled: 1,
          ...data,
        });
        dispatch(getEducationApplicationSuccess({ response: response.data }));
        toast.success(response.data.response.notice[0]);
      }
    } catch (error: any) {
      dispatch(getEducationsApplicationError({ error: true }));
      const errorNotice = error.response.data.response.notice;
      toast.error(
        typeof errorNotice == 'undefined'
          ? 'Problem prilikom spremanja podataka, pokušajte ponovno...'
          : 'Već ste prijavljeni na ovaj tečaj'
      );
    }
  };

export const addReminder =
  (data: EducationReminder): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        signIn,
        profile,
        educations: { allReminders },
      } = getState();
      if (!signIn.user || !profile.profile || !data) return;
      dispatch(getEducationsRequest());

      const { accessToken } = signIn.user;
      const { accountUid, education, remindInDays } = data;

      const reminderToAdd: EducationReminderRequest = {
        educationId: education.id,
        accountUid: accountUid,
        educationUid: education.uid,
        remindInDays: remindInDays,
        mibpg: profile.profile.mibpg,
      };

      const response = await addReminderToEducation(accessToken, reminderToAdd);

      // Had to store them all to redux because we need to check
      // if picked education has any reminder.
      dispatch(
        getAllReminders({
          reminders: handleAddEducationsWithReminders(
            { accountUid, education, remindInDays },
            allReminders?.items
          ),
        })
      );

      // Also, had to add them to a special redux state because
      // there's a need for pagination and this one handles state for pagination.
      dispatch(
        getEducationsWithRemindersSuccess({
          educations: handleAddEducationsWithReminders(
            { accountUid, education, remindInDays },
            allReminders?.items
          ),
        })
      );
      toast.success(response.data.statuses[0].message);
    } catch (error: any) {
      dispatch(getEducationReminderError({ error: true }));
      toast.error(error.response.data.statuses[0].message);
    }
  };

export const getEducationsWithReminder =
  (data: { pageSize: number; pageNumber: number }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn, profile } = getState();
      dispatch(getEducationsRequest());

      if (!signIn.user || !profile.profile) return;
      const { accessToken } = signIn.user;

      const response = await getUsersEducationsWithReminder(
        accessToken,
        profile.profile.uid
      );

      // Had to store them all to redux because we need to check
      // if picked education has any reminder.
      dispatch(
        getAllReminders({
          reminders: handleGetEducationsWithReminders(
            response.data.educationReminders
          ),
        })
      );

      // Also, had to add them to a special redux state because
      // there's a need for pagination and this one handles state for pagination.
      dispatch(
        getEducationsWithRemindersSuccess({
          educations: handleGetPaginatedEducationsWithReminders(
            response.data.educationReminders,
            data.pageSize,
            data.pageNumber
          ),
        })
      );
    } catch (error) {
      dispatch(getEducationsApplicationError({ error: true }));
      toast.error('Problem prilikom dohvaćanja podataka, pokušajte ponovno...');
    }
  };
