import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForAdviserSuperAdmin = (
  abilityBuilder: AbilityBuilder<AppAbility>
) => {
  const { can, rules, cannot } = abilityBuilder;

  cannot("update", "UserRole");

  can("view", "UserActivityModule");
  can("view", "UserAdministrationModule");
  can("view", "PresentationsModule");
  can("view", "CommunicationModule");
  can("view", "EducationModule");
  can("view", "EducationLinks");
  can("view", "CourseRegistration");
  can("view", "CourseList");
  can("view", "FarmList");

  can("create", "AddPresentation");
  can("update", "EditPresentation");
  can("update", "DeletePresentation");
  can("view", "DownloadPresentation");

  can("view", "AgroclimateStations");
  can("view", "PlantProtection");
  can("view", "SEMIS");
  can("view", "InternalDocumentation");
  can("view", "PIO");
  can("view", "Webtop");
  can("view", "ReportProblem");
  can("view", "PlantProtection");

  can("view", "SuperAdminFilter");
  can("view", "InternalSystemsModule");
  can("view", "Records");

  can("view", "WeatherAlarmToggle");


  return rules;
};
