import * as React from "react";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as SmsIcon } from "assets/icons/sms.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";
import { notificationTypes } from "modules/common";
import { useMemo } from "react";

interface Props {
  notificationType: string[];
}

export const Notifications: React.FC<Props> = ({ notificationType }) => {
  const notifications = useMemo(
    () => [
      {
        value: notificationTypes.Email.value,
        label: notificationTypes.Email.label,
        icon: EmailIcon,
      },
      {
        value: notificationTypes.SMS.value,
        label: notificationTypes.SMS.label,
        icon: SmsIcon,
      },
      {
        value: notificationTypes.Viber.value,
        label: notificationTypes.Viber.label,
        icon: MessengerIcon,
      },
      {
        value: notificationTypes.WhatsApp.value,
        label: notificationTypes.WhatsApp.label,
        icon: MessengerIcon,
      },
    ],
    []
  );

  return (
    <div className="notification-container">
      {notifications
        .filter((n) => notificationType.includes(n.label))
        .map(({ label, icon: Icon }, index) => (
          <div key={index}>
            <Icon className="notification-icon" />
            <label className="notification-text">{label}</label>
          </div>
        ))}
    </div>
  );
};
