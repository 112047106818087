import React, { useRef, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { useOutsideClick } from "modules/common";

interface Props {
  name: string;
  className?: string;
}
export const NavDropdown: React.FC<Props> = ({ className, name, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  useOutsideClick(ref, () => setShow(false));

  return (
    <div className="nav-dropdown" ref={ref}>
      <div
        className={`nav-dropdown__title ${className ?? ""}`}
        onClick={() => setShow(!show)}
      >
        <span className="nav-dropdown__text">{name}</span>
        <ArrowDownIcon className={`${show && "nav-dropdown__icon--active"}`} />
      </div>
      {show && <div className="nav-dropdown__container">{children}</div>}
    </div>
  );
};
