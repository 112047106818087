import * as React from "react";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  htmlFor: string;
  className?: string;
  value?: string;
  isDisabled?: boolean;
}

export const ToggleSwitch: React.FC<Props> = ({
  checked,
  isDisabled,
  className,
  onChange,
  htmlFor,
  value,
}) => {
  return (
    <>
      <label htmlFor={htmlFor} className={`toggle ${className ?? ""}`}>
        <input
          className={`toggle__input ${
            isDisabled ? "toggle__input--disabled" : ""
          }`}
          type="checkbox"
          value={value}
          checked={checked}
          onChange={onChange}
          id={htmlFor}
          disabled={isDisabled}
        />
        <div className={`toggle__fill ${isDisabled ? "toggle--disabled" : ""}`}>
          {checked ? (
            <CheckIcon
              className={`toggle__icon toggle__icon-check ${
                checked ? "toggle__icon--checked" : ""
              }`}
            />
          ) : (
            <CrossIcon className={`toggle__icon`} />
          )}
        </div>
      </label>
    </>
  );
};
