import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountDashboardData, AccountDashboardDataResponse } from "services";

interface State {
  isFetching: boolean;
  fetchError: boolean;
  fetchSuccess: boolean;
  errorMessage: string;
  data: AccountDashboardData | null;
}

interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  isFetching: false,
  fetchError: false,
  fetchSuccess: false,
  errorMessage: "",
  data: null,
};

const dashboardDataSlice = createSlice({
  name: "DashboardData",
  initialState: initialState,
  reducers: {
    getDashboardDataRequest: (state) => {
      state.isFetching = true;
    },
    getDashboardDataSuccess: (state, action: PayloadAction<AccountDashboardDataResponse>) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.data = action.payload.data;
    },
    getDashboardDataError: (state, action: PayloadAction<RequestError>) => {
      state.isFetching = false;
      state.fetchError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = dashboardDataSlice;

export const {
  getDashboardDataError,
  getDashboardDataRequest,
  getDashboardDataSuccess,
} = actions;

export const dashboardDataReducer = dashboardDataSlice.reducer;
