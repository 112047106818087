import React from "react";
import { useTable, useSortBy } from "react-table";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { NoResults } from "../NoResults";

interface Props {
  data: Array<any>;
  columns: any;
}
export const Table: React.FC<Props> = ({ data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <table className="table" {...getTableProps()}>
      <TableHeader headerGroups={headerGroups} />
      {data.length === 0 ? (
        <tbody>
          <tr>
            <td colSpan={4}>
              <NoResults className="mt-24" />
            </td>
          </tr>
        </tbody>
      ) : (
        <TableBody
          rows={rows}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
        />
      )}
    </table>
  );
};
