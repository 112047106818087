import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddOrUpdatePresentationResponse,
  PresentationsResponse,
} from "services";

interface State {
  isLoading: boolean;
  isDownloading: boolean;
  errorMessage: string | undefined;
  presentations: PresentationsResponse | null;
  presentationUpload?: AddOrUpdatePresentationResponse;
}

const initialState: State = {
  isLoading: false,
  isDownloading: false,
  errorMessage: undefined,
  presentations: null,
  presentationUpload: undefined,
};

const presentationsSlice = createSlice({
  name: "Presentations",
  initialState: initialState,
  reducers: {
    getPresentationsRequest: (state) => {
      state.isLoading = true;
    },
    getPresentationsSuccess: (
      state,
      action: PayloadAction<{ presentations: PresentationsResponse }>
    ) => {
      state.isLoading = false;
      state.presentations = action.payload.presentations;
    },
    getPresentationsError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
    getPresentationUploadSuccess: (
      state,
      action: PayloadAction<{ response: AddOrUpdatePresentationResponse }>
    ) => {
      state.isLoading = false;
      state.presentationUpload = action.payload.response;
    },
    deletePresentationSuccess: (state) => {
      state.isLoading = false;
    },

    getPresentationDownloadRequest: (state) => {
      state.isDownloading = true;
    },

    getPresentationDownloadSuccess: (state) => {
      state.isDownloading = false;
    },

    getPresentationDownloadError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isDownloading = false;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = presentationsSlice;

export const {
  getPresentationsRequest,
  getPresentationsSuccess,
  getPresentationsError,
  getPresentationUploadSuccess,
  deletePresentationSuccess,
  getPresentationDownloadRequest,
  getPresentationDownloadSuccess,
  getPresentationDownloadError,
} = actions;

export const presentationsReducer = presentationsSlice.reducer;
