import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CityMunicipalityResponse,
  CountyResponse,
  SettlementResponse,
} from "services";

// create models out of Country and Settlement and add initial states

interface State {
  isLoading: boolean;
  errorMessage: string;
  counties: CountyResponse[];
  settlements: SettlementResponse[];
  cityMunicipalities: CityMunicipalityResponse[];
}

interface RequestError {
  errorMessage: string;
}

const initialState: State = {
  isLoading: false,
  errorMessage: "",
  counties: [],
  settlements: [],
  cityMunicipalities: [],
};

const addressSlice = createSlice({
  name: "Profile",
  initialState: initialState,
  reducers: {
    getCountiesRequest: (state) => {
      state.isLoading = true;
    },
    getCountiesSuccess: (
      state,
      action: PayloadAction<{ counties: CountyResponse[] }>
    ) => {
      state.isLoading = false;
      state.counties = action.payload.counties;
    },
    getCountiesError: (state, action: PayloadAction<RequestError>) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
    getSettlementsRequest: (state) => {
      state.isLoading = true;
    },
    getSettlementsSuccess: (
      state,
      action: PayloadAction<{ settlements: SettlementResponse[] }>
    ) => {
      state.isLoading = false;
      state.settlements = action.payload.settlements;
    },
    getSettlementsError: (state, action: PayloadAction<RequestError>) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
    getCityMunicipalitiesRequest: (state) => {
      state.isLoading = true;
    },
    getCityMunicipalitiesSuccess: (
      state,
      action: PayloadAction<{ municipalities: CityMunicipalityResponse[] }>
    ) => {
      state.isLoading = false;
      state.cityMunicipalities = action.payload.municipalities;
    },
    getCityMunicipalitiesError: (
      state,
      action: PayloadAction<RequestError>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = addressSlice;

export const {
  getCountiesRequest,
  getCountiesSuccess,
  getCountiesError,
  getSettlementsRequest,
  getSettlementsSuccess,
  getSettlementsError,
  getCityMunicipalitiesRequest,
  getCityMunicipalitiesSuccess,
  getCityMunicipalitiesError,
} = actions;

export const addressReducer = addressSlice.reducer;
