import React from "react";
import {
  DoughnutChart,
  StatisticsContainer,
  StatisticsContent,
  subscriptionChartOptions,
} from "modules/common";

interface Props {
  usersByCourses: number;
  usersByManifestations: number;
  usersByDemonstrations: number;
  usersByFarmingRecommendations: number;
  usersByNews: number;
  usersByAdvices: number;
  total: number;
}
export const SubscriptionsStats: React.FC<Props> = ({
  usersByAdvices,
  usersByCourses,
  usersByDemonstrations,
  usersByFarmingRecommendations,
  usersByManifestations,
  usersByNews,
  total,
}) => {
  return (
    <StatisticsContainer title="Broj korisnika po vrsti predbilježbe">
      <div className="statistics-chart">
        <DoughnutChart
          options={subscriptionChartOptions}
          data={[
            usersByCourses,
            usersByManifestations,
            usersByDemonstrations,
            usersByFarmingRecommendations,
            usersByNews,
            usersByAdvices,
          ]}
          label="Ukupno"
          value={total.toString()}
        />
      </div>
      <StatisticsContent>
        <div className="stats-row">
          <div className="dot dot--terracota" />
          <p className="stats-text">Obavijesti o tečajevima</p>
        </div>
        <div className="stats-value">{usersByCourses}</div>
        <div className="stats-row">
          <div className="dot dot--rajah" />
          <p className="stats-text">Najave manifestacija</p>
        </div>
        <div className="stats-value">{usersByManifestations}</div>
        <div className="stats-row">
          <div className="dot dot--scooter" />
          <p className="stats-text">Obavijesti o demonstracijama</p>
        </div>
        <div className="stats-value">{usersByDemonstrations}</div>
        <div className="stats-row">
          <div className="dot dot--royalBlue" />
          <p className="stats-text">Preporuka o zaštiti bilja</p>
        </div>
        <div className="stats-value">{usersByFarmingRecommendations}</div>
        <div className="stats-row">
          <div className="dot dot--lavender" />
          <p className="stats-text">Vijesti</p>
        </div>
        <div className="stats-value">{usersByNews}</div>
        <div className="stats-row">
          <div className="dot dot--pear" />
          <p className="stats-text">Savjeti</p>
        </div>
        <div className="stats-value">{usersByAdvices}</div>
      </StatisticsContent>
    </StatisticsContainer>
  );
};
