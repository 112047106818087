import { resetPassword, verifyResetPasswordCode } from "services";
import { AppThunk } from "store";

import {
  verifyPasswordResetLinkRequest,
  verifyPasswordResetLinkError,
  verifyPasswordResetLinkSuccess,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
} from "./reducer";

export const verfiyPasswordLink = (code: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(verifyPasswordResetLinkRequest());

    await verifyResetPasswordCode(code);
    dispatch(verifyPasswordResetLinkSuccess({ code }));
  } catch (error) {
    //const [first] = error.response.data.statuses;
    dispatch(verifyPasswordResetLinkError());
  }
};

export const updatePassword = (
  code: string,
  password: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(changePasswordRequest());

    const response = await resetPassword(code, password);
    const [first] = response.data.statuses;
    dispatch(changePasswordSuccess({ successMessage: first.message }));
  } catch (error: any) {
    const [first] = error.response.data.statuses;
    dispatch(changePasswordError({ errorMessage: first.message }));
  }
};
