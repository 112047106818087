import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activateAccount, getUrlCode } from "modules/auth";
import { ReactComponent as Spinner } from "assets/icons/spinner.svg";
import { AppRoutes } from "routing";
import { RootState } from "store";
import { useTitle } from "modules/common";

export const ActivateAccount: React.FC = () => {
  useTitle('Aktivacija računa - eSavjetnik');
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const accountActivation = useSelector(
    (state: RootState) => state.activateAccount
  );
  const { activateAccountError, activateAccountSuccess } = accountActivation;

  useEffect(() => {
    const code = getUrlCode(search);
    if (!code) {
      history.push({
        pathname: AppRoutes.SignIn,
      });
    } else {
      dispatch(activateAccount(code));
    }
  }, [dispatch, history, search]);

  useEffect(() => {
    if (activateAccountError || activateAccountSuccess) {
      history.push({
        pathname: AppRoutes.SignIn,
      });
    }
  }, [activateAccountError, activateAccountSuccess, history]);

  return (
    <div className="activation-container">
      <Spinner className="spinner spinner--large" />
    </div>
  );
};
