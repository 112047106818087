import { useState, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";

const useDebounced = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    const debouncedSetter = useMemo(
        () =>
            debounce(newValue => {
                setDebouncedValue(newValue);
            }, delay),
        [delay]
    );

    useEffect(() => {
        debouncedSetter(value);
        return () => {
            debouncedSetter.cancel();
        };
    }, [value, debouncedSetter]);

    return debouncedValue;
};

export default useDebounced;
