import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  activateAccountError: boolean;
  errorMessage: string;
  successMessage: string;
  activateAccountSuccess: boolean;
}

interface ActivateAccountError {
  errorMessage: string;
}

interface ActivateAccountSuccess {
  successMessage: string;
}

const initialState: State = {
  isLoading: false,
  activateAccountError: false,
  activateAccountSuccess: false,
  errorMessage: "",
  successMessage: "",
};
const activateAccountSlice = createSlice({
  name: "activateAccount",
  initialState: initialState,
  reducers: {
    activateAccountRequest: (state) => {
      state.activateAccountError = false;
      state.activateAccountSuccess = false;
      state.isLoading = true;
    },
    activateAccountSuccess: (
      state,
      action: PayloadAction<ActivateAccountSuccess>
    ) => {
      state.isLoading = false;
      state.activateAccountSuccess = true;
      state.successMessage = action.payload.successMessage;
    },
    activateAccountError: (
      state,
      action: PayloadAction<ActivateAccountError>
    ) => {
      state.isLoading = false;
      state.activateAccountError = true;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = activateAccountSlice;

export const {
  activateAccountError,
  activateAccountRequest,
  activateAccountSuccess,
} = actions;

export const activateAccountReducer = activateAccountSlice.reducer;
