import { ReactComponent as DetailsIcon } from "assets/icons/details.svg";
import { AdministrationTable, OptionType, Pagination } from "modules/common";
import { useMemo } from "react";
import { SortingRule } from "react-table";
import { OuterModule, OuterModuleResponse } from "services";

export interface IOuterModulesTable {
    outerModules?: OuterModuleResponse;
    isLoading?: boolean;
    setSortBy?: (sortBy: SortingRule<object>) => void;
    page?: number;
    pageSize?: number;
    onPageSizeChange?: (value: number) => void;
    onPageChange?: (page: number) => void;
    onRowSelect?: (row: OuterModule) => void;
    className?: string;
}

const DefaultPageSize = {
    value: "10",
    label: "10",
};

const MAX_URL_LENGTH = 110;

const defaultTableColumns: any[] = [
    {
        Header: "R. BROJ",
        accessor: "orderNumber",
        width: 2,
    },
    {
        Header: "NAZIV MODULA",
        accessor: "name",
        width: 6,
        Cell: ({ row }: any) => {
            const { original } = row;
            return <span className="modal-name">{original.name}</span>;
        },
    },
    {
        Header: "STATUS",
        accessor: "isActive",
        disableSortBy: true,
        width: 2,
        Cell: ({ row }: any) => {
            const { original } = row;
            return <span>{original.isActive ? "Aktivan" : "Neaktivan"}</span>;
        },
    },
    {
        Header: "URL",
        accessor: "url",
        disableSortBy: true,
        width: 10,
        Cell: ({ row }: any) => {
            const { original } = row;
            const elipsis = original.url.length > MAX_URL_LENGTH ? "..." : "";
            return <a href={original.url}>{original.url.slice(0, MAX_URL_LENGTH) + elipsis}</a>;
        },
    },
];

export const OuterModulesTable = ({
    outerModules,
    isLoading,
    setSortBy,
    page,
    pageSize,
    onPageSizeChange,
    onPageChange,
    onRowSelect,
    className,
}: IOuterModulesTable) => {
    const tableColumns = useMemo(() => {
        let columns = defaultTableColumns;
        if (!setSortBy) columns = columns.map(column => ({ ...column, disableSortBy: true }));
        if (onRowSelect) {
            const infoColumn = {
                id: "info",
                disableSortBy: true,
                width: 1,
                className: "details-icon-cell",
                Cell: ({ row }: any) => {
                    return (
                        <DetailsIcon
                            className="details-icon"
                            onClick={onRowSelect && (() => onRowSelect(row.original))}
                        />
                    );
                },
            };

            columns = [...columns, infoColumn];
        }

        return columns;
    }, [defaultTableColumns]);

    const activePageSize = useMemo(() => {
        return pageSize
            ? { value: pageSize.toString(), label: pageSize.toString() }
            : DefaultPageSize;
    }, [pageSize]);

    const classNameString = useMemo(() => {
        return `outer-modules-table ${className ?? ""}`;
    }, [className]);

    const handlePageSizeChange = (option: OptionType | null) => {
        onPageSizeChange && onPageSizeChange(parseInt(option?.value ?? DefaultPageSize.value));
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const target = event.currentTarget ?? event.target;
        onPageChange && onPageChange(Number(parseInt(target.id)));
    };

    const handleNexPageClick = () => {
        onPageChange && onPageChange(page ? page + 1 : 1);
    };

    const handlePrevPageClick = () => {
        onPageChange && onPageChange(page ? page - 1 : 1);
    };

    return (
        <div className={classNameString}>
            <AdministrationTable
                flex
                data={outerModules?.items ?? []}
                columns={tableColumns}
                loading={isLoading}
                setSortBy={setSortBy}
            />

            {onPageChange && outerModules && outerModules.items.length > 0 && (
                <Pagination
                    className="mt-24"
                    currentPage={page ?? 1}
                    numberOfPages={Math.ceil(outerModules.total / parseInt(activePageSize.value))}
                    pageNeighbours={1}
                    pageSize={activePageSize}
                    onPageSizeChange={handlePageSizeChange}
                    handlepageClick={handlePageClick}
                    next={handleNexPageClick}
                    prev={handlePrevPageClick}
                />
            )}
        </div>
    );
};
