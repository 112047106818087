import { RoleType } from "modules/common";

export const isCitizen = (role: number) => role === RoleType.Citizen;
export const isFarmer = (role: number) => role === RoleType.Farmer;
// export const isFisherman = (role: number) => role === RoleType.Fisherman;
export const isEmployeeMp = (role: number) => role === RoleType.EmployeeMp;

export const isEmployeeApprrr = (role: number) =>
  role === RoleType.EmployeeApprrr;

export const isEmployeeAdviser = (role: number) =>
  role === RoleType.EmployeeAdviser;

export const isMpAdminFarmer = (role: number) =>
  role === RoleType.MpAdminFarmer;

// export const isMpAdminFisherman = (role: number) =>
//   role === RoleType.MpAdminFisherman;

export const isMpSuperAdmin = (role: number) => role === RoleType.MpSuperAdmin;
export const isAdviserAdminFarmer = (role: number) =>
  role === RoleType.AdviserAdminFarmer;

// export const isAdviserAdminFisherman = (role: number) =>
//   role === RoleType.AdviserAdminFisherman;

export const isAdviserSuperAdmin = (role: number) =>
  role === RoleType.AdviserSuperAdmin;
