export enum AppRoutes {
  SignIn = "/",
  SignUp = "/registracija",
  ForgotPassword = "/zaboravljena-lozinka",
  ChangePassword = "/nova-lozinka",
  ActivateAccount = "/aktivacija-racuna",
  Education = "/edukacija",
  News = "/vijesti",
  AgroclimateStations = "/agroklimatske-stanice",
  Agrometeorology = "/agrometeorologija",
  Records = "/evidencije",
  Advisors = "/savjetnici",
  PlantProtection = "/zastita-bilja",
  UserActivity = "/aktivnost-korisnika",
  UserAdministration = "/administracija-korisnika",
  Profile = "/profil",
  SystemAdministration = "/administracija-sustava",
  NotAuthorized = "/unauthorized",
  Presentations = "/prezentacije",
  Communication = "/komunikacija-s-korisnicima",
  ModulesAdministration = "/administracija-modula",
  ModuleVersionsAdministration = "/administracija-verzija-modula",
}