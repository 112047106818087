import { userRoles } from "modules/common/constants";
import React from "react";
import { Filter, Checkbox } from "../";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isActive: boolean;
  showDropdown: boolean;
  value: number[];
}

export type RoleFilterState = string[];

export const RoleFilter: React.FC<Props> = ({
  onChange,
  onClick,
  onClear,
  isActive,
  showDropdown,
  value,
}) => {
  return (
    <Filter
      isActive={isActive}
      showDropdown={showDropdown}
      onClear={onClear}
      onClick={onClick}
      text="Uloga"
    >
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.Citizen.value.toString()}
          checked={value.includes(userRoles.Citizen.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Citizen.label}
          text={
            <div className="filter__item__text">{userRoles.Citizen.label}</div>
          }
        />
      </div>
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.Farmer.value.toString()}
          checked={value.includes(userRoles.Farmer.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Farmer.label}
          text={
            <div className="filter__item__text">{userRoles.Farmer.label}</div>
          }
        />
      </div>
      {/* <div>
        <Checkbox
          type="checkbox"
          value={userRoles.Fisherman.value.toString()}
          checked={value.includes(userRoles.Fisherman.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Fisherman.label}
          text={
            <div className="filter__item__text">
              {userRoles.Fisherman.label}
            </div>
          }
        />
      </div> */}
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.EmployeeMp.value.toString()}
          checked={value.includes(userRoles.EmployeeMp.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.EmployeeMp.label}
          text={
            <div className="filter__item__text">
              {userRoles.EmployeeMp.label}
            </div>
          }
        />
      </div>
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.EmployeeApprrr.value.toString()}
          checked={value.includes(userRoles.EmployeeApprrr.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.EmployeeApprrr.label}
          text={
            <div className="filter__item__text">
              {userRoles.EmployeeApprrr.label}
            </div>
          }
        />
      </div>
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.MpAdminFarmer.value.toString()}
          checked={value.includes(userRoles.MpAdminFarmer.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MpAdminFarmer.label}
          text={
            <div className="filter__item__text">
              {userRoles.MpAdminFarmer.label}
            </div>
          }
        />
      </div>
      {/* <div>
        <Checkbox
          type="checkbox"
          value={userRoles.MpAdminFisherman.value.toString()}
          checked={value.includes(userRoles.MpAdminFisherman.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MpAdminFisherman.label}
          text={
            <div className="filter__item__text">
              {userRoles.MpAdminFisherman.label}
            </div>
          }
        />
      </div> */}
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.MpSuperAdmin.value.toString()}
          checked={value.includes(userRoles.MpSuperAdmin.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MpSuperAdmin.label}
          text={
            <div className="filter__item__text">
              {userRoles.MpSuperAdmin.label}
            </div>
          }
        />
      </div>
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.AdviserAdminFarmer.value.toString()}
          checked={value.includes(userRoles.AdviserAdminFarmer.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.AdviserAdminFarmer.label}
          text={
            <div className="filter__item__text">
              {userRoles.AdviserAdminFarmer.label}
            </div>
          }
        />
      </div>
      {/* <div>
        <Checkbox
          type="checkbox"
          value={userRoles.AdviserAdminFisherman.value.toString()}
          checked={value.includes(userRoles.AdviserAdminFisherman.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.AdviserAdminFisherman.label}
          text={
            <div className="filter__item__text">
              {userRoles.AdviserAdminFisherman.label}
            </div>
          }
        />
      </div> */}
      <div>
        <Checkbox
          type="checkbox"
          value={userRoles.AdviserSuperAdmin.value.toString()}
          checked={value.includes(userRoles.AdviserSuperAdmin.value)}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.AdviserSuperAdmin.label}
          text={
            <div className="filter__item__text">
              {userRoles.AdviserSuperAdmin.label}
            </div>
          }
        />
      </div>
    </Filter>
  );
};
