import { ChartOptions } from "chart.js";

export const chartOptions: ChartOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  scales: {
    xAxes: [
      {
        display: false,
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          min: 0, // Edit the value according to what you need
        },
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: false,
      },
    ],
  },
  elements: {
    rectangle: {
      borderWidth: 0,
      backgroundColor: ["#377CC1", "#F3B618"],
    },
  },
  responsive: false,
  maintainAspectRatio: false,
};
