import { UserActivity } from "services";
import {
  mapApplicationAction,
  mapApplicationType,
  mapSingleRole,
} from "./mappingUtils";

export const mapUserActivites = (activities: UserActivity[]) => {
  return activities.map((a) => {
    const role = mapSingleRole(a.userRole);
    const action = mapApplicationAction(a.userAction);
    const application = mapApplicationType(a.application);
    return {
      ...a,
      userAction: action?.label ?? "",
      userRole: role?.label ?? "",
      application: application?.label ?? "",
    };
  });
};
