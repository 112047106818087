import * as React from "react";

import { SkeletonText } from "../SkeletonText";

interface Props {}

export const UserInfoLoading: React.FC<Props> = () => {
  return (
    <div className="userInfo--loading">
      <div className="userInfo-section business-container">
        <div className={`f-column`}>
          <SkeletonText className="mb-12" width="90%" height="20px" />
          <SkeletonText width="70%" height="20px" />
        </div>
        <div className="f-column">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          <SkeletonText width="70%" height="20px" />
        </div>
      </div>
      <div className="userInfo-separator" />
      <div className="userInfo-section userInfo-grid">
        <div className="f-column grid-column-1">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          <SkeletonText className="mb-12" width="70%" height="20px" />
          <SkeletonText width="70%" height="20px" />
        </div>
        <div className="f-column grid-row-2">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          <SkeletonText className="mb-12" width="70%" height="20px" />
        </div>
        <div className="f-column grid-column-2">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          <SkeletonText className="mb-12" width="70%" height="20px" />
        </div>
        <div className="f-column">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          <SkeletonText className="mb-12" width="70%" height="20px" />
        </div>
      </div>
      <div className="userInfo-separator" />
      <div className="app-info-section userInfo-grid">
        <div className="f-column">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          {[...new Array(4)].map((_item, index) => (
            <SkeletonText
              key={index}
              className="mb-12"
              width="70%"
              height="20px"
            />
          ))}
        </div>
        <div className="f-column">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          {[...new Array(4)].map((_item, index) => (
            <SkeletonText
              key={index}
              className="mb-12"
              width="70%"
              height="20px"
            />
          ))}
        </div>
      </div>
      <div className="userInfo-separator" />
      <div className="app-info-section">
        <div className="userInfo-roles">
          <SkeletonText className="mb-12" width="90%" height="20px" />
          {[...new Array(4)].map((_item, index) => (
            <SkeletonText
              key={index}
              className="mb-12"
              width="70%"
              height="20px"
            />
          ))}
        </div>
      </div>
      <div className="userInfo-separator" />
      <div className="deactivate-section">
        <SkeletonText className="mr-32" width="60%" height="20px" />
        <SkeletonText width="60%" height="20px" />
      </div>
    </div>
  );
};
