import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import ReactGA from "react-ga4";

ReactGA.initialize("G-54H57RZDM6");;
ReactGA.send("eSavjetnik web init");
ReactGA.event({
    category: "shopping",
    action: "eSavjetnik web event",
    label: "name",
});


ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
