import React from "react";

interface Props {
  className?: string;
}
export const StatisticsContent: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`statistics-content ${className ?? ""}`}>{children}</div>
  );
};
