import React from 'react';
import AuthImage from 'assets/images/unsplash.jpg';
import { ToastContainer } from 'react-toastify';
interface Props {
  header: React.ReactNode;
  footer: React.ReactNode;
}
export const AccountLayout: React.FC<Props> = ({
  children,
  footer,
  header,
}) => {
  return (
    <div className="container">
      <div className="grid">
        <img className="image grid-image" src={AuthImage} alt="" />
        <div className="grid-content">
          {header}
          <main>{children}</main>
          {footer}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};
