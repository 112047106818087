import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  errorMessage: string;
  items: string[];
}

const initialState: State = {
  isLoading: false,
  errorMessage: "",
  items: [],
};

const expertAreasSlice = createSlice({
  name: "ExpertAreas",
  initialState: initialState,
  reducers: {
    getExpertAreasRequest: (state) => {
      state.isLoading = true;
    },
    getExpertAreasSuccess: (
      state,
      action: PayloadAction<{ expertAreas: string[] }>
    ) => {
      state.isLoading = false;
      state.items = action.payload.expertAreas;
    },
    getExpertAreasError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = expertAreasSlice;

export const {
  getExpertAreasRequest,
  getExpertAreasSuccess,
  getExpertAreasError,
} = actions;

export const expertAreasReducer = expertAreasSlice.reducer;
