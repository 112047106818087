import React from "react";
import { StatisticsContainer, StatisticsContent } from "modules/common";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as SmsIcon } from "assets/icons/sms.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";

interface Props {
  numberOfEmailUsers: number;
  numberOfSmsUsers: number;
  numberOfWhatsAppUsers: number;
  numberOfViberUsers: number;
}
export const NotificationsStats: React.FC<Props> = ({
  numberOfEmailUsers,
  numberOfSmsUsers,
  numberOfViberUsers,
  numberOfWhatsAppUsers,
}) => {
  return (
    <StatisticsContainer title="Broj korisnika po način obavještavanja">
      <StatisticsContent className="notifications-stats">
        <div className="notification-stats-row">
          <EmailIcon className="notification-stats-icon" />
          <p className="notification-stats-text">Email</p>
        </div>
        <div className="notification-stats-value">{numberOfEmailUsers}</div>
        <div className="notification-stats-row">
          <SmsIcon className="notification-stats-icon" />
          <p className="notification-stats-text">SMS</p>
        </div>
        <div className="notification-stats-value">{numberOfSmsUsers}</div>
        <div className="notification-stats-row">
          <MessengerIcon className="notification-stats-icon" />
          <p className="notification-stats-text">WhatsApp</p>
        </div>
        <div className="notification-stats-value">{numberOfWhatsAppUsers}</div>
        <div className="notification-stats-row">
          <MessengerIcon className="notification-stats-icon" />
          <p className="notification-stats-text">Viber</p>
        </div>
        <div className="notification-stats-value">{numberOfViberUsers}</div>
      </StatisticsContent>
    </StatisticsContainer>
  );
};
