import React from "react";
import { ReactComponent as ResendIcon } from "assets/icons/resend.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/success.svg";
import { ReactComponent as Spinner } from "assets/icons/spinner.svg";
import { Button } from "../../../common";

interface Props {
  isLoading: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export const EmailSent: React.FC<Props> = ({ isLoading, onClick }) => {
  return (
    <div>
      <h1 className="email-sent-heading mt-72">
        Provjerite svoj email sandučić
      </h1>
      <div className="email-sent-container">
        <div className="email-sent-icon">
          <SuccessIcon />
        </div>
        <p className="email-sent-description">
          Poveznica za resetiranje lozinke vam je poslana na vašu email adresu.
          Ukoliko ne vidite email kroz par minuta, provjerite i spam, social ili
          junk datoteke unutar inboxa.
        </p>
      </div>
      {isLoading ? (
        <div className="ml-80">
          <Spinner className="spinner" />
        </div>
      ) : (
        <Button
          onClick={onClick}
          type="button"
          variant="primary"
          size="medium"
          isDisabled={false}
        >
          <ResendIcon className="btn--right-icon" />
          <span>Pošaljite email ponovno</span>
        </Button>
      )}
    </div>
  );
};
