import { Button } from "../Button";
import { Modal, ModalFooter, ModalHeader } from "../Modal";

export interface IConfirmationModal {
    isActive: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    title?: string;
    content?: React.ReactNode;
    confirmText?: string;
    cancelText?: string;
}

export const ConfirmationModal = ({
    isActive,
    onConfirm,
    onCancel,
    content,
    title = "Jeste li sigurni?",
    confirmText = "Da",
    cancelText = "Ne",
}: IConfirmationModal) => {
    return (
        <Modal
            isActive={isActive}
            onClose={onCancel}
            modalHeader={<ModalHeader onClose={onCancel} title={title} />}
            modalFooter={
                <ModalFooter>
                    <Button
                        type="button"
                        size="small"
                        variant="primary-ghost"
                        isDisabled={false}
                        onClick={onConfirm}
                    >
                        <span>{confirmText}</span>
                    </Button>

                    <Button
                        type="button"
                        size="small"
                        variant="secondary-ghost"
                        isDisabled={false}
                        onClick={onCancel}
                    >
                        <span>{cancelText}</span>
                    </Button>
                </ModalFooter>
            }
        >
            {content && <div className="confirmation-modal__content">{content}</div>}
        </Modal>
    );
};
