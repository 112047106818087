import {
  Button,
  Loading,
  Modal,
  ModalFooter,
  ModalHeader,
} from 'modules/common';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  EducationCarrierForm,
  EducationMembersForm,
  EducationModalFilter,
} from '.';
import { EducationFormInfo } from '../models';
import { educationSignUp, getHolderFarm, getMembersFarm } from '../store';
import { UserTypes } from 'modules/common';
import { sessionCache } from 'modules/auth';

const isFarmer = (userType: number) => UserTypes.Farmer === userType;

interface Props {
  isVisible: boolean;
  toggleModal: () => void;
  semisId: number | undefined;
}

export const EducationSignupModal: React.FC<Props> = ({
  isVisible,
  toggleModal,
  semisId,
}) => {
  const { profile } = useSelector((state: RootState) => state.profile);
  const user = sessionCache().getSession();
  const userType = user?.userType.value ?? profile?.userType?.value;
  const { isLoading } = useSelector((state: RootState) => state.educations);
  const dispatch = useDispatch();
  const [isSubmitAndAddNew, setIsSubmitAndAddNew] = useState(false);
  const { farmHolder, firstFarmMember, farmMembers } = useSelector(
    (state: RootState) => state.farmHolder
  );
  const Filters =
    isFarmer(userType ?? UserTypes.Farmer) && farmMembers
      ? ['Nositelj', 'Član']
      : isFarmer(userType ?? UserTypes.Farmer) && !farmMembers
      ? ['Nositelj']
      : ['Član'];

  const [tabFilter, setTabFilter] = useState<string>(Filters[0]);
  useEffect(() => {
    if (!isFarmer(userType ?? UserTypes.Farmer)) return;
    dispatch(getHolderFarm());
    dispatch(getMembersFarm());
  }, []);

  const [formData, setFormData] = useState<{
    carrier: EducationFormInfo;
    member: EducationFormInfo;
  }>({
    carrier: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      oib: farmHolder?.farmHolder.oib || '',
      email: profile?.email || null,
      mobileNumber: profile?.mobileNumber || null,
      mibpg: profile?.mibpg || '',
    },
    member: {
      firstName: firstFarmMember?.firstName || '',
      lastName: firstFarmMember?.lastName || '',
      oib: firstFarmMember?.oib || '',
      email: null,
      mobileNumber: null,
      mibpg: '',
    },
  });
  const [carrierFormValid, setCarrierFormValid] = useState(false);
  const [memberFormValid, setMemberFormValid] = useState(false);

  function submit() {
    if (!semisId) {
      return;
    }

    if (carrierFormValid && tabFilter === 'Nositelj') {
      const carrierContact =
        formData.carrier.mobileNumber && formData.carrier.mobileNumber.length
          ? formData.carrier.mobileNumber
          : null;
      const carrierEmail =
        formData.carrier.email && formData.carrier.email.length
          ? formData.carrier.email
          : null;

      dispatch(
        educationSignUp({
          contact: carrierContact,
          email: carrierEmail,
          firstName: formData.carrier.firstName,
          lastName: formData.carrier.lastName,
          oib: formData.carrier.oib,
          semisEducationId: semisId,
          mibpg: formData.carrier.mibpg,
        })
      );
    }

    if (memberFormValid && tabFilter === 'Član') {
      const memberContact =
        formData.member.mobileNumber && formData.member.mobileNumber.length
          ? formData.member.mobileNumber
          : null;
      const memberEmail =
        formData.member.email && formData.member.email.length
          ? formData.member.email
          : null;

      dispatch(
        educationSignUp({
          contact: memberContact,
          email: memberEmail,
          firstName: formData.member.firstName,
          lastName: formData.member.lastName,
          oib: formData.member.oib,
          semisEducationId: semisId,
          mibpg: formData.carrier.mibpg,
        })
      );
    }

    setIsSubmitAndAddNew(true);
    setFormData({
      carrier: formData.carrier,
      member: {
        firstName: firstFarmMember?.firstName || '',
        lastName: firstFarmMember?.lastName || '',
        oib: firstFarmMember?.oib || '',
        email: null,
        mobileNumber: null,
        mibpg: '',
      },
    });
  }

  function submitAndClose() {
    if (!semisId) {
      return;
    }

    if (carrierFormValid && tabFilter === 'Nositelj') {
      const carrierContact =
        formData.carrier.mobileNumber && formData.carrier.mobileNumber.length
          ? formData.carrier.mobileNumber
          : null;
      const carrierEmail =
        formData.carrier.email && formData.carrier.email.length
          ? formData.carrier.email
          : null;

      dispatch(
        educationSignUp({
          contact: carrierContact,
          email: carrierEmail,
          firstName: formData.carrier.firstName,
          lastName: formData.carrier.lastName,
          oib: formData.carrier.oib,
          semisEducationId: semisId,
          mibpg: formData.carrier.mibpg,
        })
      );
    }

    if (memberFormValid && tabFilter === 'Član') {
      const memberContact =
        formData.member.mobileNumber && formData.member.mobileNumber.length
          ? formData.member.mobileNumber
          : null;
      const memberEmail =
        formData.member.email && formData.member.email.length
          ? formData.member.email
          : null;

      dispatch(
        educationSignUp({
          contact: memberContact,
          email: memberEmail,
          firstName: formData.member.firstName,
          lastName: formData.member.lastName,
          oib: formData.member.oib,
          semisEducationId: semisId,
          mibpg: formData.member.mibpg,
        })
      );
    }

    setIsSubmitAndAddNew(false);
    setFormData({
      carrier: formData.carrier,
      member: {
        firstName: firstFarmMember?.firstName || '',
        lastName: firstFarmMember?.lastName || '',
        oib: firstFarmMember?.oib || '',
        email: null,
        mobileNumber: null,
        mibpg: '',
      },
    });
    toggleModal();
  }

  function onCarrierChange(data: EducationFormInfo) {
    console.log('carrier', data);
    setFormData({ carrier: data, member: formData.member });
  }

  function onMemberChange(data: EducationFormInfo) {
    console.log('member', data);
    setFormData({ carrier: formData.carrier, member: data });
  }

  function onClose() {
    setIsSubmitAndAddNew(false);
    toggleModal();
  }

  useEffect(() => {
    setFormData({
      carrier: {
        firstName: profile?.firstName || '',
        lastName: profile?.lastName || '',
        oib: farmHolder?.farmHolder.oib || '',
        email: null,
        mobileNumber: null,
        mibpg: profile?.mibpg || '',
      },
      member: {
        firstName: firstFarmMember?.firstName || '',
        lastName: firstFarmMember?.lastName || '',
        oib: firstFarmMember?.oib || '',
        email: null,
        mobileNumber: null,
        mibpg: '',
      },
    });
  }, [farmHolder?.farmHolder, profile, isVisible]);

  return (
    <Modal
      isActive={isVisible}
      onClose={onClose}
      modalHeader={
        <ModalHeader
          onClose={onClose}
          title="Prijava za tečaj"
          isLoading={false}
        />
      }
      modalFooter={
        <ModalFooter>
          {isLoading ? (
            <Loading className="loading-container--sml" />
          ) : (
            <>
              <Button
                type="submit"
                size="small"
                variant="primary"
                isDisabled={!carrierFormValid && !memberFormValid}
                onClick={submit}
              >
                <span>Spremi i dodaj novu prijavu</span>
              </Button>
              <Button
                type="submit"
                size="small"
                variant="primary-ghost"
                isDisabled={!carrierFormValid && !memberFormValid}
                onClick={submitAndClose}
              >
                <span>Spremi i završi prijavu</span>
              </Button>
            </>
          )}
        </ModalFooter>
      }
    >
      <EducationModalFilter
        filters={Filters}
        setFilter={setTabFilter}
        activeFilter={tabFilter}
      />

      {isFarmer(userType ?? UserTypes.Farmer) ? (
        tabFilter === 'Nositelj' ? (
          <EducationCarrierForm
            onChange={onCarrierChange}
            data={formData.carrier}
            formValid={setCarrierFormValid}
            isLoading={isLoading}
          />
        ) : (
          <EducationMembersForm
            onChange={onMemberChange}
            data={formData.member}
            formValid={setMemberFormValid}
            isLoading={isLoading}
            isSubmitAndAddNew={isSubmitAndAddNew}
          />
        )
      ) : (
        <EducationMembersForm
          onChange={onMemberChange}
          data={formData.member}
          formValid={setMemberFormValid}
          isLoading={isLoading}
          isSubmitAndAddNew={isSubmitAndAddNew}
        />
      )}
    </Modal>
  );
};
