import React from "react";
import {
  DoughnutChart,
  regisrationChartOptions,
  StatisticsContainer,
  StatisticsContent,
} from "modules/common";

interface Props {
  numberOfFarmers: number;
  numberOfFishermans: number;
  numberOfCitizens: number;
  total: number;
}
export const RegistrationStats: React.FC<Props> = ({
  numberOfCitizens,
  numberOfFarmers,
  numberOfFishermans,
  total,
}) => {
  return (
    <StatisticsContainer title="Broj registriranih korisnika">
      <div className="statistics-chart">
        <DoughnutChart
          options={regisrationChartOptions}
          data={[numberOfFarmers, numberOfFishermans, numberOfCitizens]}
          label="Ukupno"
          value={total.toString()}
        />
      </div>
      <StatisticsContent>
        <div className="stats-row">
          <div className="dot dot--terracota" />
          <p className="stats-text">Poljoprivrednika</p>
        </div>
        <div className="stats-value">{numberOfFarmers}</div>
        <div className="stats-row">
          <div className="dot dot--royalBlue" />
          <p className="stats-text">Ribara</p>
        </div>
        <div className="stats-value">{numberOfFishermans}</div>
        <div className="stats-row">
          <div className="dot dot--pear" />
          <p className="stats-text">Građana</p>
        </div>
        <div className="stats-value">{numberOfCitizens}</div>
      </StatisticsContent>
    </StatisticsContainer>
  );
};
