import React from "react";
import { ReactComponent as Spinner } from "assets/icons/spinner.svg";

interface Props {
  className?: string;
}
export const Loading: React.FC<Props> = ({ className }) => (
  <div className={`loading-container ${className ?? ""}`}>
    <Spinner className="spinner" />
  </div>
);
