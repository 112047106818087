import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import add from "date-fns/add";

const MONTHS = [
  { label: "Siječanj", value: 0 },
  { label: "Veljača", value: 1 },
  { label: "Ožujak", value: 2 },
  { label: "Travanj", value: 3 },
  { label: "Svibanj", value: 4 },
  { label: "Lipanj", value: 5 },
  { label: "Srpanj", value: 6 },
  { label: "Kolovoz", value: 7 },
  { label: "Rujan", value: 8 },
  { label: "Listopad", value: 9 },
  { label: "Studeni", value: 10 },
  { label: "Prosinac", value: 11 },
];

const findMonth = (val: number) => MONTHS.find((m) => m.value === val);

export function Navbar(props: any) {
  const currentDate = `${findMonth(getMonth(props.month))?.label} ${getYear(
    props.month
  )}`;

  const nextDate = `${
    findMonth(getMonth(add(props.month, { months: 1 })))?.label
  } ${getYear(props.nextMonth)}`;
  return (
    <div className={`${props.className}`}>
      <ArrowLeftIcon
        className="day-picker-control"
        onClick={() => props.onPreviousClick()}
      />
      <div>
        <span className="day-picker-month mr-40">{currentDate}</span>
        <span className="day-picker-month">{nextDate}</span>
      </div>
      <ArrowRightIcon
        className="day-picker-control"
        onClick={() => props.onNextClick()}
      />
    </div>
  );
}
