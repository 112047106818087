import React from "react";
import Mplogo from "assets/images/LogoMP.jpg";
interface Props {
  className?: string;
}
export const AccountHeader: React.FC<Props> = ({ className }) => {
  return (
    <header className={`${className ?? ""}`}>
      <img src={Mplogo} alt="" />
    </header>
  );
};
