import React from "react";
import { ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";

interface Props {
  label: string;
  value: string;
  data: number[];
  options: ChartOptions;
}
export const DoughnutChart: React.FC<Props> = ({
  label,
  value,
  data,
  options,
}) => {
  return (
    <div className="chart-container">
      <Doughnut
        data={{
          datasets: [
            {
              data: data,
            },
          ],
        }}
        width={124}
        height={124}
        options={options}
      />
      <div className="chart-info">
        <strong className="chart-total">{value}</strong>
        <p className="chart-label">{label}</p>
      </div>
    </div>
  );
};
