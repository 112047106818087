import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EducationApplicationResponse,
  EducationByIdResponse,
  EducationListItems,
  EducationResponse,
  StatusResponse,
} from "services";

interface State {
  isLoading: boolean;
  errorMessage: string | undefined;
  educations: EducationResponse | null;
  appliedEducations: EducationResponse | null;
  listenedEducations: EducationResponse | null;
  educationById: EducationByIdResponse | null;
  educationApplication?: EducationApplicationResponse;
  educationApplicationError?: boolean;
  educationReminder?: StatusResponse;
  educationReminderError?: boolean;
  educationsWithReminders?: EducationListItems;
  allReminders?: EducationListItems;
}

const initialState: State = {
  isLoading: false,
  errorMessage: undefined,
  educations: null,
  appliedEducations: null,
  listenedEducations: null,
  educationById: null,
  educationApplication: undefined,
  educationApplicationError: undefined,
  educationReminder: undefined,
  educationReminderError: false,
  educationsWithReminders: undefined,
  allReminders: undefined,
};

const educationsSlice = createSlice({
  name: "Educations",
  initialState: initialState,
  reducers: {
    getEducationsRequest: (state) => {
      state.isLoading = true;
    },
    getEducationsSuccess: (
      state,
      action: PayloadAction<{ educations: EducationResponse }>
    ) => {
      state.isLoading = false;
      state.educations = action.payload.educations;
    }, 
    getAppliedEducationsSuccess: (
      state,
      action: PayloadAction<{ educations: EducationResponse }>
    ) => {
      state.isLoading = false;
      state.appliedEducations = action.payload.educations;
    }, 
    getListenedEducationsSuccess: (
      state,
      action: PayloadAction<{ educations: EducationResponse }>
    ) => {
      state.isLoading = false;
      state.listenedEducations = action.payload.educations;
    }, 
    getEducationByIdSuccess: (
      state,
      action: PayloadAction<{ education: EducationByIdResponse }>
    ) => {
      state.isLoading = false;
      state.educationById = action.payload.education;
    },
    getEducationsError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
    getEducationApplicationSuccess: (
      state,
      action: PayloadAction<{ response: EducationApplicationResponse }>
    ) => {
      state.isLoading = false;
      state.educationApplication = action.payload.response;
    },
    getEducationsApplicationError: (
      state,
      action: PayloadAction<{ error: boolean }>
    ) => {
      state.isLoading = false;
      state.educationApplicationError = action.payload.error;
    },
    getEducationReminderSuccess: (
      state,
      action: PayloadAction<{ response: StatusResponse }>
    ) => {
      state.isLoading = false;
      state.educationReminder = action.payload.response;
    },
    getEducationReminderError: (
      state,
      action: PayloadAction<{ error: boolean }>
    ) => {
      state.isLoading = false;
      state.educationReminderError = action.payload.error;
    },
    getEducationsWithRemindersSuccess: (
      state,
      action: PayloadAction<{ educations?: EducationListItems }>
    ) => {
      state.isLoading = false;
      state.educationsWithReminders = action.payload.educations;
    },
    getEducationsWithReminderError: (
      state,
      action: PayloadAction<{ error: boolean }>
    ) => {
      state.isLoading = false;
      state.educationReminderError = action.payload.error;
    },
    getAllReminders: (
      state,
      action: PayloadAction<{ reminders?: EducationListItems }>
    ) => {
      state.isLoading = false;
      state.allReminders = action.payload.reminders;
    },
  },
});

const { actions } = educationsSlice;

export const {
  getEducationsRequest,
  getEducationsSuccess,
  getAppliedEducationsSuccess,
  getListenedEducationsSuccess,
  getEducationByIdSuccess,
  getEducationsError,
  getEducationApplicationSuccess,
  getEducationsApplicationError,
  getEducationReminderSuccess,
  getEducationReminderError,
  getEducationsWithRemindersSuccess,
  getEducationsWithReminderError,
  getAllReminders,
} = actions;

export const educationsReducer = educationsSlice.reducer;
