export const isOneLowerCaseChar = (input: string) =>
  new RegExp("(?=.*[a-z])").test(input);

export const isOneUpperCaseChar = (input: string) =>
  new RegExp("(?=.*[A-Z])").test(input);

export const isOneNumericChar = (input: string) =>
  new RegExp("(?=.*[0-9])").test(input);

export const isOneSpecialChar = (input: string) =>
  new RegExp("(?=.*[!@#$%^&*.;<>_?])").test(input);

export const isEightCharLong = (input: string) =>
  new RegExp("(?=.{8,})").test(input);

export const isTenCharLong = (input: string) =>
  new RegExp("(?=.{10,})").test(input);

export const isValidEmail = (input: string) =>
  new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$").test(input);

export const isValidMobile = (input: string) =>
  new RegExp(
    "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{3,4}$"
  ).test(input);
