import React from "react";
import { HeaderGroup } from "react-table";

interface Props {
  headerGroups: HeaderGroup<object>[];
}
export const TableHeader: React.FC<Props> = ({ headerGroups }) => {
  return (
    <thead className="table__head">
      {headerGroups.map((headerGroup) => (
        <tr className="" {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column: any) => {
            if (Number(column.id) === 2) {
              return (
                <th
                  className={`module-table__header ${column.className ?? ""}`}
                  {...column.getHeaderProps()}
                >
                  <span className="header-dot-wrapper">
                    <div className="dot dot--primary" />
                    {column.render("Header")}
                  </span>
                </th>
              );
            }

            if (Number(column.id) === 3) {
              return (
                <th
                  className={`module-table__header ${column.className ?? ""}`}
                  {...column.getHeaderProps()}
                >
                  <span className="header-dot-wrapper">
                    <div className="dot dot--warning" />
                    {column.render("Header")}
                  </span>
                </th>
              );
            }
            return (
              <th
                className={`module-table__header ${column.className ?? ""}`}
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
