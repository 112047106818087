import { sendForgotPasswordEmail } from "services";
import { AppThunk } from "store";
import {
  forgotPasswordError,
  forgotPasswordRequest,
  forgotPasswordSuccess,
} from "./reducer";

export const sendPasswordResetEmail = (email: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(forgotPasswordRequest());

    await sendForgotPasswordEmail(email);
    dispatch(forgotPasswordSuccess());
  } catch (error: any) {
    const [first] = error.response.data.statuses;
    dispatch(forgotPasswordError({ errorMessage: first.message }));
  }
};
