import * as React from "react";
import { MappedUserAccount, Notifications, updateUserRoles } from "modules/dashboard";
import { Button } from "../Button";
import { RolesList } from "../RolesList";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Can } from "modules/auth";

interface Props {
    userInfo: MappedUserAccount;
    onDeactivateClick: (uid: string, userType: number) => void;
}

export const UserInfo: React.FC<Props> = ({
    userInfo: {
        uid,
        userType,
        legalEntityName,
        mibpg,
        street,
        streetNumber,
        postalNumber,
        city,
        county,
        country,
        mobileNumber,
        email,
        notificationType,
        infoType,
        roleType,
    },
    onDeactivateClick,
}) => {
    const dispatch = useDispatch();
    const [accountRole, setAccountRole] = useState<number>(roleType);

    const updateRoles = useCallback(
        (id: string, role: number, userType: number) => {
            dispatch(updateUserRoles(id, role, userType));
        },
        [dispatch]
    );

    const handleAccountRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);

        setAccountRole(valueNumb);
        updateRoles(uid, valueNumb, userType?.value ?? 0);
    };

    return (
        <div>
            <div className="userInfo-section business-container">
                <div className="f-column">
                    <span className="userInfo-label">Naziv</span>
                    <span className="business-text">{legalEntityName}</span>
                </div>
                <div className="f-column">
                    <span className="userInfo-label">Mibpg</span>
                    <span className="business-text">{mibpg}</span>
                </div>
            </div>
            <div className="userInfo-separator" />
            <div className="userInfo-section userInfo-grid">
                <div className="f-column grid-column-1">
                    <span className="userInfo-label">Adresa</span>
                    <span className="userInfo-text">
                        {street} {streetNumber}
                    </span>
                    <span className="userInfo-text">
                        {postalNumber} {city}
                    </span>
                </div>
                <div className="f-column grid-row-2">
                    <span className="userInfo-text--lighter">{county}</span>
                    <span className="userInfo-text--lighter">{country}</span>
                </div>
                <div className="f-column grid-column-2">
                    <span className="userInfo-label">Broj mobitela</span>
                    <span className="userInfo-text">{mobileNumber}</span>
                </div>
                <div className="f-column">
                    <span className="userInfo-label">E-mail adresa</span>
                    <span className="userInfo-email">{email}</span>
                </div>
            </div>
            <div className="userInfo-separator" />
            <div className="app-info-section userInfo-grid">
                <div className="f-column">
                    <span className="userInfo-label">Način obavještavanja</span>
                    <Notifications notificationType={notificationType} />
                </div>
                <div className="f-column">
                    <span className="subscriptions-label">Predbilježbe na obavijesti</span>
                    {infoType.map((v, index) => (
                        <span key={index} className="subscriptions-text">
                            {v}
                        </span>
                    ))}
                </div>
            </div>
            <Can I="update" a="UserRole">
                <div className="userInfo-separator" />
                <div className="app-info-section">
                    <span className="userInfo-label">Uloga</span>
                    <div className="userInfo-roles">
                        <RolesList onChange={handleAccountRoleChange} roleType={accountRole} />
                    </div>
                </div>
            </Can>
            <div className="userInfo-separator" />
            <div className="deactivate-section">
                <Button
                    className="mr-32"
                    type="button"
                    variant="warning-ghost"
                    size="xsmall"
                    isDisabled={false}
                    onClick={() => onDeactivateClick(uid, userType?.value ?? 0)}
                >
                    <span>Deaktiviraj korisnika</span>
                </Button>
                <div className="user-deactivate">
                    Deaktivacijom korisnika deaktivira se korisnički račun na sustavu e-Savjetnik
                </div>
            </div>
        </div>
    );
};
