import {
  initializeNew,
  sendEmail,
  SendEmailRequest,
  SendEmailAttachRequest,
  sendNotification,
  SendNotificationRequest,
  uploadAttachment
} from "services";
import { AppThunk } from "store";

import { toast } from "react-toastify";
import {
  getEmailError,
  getEmailRequest,
  getEmailSuccess,
  getNotificationError,
  getNotificationRequest,
  getNotificationSuccess,
} from "./CommunicationSlice";


export const initializeEmailAsync =
    (): AppThunk =>
        async (dispatch, getState) => {
            try {
                const { signIn } = getState();

                if (signIn.user) {
                    dispatch(getEmailRequest());

                    const { accessToken } = signIn.user;
                    //const response = await initializeNew(accessToken);

                    //dispatch(
                    //    getEmailSuccess({
                    //        res: {
                    //            id: response.data.id,
                    //            statuses: response.data.statuses,
                    //        },
                    //    })
                    //);
                    //toast.success(response.data.statuses[0].message);
                }
            } catch (error: any) {
                const [first] = error.response.data.statuses;
                dispatch(getEmailError({ error: first.message }));
                toast.error(first.message);
            }
        };

//export const sendEmailAsync =
//  (data: SendEmailRequest): AppThunk =>
//  async (dispatch, getState) => {
//    try {
//      const { signIn } = getState();

//      if (signIn.user) {
//        dispatch(getEmailRequest());

//      const { accessToken } = signIn.user;

//      const responseInit = await initializeNew(accessToken, data);

//      if (!responseInit) return;

//          const responseInitId = responseInit.data.notificationFormView.id;

//          console.log(responseInitId);
//          data.id = responseInitId;

//        const response = await sendEmail(accessToken, data);

//        dispatch(
//          getEmailSuccess({
//              res: {
//              notificationFormView: response.data.notificationFormView,
//              statuses: response.data.statuses,
//            },
//          })
//        );
//        toast.success(response.data.statuses[1].message);
//      }
//    } catch (error) {
//      const [first] = error.response.data.statuses;
//      dispatch(getEmailError({ error: first.message }));
//      toast.error(first.message);
//    }
//  };


export const sendEmailAsync =
    (data: SendEmailAttachRequest): AppThunk =>
        async (dispatch, getState) => {
            try {
                const { signIn } = getState();

                if (signIn.user) {
                    dispatch(getEmailRequest());

                    const { accessToken } = signIn.user;

                    //maknuti data
                    const responseInit = await initializeNew(accessToken, data.emailRequest);

                    if (!responseInit) return;

                    const responseInitId = responseInit.data.notificationFormView.id;

                    console.log(responseInitId);
                    data.attachmentRequest.id = responseInitId;
                    data.emailRequest.id = responseInitId;

                    if (data.attachmentRequest.attachment) {
                        let formData = new FormData();

                        formData.append("Id", responseInitId.toString());
                        formData.append("Attachment", data.attachmentRequest.attachment);

                        const responseAttach = await uploadAttachment(accessToken, formData);
                        if (!responseAttach) return;
                    }
                    
                    //change to send mail
                    const responseSendNotification = await sendEmail(accessToken, data.emailRequest);

                    dispatch(
                        getEmailSuccess({
                            res: {
                                notificationFormView: responseSendNotification.data.notificationFormView,
                                statuses: responseSendNotification.data.statuses,
                            },
                        })
                    );
                    toast.success(responseSendNotification.data.statuses[1].message);
                }
            } catch (error: any) {
                const [first] = error.response.data.statuses;
                dispatch(getEmailError({ error: first.message }));
                toast.error(first.message);
            }
        };


export const sendNotificationAsync =
  (data: SendNotificationRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { signIn } = getState();

      if (signIn.user) {
        dispatch(getNotificationRequest());

        const { accessToken } = signIn.user;
        const response = await sendNotification(accessToken, data);

        dispatch(
          getNotificationSuccess({
            res: {
              statuses: response.data.statuses,
            },
          })
        );
        toast.success(response.data.statuses[0].message);
      }
    } catch (error: any) {
      const [first] = error.response.data.statuses;
      dispatch(getNotificationError({ error: first.message }));
      toast.error(first.message);
    }
  };
