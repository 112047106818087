import { EducationApplicationRequest, EducationsRequest } from "services";
import { createService } from "services/serviceFactory";
import { EducationByIdResponse, FarmHolderResponse } from ".";
import {
  EducationReminderRequest,
  EducationReminderResponse,
  StatusResponse,
} from ".";
import {
  EducationApplicationResponse,
  EducationResponse,
  ExpertAreasResponse,
  MeasuresResponse,
  OperationTypesResponse,
} from "./response";

const dippService = createService("dipp");

async function getAllEducations(accessToken: string, body: EducationsRequest) {
  return dippService.post<EducationResponse>(
    "/dipp/v1/Education/GetEducations",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getAllMeasures(accessToken: string) {
  return dippService.get<MeasuresResponse>(
    "/dipp/v1/Education/GetAllMeasures",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getAllOperationTypes(accessToken: string) {
  return dippService.get<OperationTypesResponse>(
    "/dipp/v1/Education/GetAllOperationTypes",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getAllExpertAreas(accessToken: string) {
  return dippService.get<ExpertAreasResponse>(
    "/dipp/v1/Education/GetAllExpertAreas",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function educationApplication(
  accessToken: string,
  body: EducationApplicationRequest
) {
  return dippService.post<EducationApplicationResponse>(
    "dipp/v1/Education/EducationApplication",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function addReminderToEducation(
  accessToken: string,
  body: EducationReminderRequest
) {
  return dippService.post<StatusResponse>(
    "dipp/v1/Education/AddEducationReminder",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getFarmMembers(accessToken: string, mibpg: string) {
  return dippService.get("/dipp/v1/Farmer/GetFarmMembers", {
    params: {
      mibpg,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

async function getUsersEducationsWithReminder(
  accessToken: string,
  accountUid: string
) {
  return dippService.get<EducationReminderResponse>(
    `dipp/v1/Education/GetEducationReminder?accountUid=${accountUid}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getFarmHolder(accessToken: string, mibpg: string) {
  return dippService.get<FarmHolderResponse>(
    "/dipp/v1/Farmer/GetFarmHolder",
    {
      params: {
        mibpg,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

async function getEducation(accessToken: string, educationUid: string) {
  return dippService.get<EducationByIdResponse>("dipp/v1/Education/Get", {
    params: {
      educationUid,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export {
  getAllEducations,
  getAllMeasures,
  getAllOperationTypes,
  getAllExpertAreas,
  educationApplication,
  addReminderToEducation,
  getUsersEducationsWithReminder,
  getFarmMembers,
  getFarmHolder,
  getEducation,
};
