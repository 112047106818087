import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  errorMessage: string;
  items: string[];
}

const initialState: State = {
  isLoading: false,
  errorMessage: "",
  items: [],
};

const measuresSlice = createSlice({
  name: "Measures",
  initialState: initialState,
  reducers: {
    getMeasuresRequest: (state) => {
      state.isLoading = true;
    },
    getMeasuresSuccess: (
      state,
      action: PayloadAction<{ measures: string[] }>
    ) => {
      state.isLoading = false;
      state.items = action.payload.measures;
    },
    getMeasuresError: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      state.isLoading = false;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const { actions } = measuresSlice;

export const {
  getMeasuresRequest,
  getMeasuresSuccess,
  getMeasuresError,
} = actions;

export const measuresReducer = measuresSlice.reducer;
