import React from "react";

interface Props {
  className?: string;
}
export const ProfileInfo: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`profile-settings-container ${className ?? ""}`}>
      {children}
    </div>
  );
};
