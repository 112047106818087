import React, { useState }  from "react";
import {
    TermsOfUseModal
} from "modules/auth";
import { Link } from "react-router-dom";


export const MainFooter: React.FC = () => {

  const [showToUModal, setShowToUModal] = useState(false);
  const toggleToUModal = () => {
        setShowToUModal(!showToUModal);
  };

  return (
    <footer className="main-footer">
      <span className="main-footer__copy">
        © Ministarstvo poljoprivrede 2020. Sva prava pridržana.
      </span>

      <div className="footer-navigation">
        <a href="https://www.savjetodavna.hr/" rel="noreferrer" target="_blank">
          Savjetodavni portal
        </a>
        <a href="https://poljoprivreda.gov.hr" rel="noreferrer" target="_blank">
          Ministarstvo poljoprivrede
        </a>
        <a href="https://www.apprrr.hr/" rel="noreferrer" target="_blank">
          APPRRR
        </a>
        <a href="https://pitanja.mps.hr" rel="noreferrer" target="_blank">
          Postavi upit
        </a>
      </div>
      <a onClick={toggleToUModal} className="consent__link">
        Uvjeti korištenja
      </a>
      <TermsOfUseModal
        toggleModal={toggleToUModal}
        isVisible={showToUModal}
       />
      </footer>


  );
};
