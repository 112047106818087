import "./css/app.css";
import "normalize.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routing } from "routing";
import { Provider } from "react-redux";
import { store } from "store";
import { AbilityContext, ability } from "modules/auth";
import { BehaviorSubject } from "rxjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactGA from 'react-ga4';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

export const App: React.FC = () => {

//   if(navigator.userAgent.includes('Android')) {
//     window.location.replace('https://play.google.com/store/apps/details?id=com.agrivi.esavjetnik');
//   };

//   if(navigator.userAgent.includes('iPhone')) {
//     window.location.replace('https://apps.apple.com/eg/app/esavjetnik/id1572628741');

//     // setTimeout(() => {
//     //   window.location.replace(
//     //     "https://apps.apple.com/us/app/instagram/id389801252"
//     //   );
//     // }, 2000);
//     };

    (window as any)['analyticsSubject'] = new BehaviorSubject<AnalyticsEvent>({});

    (window as any)['analyticsSubject'].subscribe(
        (event: AnalyticsEvent) => {
            if (!event?.label) return;
            ReactGA.initialize("G-54H57RZDM6");;
            ReactGA.send("eSavjetnik_web_init2");
            ReactGA.event({
                category: "shopping",
                action: "eSavjetnik_web_event_app",
                label: "name",
            });
        }
    )

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <AbilityContext.Provider value={ability}>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <Routing />
                    </BrowserRouter>
                </AbilityContext.Provider>
            </Provider>
            <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
    );
};

export interface AnalyticsEvent {
    label?: string;
    action?: string;
    category?: string;

}