export enum NotificationType {
  Email = 0x1,
  Sms = 0x2,
  WhatsApp = 0x4,
  Viber = 0x8,
  Push = 0x10,
}

export const notificationTypes: Record<
  string,
  { value: number; label: string }
> = {
  Email: {
    value: NotificationType.Email,
    label: "Email",
  },
  SMS: {
    value: NotificationType.Sms,
    label: "SMS",
  },
  WhatsApp: {
    value: NotificationType.WhatsApp,
    label: "WhatsApp",
  },
  Viber: {
    value: NotificationType.Viber,
    label: "Viber",
  },
  Application: {
    value: NotificationType.Push,
    label: "Aplikacija",
  },
};
