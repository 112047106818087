import {
  NotificationSettings,
  Subscriptions,
  updateProfile,
} from "modules/dashboard";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

interface Props {
  notificationTypes?: number[];
  infoTypes?: number[];
}

export const Settings: React.FC<Props> = ({ notificationTypes, infoTypes }) => {
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState(notificationTypes);
  const [subscriptions, setSubscriptions] = useState(infoTypes);

  const handleNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const valueNumb = parseInt(value);

    setNotifications((prev) => {
      if (prev && !prev.includes(valueNumb)) {
        return [...prev, valueNumb];
      } else {
        return prev && [...prev.filter((s) => s !== valueNumb)];
      }
    });
  };

  const handleSubscriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const valueNumb = parseInt(value);

    setSubscriptions((prev) => {
      if (prev && !prev.includes(valueNumb)) {
        return [...prev, valueNumb];
      } else {
        return prev && [...prev.filter((s) => s !== valueNumb)];
      }
    });
  };

  useEffect(() => {
    //prevent api call on first render and trigger only on state change
    if (mountedRef.current) {
      mountedRef.current = false;
    } else {
      if (Array.isArray(notifications) && Array.isArray(subscriptions)) {
        dispatch(updateProfile(notifications, subscriptions));
      }
    }
  }, [dispatch, notifications, subscriptions]);

  return (
    <>
      <section>
        <NotificationSettings
          values={notifications}
          onChange={handleNotificationChange}
        />
      </section>
      <div className="section-separator" />
      <section>
        <Subscriptions
          values={subscriptions}
          onChange={handleSubscriptionChange}
        />
      </section>
    </>
  );
};
