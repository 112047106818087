import {
  Button,
  Checkbox,
  Modal,
  ModalFooter,
  ModalHeader,
} from "modules/common";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { xor } from "lodash";

interface Props {
  isActive: boolean;
  toggleModal: () => void;
  yearFilter: string[];
  setYearFilter: React.Dispatch<React.SetStateAction<string[]>>;
  obligationFilter: boolean[];
  setObligationFilter: React.Dispatch<React.SetStateAction<boolean[]>>;
  measureFilter: string[];
  setMeasureFilter: React.Dispatch<React.SetStateAction<string[]>>;
  operationFilter: string[];
  setOperationFilter: React.Dispatch<React.SetStateAction<string[]>>;
}

/** Array of last three years */
const year = new Date().getFullYear();
const years = Array.from(new Array(3), (_, i) => (year - i).toString());

const obligations = [true, false];

export const CommunicationAdditionalFiltersModal: React.FC<Props> = ({
  isActive,
  toggleModal,
  yearFilter,
  setYearFilter,
  obligationFilter,
  setObligationFilter,
  measureFilter,
  setMeasureFilter,
  operationFilter,
  setOperationFilter,
}) => {
  const { items: ReducerMeasures } = useSelector(
    (state: RootState) => state.measures
  );
  const { items: ReducerOperations } = useSelector(
    (state: RootState) => state.operationTypes
  );

  function onYearChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedYear = years.find((i) => i === event.target.value);

    if (!selectedYear) {
      return;
    }

    // Create array of unique values
    const newArray = xor(yearFilter, [selectedYear]);
    setYearFilter(newArray);
  }

  function onObligationChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedObligation = obligations.find(
      (i) => i.toString() === event.target.value
    );

    if (selectedObligation === undefined) {
      return;
    }

    // Create array of unique values
    const newArray = xor(obligationFilter, [selectedObligation]);
    setObligationFilter(newArray);
  }

  function onMeasureChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedMeasure = ReducerMeasures.find(
      (i) => i === event.target.value
    );

    if (!selectedMeasure) {
      return;
    }

    // Create array of unique values
    const newArray = xor(measureFilter, [selectedMeasure]);
    setMeasureFilter(newArray);
  }

  function onOperationChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedOperation = ReducerOperations.find(
      (i) => i === event.target.value
    );

    if (!selectedOperation) {
      return;
    }

    // Create array of unique values
    const newArray = xor(operationFilter, [selectedOperation]);
    setOperationFilter(newArray);
  }

  return (
    <Modal
      isActive={isActive}
      onClose={toggleModal}
      modalHeader={
        <ModalHeader
          onClose={toggleModal}
          title={"Dodatni filteri"}
          isLoading={false}
        />
      }
      modalFooter={
        <ModalFooter>
          <Button
            type="button"
            size="small"
            variant="primary"
            isDisabled={false}
            onClick={toggleModal}
          >
            <span>Prikaži odabrano</span>
          </Button>
        </ModalFooter>
      }
    >
      <div style={{ marginLeft: 40, marginTop: 20 }}>
        <div style={{ maxHeight: 700, overflowY: "scroll", paddingBottom: 32 }}>
          <div
            style={{
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "rgba(217, 217, 217, 1)",
              marginBottom: 28,
            }}
          >
            <label htmlFor="godina">Godina</label>
            {years.map((value, index) => {
              return (
                <div key={index} className="mt-8 mb-16">
                  <Checkbox
                    type="checkbox"
                    checked={Boolean(yearFilter.includes(value))}
                    htmlFor={value}
                    onChange={onYearChange}
                    size="small"
                    text={<div className="filter__item__text">{value}</div>}
                    value={value}
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "rgba(217, 217, 217, 1)",
              marginBottom: 28,
            }}
          >
            <label htmlFor="obveze">Obveze</label>
            {obligations.map((value, index) => {
              return (
                <div key={index} className="mt-8 mb-16">
                  <Checkbox
                    type="checkbox"
                    checked={Boolean(obligationFilter.includes(value))}
                    htmlFor={value ? "Da" : "Ne"}
                    onChange={onObligationChange}
                    size="small"
                    text={
                      <div className="filter__item__text">
                        {value ? "Da" : "Ne"}
                      </div>
                    }
                    value={value.toString()}
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "rgba(217, 217, 217, 1)",
              marginBottom: 28,
            }}
          >
            <label htmlFor="mjera">Mjera</label>
            {ReducerMeasures.map((value, index) => {
              return (
                <div key={index} className="mt-8 mb-16">
                  <Checkbox
                    type="checkbox"
                    checked={Boolean(measureFilter.includes(value))}
                    htmlFor={value}
                    onChange={onMeasureChange}
                    size="small"
                    text={<div className="filter__item__text">{value}</div>}
                    value={value}
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "rgba(217, 217, 217, 1)",
            }}
          >
            <label htmlFor="operacija">Operacija</label>
            {ReducerOperations.map((value, index) => {
              return (
                <div key={index} className="mt-8 mb-16">
                  <Checkbox
                    type="checkbox"
                    checked={Boolean(operationFilter.includes(value))}
                    htmlFor={value}
                    onChange={onOperationChange}
                    size="small"
                    text={<div className="filter__item__text">{value}</div>}
                    value={value}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
