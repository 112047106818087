import { decodeJwt } from "..";

interface SessionData {
  userId: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  role: {
    value: number;
    label: string;
  };
  userType: {
    value: number;
    label: string;
  };
  expiration: number;
  rememberMe: boolean;
  refreshExpiration: number;
}

export function sessionCache() {
  const key = "session";

  const saveSession = (rememberMe: boolean, data: unknown) => {
    if (rememberMe) {
      localStorage.setItem(key, JSON.stringify(data));
      return;
    }

    sessionStorage.setItem(key, JSON.stringify(data));
  };

  const removeSession = () => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  };

  const updateTokens = (accessToken: string, refreshToken: string) => {
    const session = getSession();

    if (!session) {
      return;
    }
    const body = decodeJwt(accessToken);

    session.accessToken = accessToken;
    session.refreshToken = refreshToken;
    session.expiration = body.exp;

    saveSession(session.rememberMe, session);
  };

  const updateSession = (data: SessionData) => {
    let existingSession = getSession();

    if (!existingSession) {
      saveSession(data.rememberMe, data);
      return;
    }

    existingSession = {
      ...data,
    };

    saveSession(existingSession.rememberMe, existingSession);
  };

  const getSession = () => {
    let session = sessionStorage.getItem(key);

    if (!session) {
      session = localStorage.getItem(key);
    }

    if (session) {
      return JSON.parse(session) as SessionData;
    } else {
      return null;
    }
  };
  return {
    saveSession,
    removeSession,
    getSession,
    updateTokens,
    updateSession,
  };
}
