import React from "react";

interface Props {
  numberOfRegistered: number;
  numberOfSignedIn: number;
}
export const UsersStats: React.FC<Props> = ({
  numberOfRegistered,
  numberOfSignedIn,
}) => {
  return (
    <div className="users-container">
      <div>
        <strong className="users-stats-value">{numberOfRegistered}</strong>
        <p className="users-stats-text">Registriranih</p>
      </div>
      <div>
        <strong className="users-stats-value">{numberOfSignedIn}</strong>
        <p className="users-stats-text">Prijavljenih</p>
      </div>
    </div>
  );
};
