import * as React from "react";
import { InfoNote, notificationTypes, ToggleSwitch } from "modules/common";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
// import { ReactComponent as SmsIcon } from "assets/icons/sms.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";

interface Props {
  values?: number[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NotificationSettings: React.FC<Props> = ({ values, onChange }) => {
  if (!values) {
    return null;
  }
  return (
    <div>
      <h2 className="notifications__header">Način obavještavanja</h2>
      <span className="notifications__description">
        Budite u toku sa svim novostima i obavezama.
      </span>
      <div className="notifications">
        <div>
          <EmailIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.Email.label}
            className="notification-row__label"
          >
            {notificationTypes.Email.label} (primarno)
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.Email.label}
          value={notificationTypes.Email.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.Email.value)}
          className="notification-row__toggle"
        />
        {/* <div>
          <SmsIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.SMS.label}
            className="notification-row__label"
          >
            {notificationTypes.SMS.label}
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.SMS.label}
          value={notificationTypes.SMS.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.SMS.value)}
          className="notification-row__toggle"
        /> */}

        { /*<div>
          <MessengerIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.WhatsApp.label}
            className="notification-row__label"
          >
            {notificationTypes.WhatsApp.label}
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.WhatsApp.label}
          value={notificationTypes.WhatsApp.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.WhatsApp.value)}
          className="notification-row__toggle"
        /> */}

        {/* <div>
          <MessengerIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.Viber.label}
            className="notification-row__label"
          >
            {notificationTypes.Viber.label}
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.Viber.label}
          value={notificationTypes.Viber.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.Viber.value)}
          className="notification-row__toggle"
        /> */}

         <div>
          <MessengerIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.Application.label}
            className="notification-row__label"
          >
            {notificationTypes.Application.label}
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.Application.label}
          value={notificationTypes.Application.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.Application.value)}
          className="notification-row__toggle"
        /> 
      </div>
      <InfoNote
        text="Ukoliko isključite
sve opcije nećete moći pravovremeno primati obavijesti o novostima i
vašim obavezama."
      />
    </div>
  );
};
