import axios, { AxiosRequestConfig } from "axios";
import serviceBaseRoutes from "./serviceBaseRoutes.json"; // If there is an error on this line, create a new file named serviceBaseRoutes.json based on the example file serviceBaseRoutes.example.json
import { headers } from "./headers";

export type ServiceConfiguration = Omit<AxiosRequestConfig, "baseURL"> | undefined;

function getServiceBaseRoute(serviceName: string) {
    const serviceRouteMap = serviceBaseRoutes as Record<string, string>;
    return serviceRouteMap[serviceName] ?? process.env.REACT_APP_API_BASE_URL;
}

export function createService(serviceName: string, config: ServiceConfiguration = undefined) {
    const baseURL = getServiceBaseRoute(serviceName);
    return axios.create({ headers, baseURL, ...config });
}
